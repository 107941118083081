import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import GlobalReportTable from './GlobalReportTable'
import store from "../../App/store";
import { loadReportAction } from "../../../redux/actions/reportActions";
import { loadSettingsAction } from "../../../redux/actions/usersActions";


class GlobalReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scope: 'global_report'
    }
  }

  componentDidMount() {
    const { scope } = this.state;
    store.dispatch(loadSettingsAction())
    store.dispatch(loadReportAction(scope, {
      ...store.getState().reports[`${scope}_filters`],
    }))
  }



  render() {
    const { scope } = this.state;
    return (
      <>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Report</h3>
          </Col>
        </Row>
        <Row>
          <GlobalReportTable
            scope={scope}
          />
        </Row>
      </>
    )
  }

}

export default GlobalReport
