import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Become from '../Become/index'
import SignUp from '../SignUp/index'
import ResetPassword from '../ResetPassword/index';
import {TrafficDashboard} from '../Dashboard'
import Offers from '../Offers'
import Report from '../Report'
import BannersLayout from '../BannersLayout/BannersLayout'
import PostbacksLogs from '../Logs/PostbacksLogs/PostbacksLogs'
import OfferDetails from '../OfferDetails'
import OfferReport from '../Offers/components/reports/OfferReport'
import PostbackPage from '../PostbackPage/index';
import ProfilePage from '../ProfilePage/index'
import ReferralPage from '../ReferralPage/index'
import {Payments} from '../PaymentHistory/index';
import {PublicRoute, PrivateRoute} from '../../utils/auth_service';

const Pages = () => (
    <Switch>
        <Route exact path="/" component={TrafficDashboard}/>
        <Route exact path="/dashboard" component={TrafficDashboard}/>
        <Route exact path="/offers/:id" component={OfferDetails}/>
        <Route exact path="/offers/report/:id" component={OfferReport}/>
        <Route path="/offers" component={Offers}/>
        <Route path="/report" component={Report}/>
        <Route exact path="/postback" component={PostbackPage}/>
        <Route exact path="/postbacks_logs" component={PostbacksLogs}/>
        <Route exact path="/profile" component={ProfilePage}/>
        <PublicRoute exact path="/referral" component={ReferralPage}/>
        <Route path="/payments" component={Payments}/>
    </Switch>
);

const wrappedRoutes = () => (
    <div>
        <Layout/>
        <div className="container__wrap">
            <Route path="/" component={Pages}/>
            <BannersLayout/>
        </div>
    </div>
);

const Routers = () => (
    <MainWrapper>
        <main>
            <Switch>
                <Route exact path="/" component={LogIn}/>
                <PublicRoute exact path="/become" component={Become}/>
                <Route exact path="/signin" component={LogIn}/>
                <Route exact path="/signup" component={SignUp}/>
                <Route exact path="/reset_password" component={ResetPassword}/>
                <PrivateRoute path="/" component={wrappedRoutes}/>
            </Switch>

        </main>
    </MainWrapper>
);

export default Routers;
