import React, { Component } from 'react'
import SignUpForm from './components/SignUpForm';
import SignUpAdditionalForm from './components/SignUpAddintional';
import {connect} from "react-redux";

class SignUp extends Component {

	constructor(props) {
		super(props);
		this.state = {
			additional: false,
			errors: null,
			userInfo: null,
			invite: null,
		};
	}

	componentDidMount() {
		const url = new URL(window.location.href)
		const query_string = url.search
		const search_params = new URLSearchParams(query_string)
		const ref = search_params.get('ref')
		if (ref) {
			this.setState({ invite: ref })
		}
	}

	viewNextSigUpPart = () => {
		const { additional } = this.state;
		this.setState({
			additional: !additional
		})
	};

	viewSignUpPart = (errorText, userInfo) => {
		this.setState({
			additional: false,
			errors: errorText,
			userInfo: { ...userInfo }
		})
	}

	setErrors = (errorText) => {
		this.setState({
			errors: errorText,
		})
	}

	render(){
		const { sign_up_form } = this.props.settings || {}
		const { additional, errors, userInfo } = this.state;

		return (
			<div className="account">
				<div className="account__wrapper">
					<div className="account__card">
						<div className="account__head">
							<h3 className="account__title">
								<div className="account__logo">
									{this.props.settings && this.props.settings.logo_url ?
										<img src={this.props.settings.logo_url} className="account__rtk-logo-by-user" /> :
										<div className="account__rtk-logo" />
									}
								</div>
								{sign_up_form && sign_up_form.title ? sign_up_form.title : <span>Registration</span>}
							</h3>
							<h4 className="account__subhead subhead">
								{sign_up_form && sign_up_form.sub_title ? sign_up_form.sub_title : <span>Your credentials</span>}
							</h4>
						</div>
						{additional ?
							<SignUpAdditionalForm
								history={this.props.history}
								settings={this.props.settings}
								invite={this.state.invite}
								onRef={ref => (this.AdditionalForm = ref)}
								changeStepToPrevious={this.viewSignUpPart}
								userInfo={userInfo}
								onSubmit
							/> :
							<SignUpForm
								history={this.props.history}
								settings={this.props.settings}
								invite={this.state.invite}
								nextSignUpStep={this.viewNextSigUpPart}
								changeStepToPrevious={this.viewSignUpPart}
								setErrors={this.setErrors}
								userInfo={userInfo}
								errors={errors}
								onSubmit
							/>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	settings: state.users && state.users.settings ? state.users.settings : {}
})

export default connect(mapStateToProps)(SignUp)

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
