import { LOAD_CAMPAIGNS, ADD_CAMPAIGNS, FILTER_CAMPAIGNS } from './../actions/campaignsActions'
import moment from "moment";

export const STATUS_ACTIVE   = 1
export const STATUS_ARCHIVED = 2

const initialState = {
    items: [],
    filters: {
        date_from: moment().subtract(29, 'days').startOf("day"),
        date_to: moment().endOf("day"),
    },
    loading: false,
    loaded: false,
}

export const campaigns = (state=initialState, action) => {
    switch (action.type) {

        case LOAD_CAMPAIGNS:
            return {
                ...state,
                loading: true,
                loaded: false
            }

        case ADD_CAMPAIGNS:
            return {
                ...state,
                loading: false,
                loaded: false,
                items: action.data.items,
                total: action.data.total
            }

        case FILTER_CAMPAIGNS:
            return {
                ...state,
                filters: action.filters
            }

        default:
            return state

    }
}
