import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import {Field, reduxForm, change} from "redux-form";
import Select from "react-select";

import store from "../../App/store";
import { updateUserAction, getUserDataAction } from "../../../redux/actions/usersActions";
import { loadSettingsAction } from "../../../redux/actions/usersActions";
import noty from '../../../utils/noty'
import { getCurrencySign } from '../../../utils';

class Profile extends Component {

    constructor(props) {
        super(props);
        const user = store.getState().users.currentUser || {}
        const users = store.getState().users || {}
        this.state = {
            email: user.email || '',
            alias: user.alias || '',
            id: user.id || '',
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            contact_details: user.contact_details || '',
            billing_details: user.billing_details || '',
            payment_type: user.payment_type || '',
            preferredPaymentOptions: [],
            usersSettings: users.settings || {},
        };
    }

    componentDidMount() {
        store.dispatch(loadSettingsAction())
        let preferredPaymentOptions = undefined
        let payment_type = ''
        const users = store.getState().users || {};
        const usersSettings = users.settings || {};
        const currencySign = getCurrencySign(usersSettings);
        if (usersSettings.payment_request_settings && usersSettings.payment_request_settings.payment_types) {
            preferredPaymentOptions = usersSettings.payment_request_settings.payment_types.map(element => {
                if (element.is_enabled) {
                    let label = ''
                    if (element.name === 'wire') {
                        label = `Wire transfer (min ${element.amount}${currencySign})`
                    } else if (element.name === 'paypal') {
                        label = `PayPal (min ${element.amount}${currencySign})`
                    } else if (element.name === 'paxum') {
                        label = `Paxum (min ${element.amount}${currencySign})`
                    }
                    return { value: element.name, amount: element.amount, label: label }
                }
            })
            preferredPaymentOptions = preferredPaymentOptions.filter(el => el)
            const foundPaymentType = preferredPaymentOptions.find(el => el.value === this.state.payment_type)
            if (foundPaymentType) { foundPaymentType.isDisabled = true }
            payment_type = foundPaymentType ? foundPaymentType : ''
        }
        this.setState({ preferredPaymentOptions, payment_type: payment_type }, () => {
            this.props.dispatch(change('profile_form', 'email', this.state.email));
            this.props.dispatch(change('profile_form', 'alias', this.state.alias));
            this.props.dispatch(change('profile_form', 'id', this.state.id));
            this.props.dispatch(change('profile_form', 'firstname', this.state.firstname));
            this.props.dispatch(change('profile_form', 'lastname', this.state.lastname));
            this.props.dispatch(change('profile_form', 'contact_details', this.state.contact_details));
            this.props.dispatch(change('profile_form', 'billing_details', this.state.billing_details));
            this.props.dispatch(change('profile_form', 'payment_type', this.state.payment_type));
            this.props.dispatch(getUserDataAction())
        });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value.trim() })
    }

    handleChangeSelect = (event) => {
        const { preferredPaymentOptions } = this.state;
        if (event) {
            preferredPaymentOptions.map(option => option.isDisabled = option.value === event.value)
        } else {
            preferredPaymentOptions.map(option => option.isDisabled = false);
        }
        this.setState({
            preferredPaymentOptions,
            payment_type: event,
        });
    };

    handleSubmit = (event) =>{
        event.preventDefault()
        const data = JSON.parse(JSON.stringify(this.state));
        data.payment_type = data.payment_type ? data.payment_type.value : ''
        delete data.usersSettings
        store.dispatch(updateUserAction(data))
            .then(() => noty({ text: 'Your profile details has been saved successfully', type: 'success' }))
            .catch((error) => noty({ text: 'Something went wrong. Please try again later or contact support@redtrack.io', type: 'error' }))
    }

    render() {
        const { payment_type, preferredPaymentOptions } = this.state

        return (
            <Col md={6} style={{margin: 'auto'}}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Profile</h5>
                        </div>
                        <form className="form"  onSubmit={this.handleSubmit}>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Email</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="email"
                                        component="input"
                                        type={'text'}
                                        value={this.state.email}
                                        placeholder=""
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            {this.state.usersSettings.show_alias && <div className="form__form-group">
                                <span className="form__form-group-label">Alias</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="alias"
                                        component="input"
                                        type={'text'}
                                        value={this.state.alias}
                                        placeholder=""
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>}
                            {this.state.usersSettings.show_alias && <div className="form__form-group">
                                <span className="form__form-group-label">Pubid</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="id"
                                        component="input"
                                        type={'text'}
                                        value={this.state.id}
                                        placeholder=""
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>}
                            <div className="form__form-group">
                                <span className="form__form-group-label">First Name</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="firstname"
                                        component="input"
                                        type={'text'}
                                        value={this.state.firstname}
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Last Name</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="lastname"
                                        component="input"
                                        type={'text'}
                                        value={this.state.lastname}
                                        disabled={true}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Contact Details</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="contact_details"
                                        component="textarea"
                                        style={{
                                            'resize': 'none'
                                        }}
                                        value={this.state.contact_details}
                                        disabled={false}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="card__title mt-10 mb-20">
                                <h5 className="bold-text">Payout details</h5>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Preferred Payment</span>
                                <div className="form__form-group-field">
                                    <Select
                                      style={{
                                          minWidth: '100%',
                                      }}
                                      isMulti={false}
                                      name='select'
                                      value={payment_type}
                                      onChange={this.handleChangeSelect}
                                      options={preferredPaymentOptions}
                                      isClearable={true}
                                      closeOnSelect={false}
                                      removeSelected={false}
                                      className="react-select"
                                      placeholder='Select desired method'
                                      classNamePrefix="react-select"
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Payment Details</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="billing_details"
                                        component="textarea"
                                        style={{
                                            'resize': 'none'
                                        }}
                                        value={this.state.billing_details}
                                        disabled={false }
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary account__btn account__btn--small">Save</button>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.users.currentUser,
    settings:  state.users.settings
})

export default reduxForm({
    form: 'profile_form',
})(Profile)
