import React, { Component } from 'react';
import { connect } from 'react-redux';

import { filtersReportsAction } from '../../../redux/actions/reportActions';
import { LoadableContainer } from './../../../widgets';
import CustomizableTable from '../../../common/table/customizableTable';
import { getReferralsColumns, REPORTS_REFERRAL_PROGRAM_OPTIONS } from '../../../constants/reports/referral';

class ReferralsProgramReportPart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderRowColor = this.renderRowColor.bind(this);
  }

  renderRowColor(row) {
    let rowColor = '';
    const index  = row.index|| row.viewIndex;
    if (index % 2 === 0) {
      rowColor = 'custom-odd';
    } else {
      rowColor = 'custom-even';
    }
    return rowColor;
  };

  render() {
    const {
      referralReport,
      onPaginationLoad,
      onPageSizeChange,
      _filterAction,
    } = this.props;
    const {
      items = [],
      loading = false,
      loaded,
      total = 0,
      pageSize,
      filters,
    } = referralReport;
    const isPaginationRequired = (total > pageSize);

    return (
      <>
        <LoadableContainer
          loading={false}
          loaded={loaded}
          style={{
            position: 'relative',
            width: '100%',
            maxHeight: '350px',
          }}
        >
          <CustomizableTable
            className='custom-data-offer-table'
            columns={[
              ...getReferralsColumns(),
            ]}
            sourceName={REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE}
            keyField="id"
            data={items}
            defaultSortDesc={false}
            minRows={3}
            pageSize={isPaginationRequired ? pageSize : items.length}
            pages={isPaginationRequired ? Math.ceil(total / pageSize) : 0}
            showPagination={isPaginationRequired}
            showPaginationTop={false}
            showPaginationBottom={isPaginationRequired}
            showPageSizeOptions={false}
            manual={isPaginationRequired}
            pageSizeOptions={[pageSize]}
            onPageChange={(page) => {
              if (isPaginationRequired) {
                _filterAction(REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE, {
                  ...filters,
                });
                onPaginationLoad(page);
              }
            }}
            onPageSizeChange={(ps) => {
              if (isPaginationRequired) {
                onPageSizeChange(ps);
              }
            }}
            style={{
              maxHeight: '100%',
              borderLeft: 0,
              borderRight: 0,
              borderBottom: 0,
              width: '100%',
            }}
            customDragDropColumnsClass={'min-drag-drop-context-container'}
            loading={loading}
            getTrProps={(state, row, column, instance) => ({
              className: (row && row.original && this.renderRowColor(row)),
            })}
          />
        </LoadableContainer>
      </>
    );
  }
}

ReferralsProgramReportPart.defaultProps = {
  referralReport: {
    items: [],
    filters: {},
    total: 0,
    page: 0,
  },
  _filterAction: () => null,
  onPaginationLoad: () => null,
  onPageSizeChange: () => null,
};

const mapStateToProps = (state) => ({
  user: state.users.currentUser || {},
  users: state.users,
  settings:  state.users.settings || {},
  referralReport: state.reports[REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE] || {},
})

const mapDispatchToProps = (dispatch) => ({
  _filterAction: (scope, filters) => dispatch(filtersReportsAction(scope, filters)),
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralsProgramReportPart));
