import { loadCampaigns, } from '../../utils/apiAdapter'
import store from '../../containers/App/store'
import { prepareDateFromTo } from '../../utils/index'
import { errorOccuredWithNotificationAction as errorOccuredAction } from './errors'

import { STATUS_CAMPAIGN_DELETED } from '../../constants';
import { DATE_FORMAT_HOUR, LAST_HOUR_FLAG_VALUE } from '../../constants/index'

export const LOAD_CAMPAIGNS   = 'LOAD_CAMPAIGNS'
export const ADD_CAMPAIGNS    = 'ADD_CAMPAIGNS'
export const FILTER_CAMPAIGNS = 'FILTER_CAMPAIGNS'

export const loadCampaignsAction = (dispatch) => {
    dispatch({ type: LOAD_CAMPAIGNS });
    const filters = store.getState().campaigns.filters;
    const date = prepareDateFromTo(filters, LAST_HOUR_FLAG_VALUE, DATE_FORMAT_HOUR)

    return loadCampaigns({
        title:         filters.title,
        date_from:     date.date_from,
        date_to:       date.date_to,
    }, (itemsData, total) => {
        const preparedItems = itemsData || [];
        const items = preparedItems.filter((el,i) => el && el.status !== STATUS_CAMPAIGN_DELETED);
        dispatch(addCampaignsAction({ items, total }))
    })
    .catch(error => dispatch(errorOccuredAction(error)))
}

export const addCampaignsAction = data => ({ type: ADD_CAMPAIGNS, data: data })

export const filterAction = filters => ({ type: FILTER_CAMPAIGNS, filters })
