import React from 'react'
import moment from 'moment'
import _DateFormatter from './DateFormatter'

import {
  SESSION_OBJECT_KEY,
  SETTINGS_TIME_EXPIRED_MIN,
  CURRENCY_OPTIONS_LIST,
  DEFAULT_CURRENCY_SIGN,
  DEFAULT_CURRENCY_LABEL,
  CURRENCY_OPTIONS_LABELS_LIST
} from '../constants'
import { CONVERSIONS_TYPES_EMPTY_SHAPE, CONVERSIONS_DEFAULT_TYPE, CONVERSIONS_DEFAULT_TYPE_ALIAS } from '../constants/conversions'

export const getSavedOrderOfColumns = (columns, savedColumns, key) => {
  if (savedColumns.length > 0) {
    const order = savedColumns.map(el => el.id)
    columns.sort((a, b) => {
      if (typeof a[key] === "function" || typeof b[key] === "function" ) {
        return 1
      }
      const columnsKeyA = a[key].split('.').pop()
      const columnsKeyB = b[key].split('.').pop()
      return order.indexOf(columnsKeyA) > order.indexOf(columnsKeyB) ? 1 : -1
    })
  }
  columns.map(column => {
    const foundedColumn = savedColumns.find(el => el.id === column[key])
    column.visible = foundedColumn ? foundedColumn.enabled : false
  })
  return columns.filter(el => el.visible)
}

export const dateFormat = d => moment(d).format('DD/MM')

export const DateFormatter = new _DateFormatter()

export const prepareDateFromTo = (filters, timeFlagValue, format) => {
  let date_from = '',
    date_to = ''
  if (timeFlagValue === filters.time_interval) {
    date_from = DateFormatter.format(filters.date_from, format)
    date_to = DateFormatter.format(filters.date_to, format)
  } else {
    date_from = DateFormatter.format(filters.date_from)
    date_to = DateFormatter.format(filters.date_to)
  }
  return { date_to, date_from }
}

export const getQueryUrlSign = (url) => {
  const findQuerySign = /\?/
  const querySign = findQuerySign.test(url) ? '&' : '?'
  return querySign
}

export const updateSessionObject = (userSessionObject, expires) => {
  const sessionObject = {
    ...userSessionObject,
    expiresAt: (expires.getTime() + (SETTINGS_TIME_EXPIRED_MIN*60*1000)),
  }
  sessionStorage.setItem(SESSION_OBJECT_KEY, JSON.stringify(sessionObject));
}

export const removeSessionItem = () => {
  sessionStorage.removeItem(SESSION_OBJECT_KEY)
}

export const renderConvTypeTitle = (key, splitBy, user) => {
  const conversion_types = user && user.goals || { ...CONVERSIONS_TYPES_EMPTY_SHAPE }
  if (conversion_types && conversion_types.default_type === '') {
    conversion_types.default_type = CONVERSIONS_DEFAULT_TYPE_ALIAS
  }
  let aliasKey = key.split(splitBy)
  let title = ''
  if (aliasKey.length > 1) {
    aliasKey = aliasKey[1]
    title = conversion_types[aliasKey]
    if (!conversion_types[aliasKey]) {
      if (aliasKey === CONVERSIONS_DEFAULT_TYPE) aliasKey = ''
      title = `Conversion ${aliasKey}`
    }
  } else {
    title = `Conversion ${aliasKey}`
  }
  return title
}

export const renderConvTypeRateTitle = (key, splitBy, user, props) => {
  const conversion_types = user && user.goals || { ...CONVERSIONS_TYPES_EMPTY_SHAPE }
  if (conversion_types && conversion_types.default_type === '') {
    conversion_types.default_type = CONVERSIONS_DEFAULT_TYPE_ALIAS
  }
  let aliasKey = key.split(splitBy)
  let title = ''
  if (aliasKey.length > 1) {
    aliasKey = aliasKey[1]
    title = `${conversion_types[aliasKey]} CR`
    if (!conversion_types[aliasKey]) {
      if (aliasKey === CONVERSIONS_DEFAULT_TYPE) aliasKey = ''
      title = `${conversion_types[aliasKey]} CR`
    }
  } else {
    title = `${conversion_types[aliasKey]} CR`
  }
  return title
}

export const renderRevenueTypeTitle = (key, splitBy, user) => {
  const conversion_types = user && user.goals || { ...CONVERSIONS_TYPES_EMPTY_SHAPE }
  if (conversion_types && conversion_types.default_type === '') {
    conversion_types.default_type = CONVERSIONS_DEFAULT_TYPE_ALIAS
  }
  let aliasKey = key.split(splitBy)
  let title = ''
  if (aliasKey.length > 1) {
    aliasKey = aliasKey[1]
    title = `${conversion_types[aliasKey]} Revenue`
    if (!conversion_types[aliasKey]) {
      if (aliasKey === CONVERSIONS_DEFAULT_TYPE) aliasKey = ''
      title = `Conversion ${aliasKey} Revenue`
    }
  } else {
    title = `Conversion ${aliasKey} Revenue`
  }
  return title
}

export const renderMetricTitle = (value, optionLabel, user) => {
  let label = ''
  if (value.split('convtype').length > 1) {
    label = renderConvTypeTitle(value,'conv', user)
  } else if (value.split('revenuetype').length > 1) {
    label = renderRevenueTypeTitle(value,'revenue', user)
  } else if (value === 'conversions') {
    label = renderConvTypeTitle('conv_default_type','conv_', user)
  } else if (value === 'revenue') {
    label = renderRevenueTypeTitle('revenue_default_type','revenue_', user)
  } else {
    label = optionLabel
  }
  return label
}

export const getCurrencySign = (user, defaultSign=DEFAULT_CURRENCY_SIGN) => {
  let currencySign = defaultSign
  if (user && user.currency) {
    currencySign = CURRENCY_OPTIONS_LIST[user.currency] || ''
  }
  return currencySign
}

export const getCurrencyLabel = (user, defaultLabel=DEFAULT_CURRENCY_LABEL) => {
  let currencyLabel = defaultLabel
  if (user && user.currency) {
    currencyLabel = CURRENCY_OPTIONS_LABELS_LIST[user.currency] || ''
  }
  return currencyLabel
}

export const hasWhiteSpace = (value) => (value.indexOf(' ') >= 0);
