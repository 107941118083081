/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';

export default class DataPaginationTable extends PureComponent {
  static propTypes = {
    heads: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      editable: PropTypes.bool,
      sortable: PropTypes.bool,
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  rowGetter = i => this.props.rows[i];

  rowRenderer = ({ renderBaseRow, ...props }) => {
    const idx = props.idx;
    let rowColor = ''
    if (idx % 2 === 0) {
      rowColor = this.props.evenRowStyle
    }
    const totalRowStyle = (idx === this.props.rows.length - 1) ? this.props.lastRowStyle : rowColor;
    return <Row style={{...totalRowStyle}}>{renderBaseRow(props)}</Row>
  };

  render() {
    const { tableProps } = this.props;
    return (
      <div className="table">
        <ReactDataGrid
          columns={this.props.heads}
          rowGetter={this.rowGetter}
          rowRenderer={this.rowRenderer}
          rowsCount={this.props.rows.length || 0}
          rowHeight={44}
          minColumnWidth={120}
          {...tableProps}
        />
      </div>
    );
  }
}
