/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import moment from "moment";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import store from "../../../containers/App/store";
import PostbacksLogsFilters from "./PostbacksLogsFilters";
import { LoadableContainer } from '../../../widgets'
import { CSVFields, getCSVFieldsColumnsList } from "../../../widgets/export";
import { getSavedOrderOfColumns } from '../../../utils';
import { loadCampaignsAction, filterAction } from "../../../redux/actions/campaignsActions";
import CustomizableTable from '../../../common/table/customizableTable';
import { PAGINATION_OPTIONS } from '../../../constants';
import { NOT_PRESENT_VALUES_FROM_REPORT } from '../../../constants/reports';
import { POSTBACKS_LOGS_OPTIONS } from "../../../constants/logs/postbacksLogs";
import { PUBLISHERS_POSTBACKS_LOGS_COLUMNS, SUBS_COUNT } from "../../../constants/columns/logs/postbacksLogs";

class PostbacksLogsTable extends Component {
  constructor (props) {
    super(props);
    const users = store.getState().users || {};
    this.state = {
      usersSettings: users.settings || {},
      heads: this._renderOffersColumns() || [],
      defaultHeads: [],
      defaultHeadColumn: {},
      groupingHeadColumn: [],
      columnsRenderer: this._renderOffersColumns,
    };
    this.calculateExportValues = this.calculateExportValues.bind(this);
  }

  static getDerivedStateFromProps (props, state) {
    const usersSettings = state.usersSettings;
    const tables = usersSettings && usersSettings.conversions_settings && usersSettings.conversions_settings.length ? usersSettings.conversions_settings : PUBLISHERS_POSTBACKS_LOGS_COLUMNS;
    const sortedHeads = getSavedOrderOfColumns(state.columnsRenderer(), tables, 'id');
    return {
      ...state,
      heads: [...sortedHeads],
    }
  }

  componentDidMount() {
    // store.dispatch(loadCampaignsAction)
  }

  calculateExportValues(data) {
    if (data) {
      data.forEach((el) => {
        NOT_PRESENT_VALUES_FROM_REPORT.forEach((value) => {
          el[value.value] = value.calculateValue(el);
        });
      });
    }
    return data;
  };

  _renderOffersColumns = () => (
    [
      {
        id: 'campaign',
        Header: 'Campaign',
        accessor: 'campaign',
        width: 200,
      },
      { id: 'campaign_id', Header: 'Campaign ID', accessor: 'campaign_id', width: 200 },
      { id: 'ref_id', Header: 'Ref ID', accessor: 'ref_id' },
      { id: 'clickid', Header: 'Click ID', accessor: 'clickid' },
      { id: 'track_time', Header: 'Clicks Time', accessor: 'track_time', width: 140, Cell: row => moment(row.value).format('YYYY.MM.DD (HH:mm)')  },
      { id: 'conv_time', Header: 'Conversion Time', accessor: 'conv_time', width: 140, Cell: row => moment(row.value).format('YYYY.MM.DD (HH:mm)')  },
      { id: 'type', Header: 'Type', accessor: 'type' },
      { id: 'pub_revenue', Header: 'Commission', accessor: 'pub_revenue' },
      { id: 'payout', Header: 'Revenue', accessor: 'payout' },
      { id: 'status', Header: 'Status', accessor: 'status' },
      { id: 'duplicate_status', Header: 'Duplicate status', accessor: 'duplicate_status' },
      ...Array.from({ length: SUBS_COUNT }, (el, i) => i + 1)
      .map((count) => (
        {
          id: `convSub${count}`,
          accessor: `p_sub${count}`,
          Header: `convSub${count}`,
          description: `convSub${count}`,
        }
      )),
      ...Array.from({ length: SUBS_COUNT }, (el, i) => i + 1)
      .map((count) => (
        {
          id: `sub${count}`,
          accessor: `sub${count}`,
          Header: `Sub${count}`,
          description: `Sub${count}`,
        }
      )),
    ]
  );

  renderRowColor = (row) => {
    let rowColor = '';
    const index  = row.index|| row.viewIndex
    if (index % 2 === 0) {
      rowColor = 'custom-odd'
    } else {
      rowColor = 'custom-even'
    }
    return rowColor
  };

  render () {
    const {
      postbacksLogsData,
      users,
      scope,
      offers,
      total,
      filters,
      loading,
      loaded,
      onPaginationLoad,
      _filterAction,
    } = this.props;
    const { heads } = this.state;
    const userSettings = users.settings || {};
    const pageSize = PAGINATION_OPTIONS[1].value;
    const isPaginationRequired = (total > pageSize);

    return (
      <>
        <PostbacksLogsFilters
          exportSettings={
            {
              scope,
              resourceName: POSTBACKS_LOGS_OPTIONS.TITLE,
              data: postbacksLogsData,
              fields: CSVFields([], getCSVFieldsColumnsList(heads, 'Header', 'id')),
              addNotPresentValues: this.calculateExportValues,
            }
          }
        />
        <LoadableContainer
          loading={false}
          loaded={loaded}
          style={{
            position: 'relative',
            width: '100%',
            maxHeight: '350px'
          }}
        >
          {offers &&
            <Fragment>
              {userSettings.show_postbacks_logs && (
                <CustomizableTable
                  sortable={false}
                  className='custom-data-offer-table'
                  columns={heads}
                  sourceName="publisher_postbacks"
                  keyField="id"
                  data={postbacksLogsData}
                  defaultSortDesc={false}
                  minRows={3}
                  pageSize={isPaginationRequired ? pageSize : postbacksLogsData.length}
                  pages={isPaginationRequired ? Math.ceil(total / pageSize) : 0}
                  showPagination={isPaginationRequired}
                  showPaginationTop={false}
                  showPaginationBottom={isPaginationRequired}
                  showPageSizeOptions={false}
                  manual={isPaginationRequired}
                  pageSizeOptions={[pageSize]}
                  onPageChange={(page) => {
                    if (isPaginationRequired) {
                      filters.page = page + 1;
                      _filterAction(filters);
                      onPaginationLoad();
                    }
                  }}
                  style={{
                    maxHeight: '100%',
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: 0,
                    width: '100%',
                  }}
                  customDragDropColumnsClass={'min-drag-drop-context-container'}
                  loading={loading}
                  getTrProps={(state, row, column, instance) => ({
                    className: (row && row.original && this.renderRowColor(row)),
                  })}
                />
              )}
            </Fragment>
          }
        </LoadableContainer>
      </>
    );
  }
}

PostbacksLogsTable.defaultProps = {
  scope: 'postbacks_logs',
  items: [],
  total: 0,
  filters: {},
  _filterAction: () => null,
  onPaginationLoad: () => null,
};

const mapStateToProps = (state) => ({
  users: state.users || {},
  offers: state.campaigns.items,
  filters: state.postbacks_logs.filters,
  postbacksLogsData: state.postbacks_logs.items,
  total: state.postbacks_logs.total,
  loading: state.postbacks_logs.loading,
  loaded: state.postbacks_logs.loaded,
});

const mapDispatchToProps = (dispatch) => ({
  _filterAction: (filters) => dispatch(filterAction(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostbacksLogsTable);
