import React, { Component } from 'react';
import { Container } from 'reactstrap';
import GlobalReport from './reportComponent/GlobalReport';

class Report extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <Container>
        <GlobalReport />
      </Container>
    )
  }

}

export default Report
