import React from 'react'
import { CardBody, UncontrolledTooltip } from 'reactstrap'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'

import CopyToClipboard from 'react-copy-to-clipboard'
import ClipboardFileIcon from 'mdi-react/ClipboardFileIcon'
import FacebookIcon from 'mdi-react/FacebookIcon'
import TwitterCircleIcon from 'mdi-react/TwitterCircleIcon'
import LinkedinIcon from 'mdi-react/LinkedinIcon'

import noty from '../../../utils/noty'

import { COPY_NOTIFICATION } from '../../../constants'

const copyShareUrl = (url) => noty({ text: COPY_NOTIFICATION })

class SharePart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      share_url: '',
      custom_share_url: '',
    }
  }

  componentDidMount() {
    const { settings } = this.props
    const user = this.props.user
    const invite_key = user && user.invite_key ? user.invite_key : ''
    const referral_url = settings && settings.referral_program && settings.referral_program.referral_url ? settings.referral_program.referral_url  : ''
    let domain = referral_url ? `${referral_url}` : `https://${window.location.hostname}/signup`
    this.setState({
      share_url: invite_key ? `https://${window.location.hostname}/signup?ref=${invite_key}` : `https://${window.location.hostname}/signup`,
      custom_share_url: invite_key ? `${domain}?ref=${invite_key}` : `${domain}`
    }, () => {
      this.props.dispatch(change('share_form', 'share_url', this.state.share_url));
      this.props.dispatch(change('share_form', 'custom_share_url', this.state.custom_share_url));
    })
  }

  render() {
    const { share_url, custom_share_url } = this.state
    const user = this.props.user
    const { settings } = this.props
    const referral_url = settings && settings.referral_program && settings.referral_program.referral_url ? settings.referral_program.referral_url  : ''

    return (
      <CardBody
        style={{
          marginBottom: 0,
        }}
      >
        <div className="card__title mb-20">
          <h5 className="bold-text">
            Share your link
          </h5>
        </div>
        <div className="panel-body">
          <p
            className="mb-10"
            style={{
              fontSize: 15,
            }}
          >
            Boost your profit by sharing your personal referral link with others:
          </p>
          <h5>
            Link to sign up page:
          </h5>
          <div className="form form--horizontal">
            <div className="form__form-group mb-10 no-margin-top">
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  id="TooltipTrackField"
                  className='offer-details__field-container'
                >
                  <Field
                    name="share_url"
                    component="input"
                    type="text"
                    value={share_url}
                    placeholder={share_url}
                    disabled
                  />
                </div>
                <div className="form__form-group-icon customize-icon-container">
                  <CopyToClipboard id="TooltipLinkTrack" text={share_url} onCopy={copyShareUrl}>
                    <div className="">
                      <a href="javascript:;">
                        <ClipboardFileIcon
                          className="customize-icon"
                        />
                      </a>
                    </div>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="top" target="TooltipLinkTrack">
                    {share_url}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
          </div>
          <h5>
            Or custom link:
          </h5>
          <div className="form form--horizontal">
            <div className="form__form-group mb-10 no-margin-top">
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  id="TooltipTrackFieldCustom"
                  className='offer-details__field-container'
                >
                  <Field
                    name="custom_share_url"
                    component="input"
                    type="text"
                    value={custom_share_url}
                    placeholder={custom_share_url}
                    disabled
                  />
                </div>
                <div className="form__form-group-icon customize-icon-container">
                  <CopyToClipboard id="TooltipLinkTrackCustom" text={custom_share_url} onCopy={copyShareUrl}>
                    <div className="">
                      <a href="javascript:;">
                        <ClipboardFileIcon
                          className="customize-icon"
                        />
                      </a>
                    </div>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="top" target="TooltipLinkTrackCustom">
                    {custom_share_url}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
          </div>
          <h5 className="bold-text">
            Share on social
          </h5>
          <p>
            Share the link with your friends on your favorite social network fast & easy:
          </p>
          <ul className="share-link-list">
            <li className=''>
              <a className="share-link"
                 href={`https://www.facebook.com/sharer.php?u=${referral_url ? custom_share_url : share_url}`}
                 rel="nofollow noopener"
                 target="_blank"
                 title="Facebook"
              >
                <span className="icon-facebook-container">
                  <FacebookIcon
                    className="customize-facebook-icon"
                  />
                </span>
              </a>
            </li>
            <li className=''>
              <a className="share-link"
                 href={`https://twitter.com/intent/tweet?url=${referral_url ? custom_share_url : share_url}&hashtags=tracker,RedTrack`}
                 rel="nofollow noopener"
                 target="_blank"
                 title="Twitter"
              >
                <span className="icon-twitter-container">
                  <TwitterCircleIcon
                    className="customize-twitter-icon"
                  />
                </span>
              </a>
            </li>
            <li className='linkedin-item'>
              <a className="share-link"
                 href={`https://www.linkedin.com/shareArticle?mini=true&url=${referral_url ? custom_share_url : share_url}&title=RedTrack&source=RedTrack`}
                 rel="nofollow noopener"
                 target="_blank"
                 title="Linkedin"
              >
                <span className="icon-linkedin-container">
                  <LinkedinIcon
                    className="customize-linkedin-icon"
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </CardBody>
    )
  }
}

SharePart.defaultProps = {
  history: [],
  paymentDetails: {},
}

const mapStateToProps = (state) => ({
  settings: state.users && state.users.settings ? state.users.settings : {},
  user: state.users.currentUser,
})

export default reduxForm({
  form: 'share_form',
})(connect(
  mapStateToProps,
)(SharePart))
