/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import shortid from "shortid";
import CopyToClipboard from 'react-copy-to-clipboard';
import ClipboardFileIcon from 'mdi-react/ClipboardFileIcon';
import HelpCircleIcon from "mdi-react/HelpCircleIcon";

import noty from '../../../../utils/noty';
import { getQueryUrlSign } from '../../../../utils';

import { COPY_NOTIFICATION } from '../../../../constants';

const copyTrackbackLink = (url) => noty({ text: COPY_NOTIFICATION })

class OfferRedirectTrackUrlPart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      trackback_url: props.resource && props.resource.trackback_url ? props.resource.trackback_url : '',
      trackback_url_deeplink: '',
      impression_url: props.resource && props.resource.impression_url ? props.resource.impression_url : '',
    };
  }

  componentDidMount() {
    const { trackback_url } = this.state;
    const deeplinkTrackbackUrl = `${trackback_url}${getQueryUrlSign(trackback_url)}deeplink={deeplink}`;
    this.setState({
      trackback_url_deeplink: deeplinkTrackbackUrl,
    }, () => {
      this.props.dispatch(change('trackback_urls_form', 'trackback_url', this.state.trackback_url));
      this.props.dispatch(change('trackback_urls_form', 'trackback_url_deeplink', this.state.trackback_url_deeplink));
      this.props.dispatch(change('trackback_urls_form', 'impression_url', this.state.impression_url));
    })
  }

  render () {
    const { resource, showImpression } = this.props;
    const { trackback_url_deeplink } = this.state;
    const trackbackUrl = resource && resource.trackback_url ? resource.trackback_url : '';
    const impressionUrl = resource && resource.impression_url ? resource.impression_url : '';
    const customTooltipId = `id_${shortid.generate()}`;

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Redirect Tracking URLS</h5>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group mb-10 no-margin-top">
              <span className="form__form-group-label">Tracking URL</span>
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  id="TooltipTrackField"
                  className='offer-details__field-container'
                >
                  <Field
                    name="trackback_url"
                    component="input"
                    type="text"
                    value={trackbackUrl}
                    placeholder={trackbackUrl}
                    disabled
                  />
                </div>
                <UncontrolledTooltip placement="top" target="TooltipTrackField">
                  {trackbackUrl}
                </UncontrolledTooltip>
                <div className="form__form-group-icon customize-icon-container">
                  <CopyToClipboard id="TooltipLinkTrack" text={trackbackUrl} onCopy={copyTrackbackLink}>
                    <div className="">
                      <a href="javascript:;">
                        <ClipboardFileIcon
                          className="customize-icon"
                        />
                      </a>
                    </div>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="top" target="TooltipLinkTrack">
                    {trackbackUrl}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
            {showImpression && (<div className="form__form-group mb-10 mt-10">
              <span className="form__form-group-label">Impression URL</span>
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  id="TooltipImpressionField"
                  className='offer-details__field-container'
                >
                  <Field
                    name="impression_url"
                    component="input"
                    type="text"
                    value={impressionUrl}
                    placeholder={impressionUrl}
                    disabled
                  />
                </div>
                <UncontrolledTooltip placement="top" target="TooltipImpressionField">
                  {impressionUrl}
                </UncontrolledTooltip>
                <div className="form__form-group-icon customize-icon-container">
                  <CopyToClipboard id="TooltipLinkImpression" text={impressionUrl} onCopy={copyTrackbackLink}>
                    <div className="">
                      <a href="javascript:;">
                        <ClipboardFileIcon
                          className="customize-icon"
                        />
                      </a>
                    </div>
                  </CopyToClipboard>
                  <UncontrolledTooltip placement="bottom" target="TooltipLinkImpression">
                    {impressionUrl}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>)}
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default reduxForm({
  form: 'trackback_urls_form',
})(OfferRedirectTrackUrlPart)
