const MAX_GROUPING_VALUES = 5;
const INITIAL_PAGE_NUMBER = 1;
const INITIAL_ROWS_COUNT = 10;
const INITIAL_ROWS_COUNT_OFFERS = 100;
const UNAUTHORIZED_USER_STATUS = 401;
const CONVTYPE_COUNT = 20;
const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT_HOUR = 'YYYY-MM-DD hh:mm'
const LAST_HOUR_FLAG_VALUE = 'lasthour'
const NA_VALUE = 'N/A';
const EMPTY_ROW_STRING_VALUE = '';
const CURRENCY = 'USD'
const TOTAL_TEXT = 'Total:'
const SESSION_OBJECT_KEY = 'sessionObject'
const SETTINGS_TIME_EXPIRED_MIN = 10
const ALLOWED_TRAFFIC = 'All traffic types'
const ALL_OS_DEVICES_ALLOWED = 'All devices & OS allowed'
const ALL_COUNTRIES_ALLOWED = 'All countries allowed'
const ALL_TYPES_ALLOWED = 'All types allowed'
const COPY_NOTIFICATION = 'URL copied to clipboard'
const COPY_NOTIFICATION_COUPON = 'Coupon copied to clipboard'
const REV_SHARE_COST_MODEL = 'RevShare'
const DASHBOARD_BY_DAY = 'by_date'
const DASHBOARD_BY_OFFER = 'by_offer'
const STATUS_ACTIVE = 1
const STATUS_ARCHIVED = 2
const STATUS_DELETED = 3
const CAMPAIGN_STATUSES = {
  [STATUS_ACTIVE]: 'Active',
  [STATUS_ARCHIVED]: 'Archived',
  [STATUS_DELETED]: 'Deleted',
}
const DEFAULT_GROUPING_BY_DATE = ['date']
const GROUPING_BY_OFFER = ['campaign']
const STATUS_CAMPAIGN_DELETED = 3
const LANGUAGES = [
  { value: 'ch', label: '中国' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'ru', label: 'Russian' },
]
const CURRENCY_OPTIONS_LIST = {
  USD: '$',
  EUR: '€',
  CAD: '$',
  HKD: '$',
  ISK: 'Kr',
  PHP: '₱',
  DKK: 'kr',
  HUF: 'Ft',
  CZK: 'Kč',
  AUD: '$',
  RON: 'L',
  SEK: 'kr',
  IDR: 'Rp',
  INR: '₹',
  BRL: 'R$',
  RUB: 'р.',
  HRK: 'Kn',
  JPY: '¥',
  THB: '฿',
  CHF: '₣',
  SGD: '$',
  PLN: 'zł',
  BGN: 'лв',
  TRY: '₤',
  CNY: '¥',
  NOK: 'kr',
  NZD: '$',
  ZAR: 'R',
  MXN: '$',
  ILS: '₪',
  GBP: '£',
  KRW: '₩',
  MYR: 'RM',
}
const CURRENCY_OPTIONS_LABELS_LIST = {
  USD: 'USD',
  EUR: 'EUR',
  CAD: 'CAD',
  HKD: 'HKD',
  ISK: 'ISK',
  PHP: 'PHP',
  DKK: 'DKK',
  HUF: 'HUF',
  CZK: 'CZK',
  AUD: 'AUD',
  RON: 'RON',
  SEK: 'SEK',
  IDR: 'IDR',
  INR: 'INR',
  BRL: 'BRL',
  RUB: 'RUB',
  HRK: 'HRK',
  JPY: 'JPY',
  THB: 'THB',
  CHF: 'CHF',
  SGD: 'SGD',
  PLN: 'PLN',
  BGN: 'BGN',
  TRY: 'TRY',
  CNY: 'CNY',
  NOK: 'NOK',
  NZD: 'NZD',
  ZAR: 'ZAR',
  MXN: 'MXN',
  ILS: 'ILS',
  GBP: 'GBP',
  KRW: 'KRW',
  MYR: 'MYR',
}
const DEFAULT_CURRENCY_SIGN = '$'
const DEFAULT_CURRENCY_LABEL = 'USD'
const PAGINATION_OPTIONS = [
  {
    label: 'Disabled',
    value: 100000,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '500',
    value: 500,
  },
  {
    label: '750',
    value: 750,
  },
  {
    label: '1000',
    value: 1000,
  },
];
export {
  CONVTYPE_COUNT,
  DATE_FORMAT,
  DATE_FORMAT_HOUR,
  LAST_HOUR_FLAG_VALUE,
  MAX_GROUPING_VALUES,
  INITIAL_PAGE_NUMBER,
  INITIAL_ROWS_COUNT,
  INITIAL_ROWS_COUNT_OFFERS,
  NA_VALUE,
  EMPTY_ROW_STRING_VALUE,
  CURRENCY,
  ALLOWED_TRAFFIC,
  REV_SHARE_COST_MODEL,
  STATUS_ACTIVE,
  STATUS_ARCHIVED,
  STATUS_DELETED,
  CAMPAIGN_STATUSES,
  COPY_NOTIFICATION,
  ALL_OS_DEVICES_ALLOWED,
  ALL_COUNTRIES_ALLOWED,
  ALL_TYPES_ALLOWED,
  STATUS_CAMPAIGN_DELETED,
  TOTAL_TEXT,
  DEFAULT_GROUPING_BY_DATE,
  DASHBOARD_BY_DAY,
  DASHBOARD_BY_OFFER,
  GROUPING_BY_OFFER,
  UNAUTHORIZED_USER_STATUS,
  SESSION_OBJECT_KEY,
  SETTINGS_TIME_EXPIRED_MIN,
  LANGUAGES,
  CURRENCY_OPTIONS_LIST,
  CURRENCY_OPTIONS_LABELS_LIST,
  DEFAULT_CURRENCY_SIGN,
  DEFAULT_CURRENCY_LABEL,
  COPY_NOTIFICATION_COUPON,
  PAGINATION_OPTIONS,
}
