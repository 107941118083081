const PRESCISION = 4

export const rnd = value => Math.round(value * Math.pow(10, PRESCISION)) / Math.pow(10, PRESCISION)

export const sum = items => rnd(items.reduce(((sum, item) => sum+(item || 0)), 0))

export const capitalize = (string) => (string.charAt(0).toUpperCase() + string.slice(1)).split('_').join(' ');

export const sumHiddenColumn = (rows, field) => {
  let total = 0
  if (rows) {
    for(let i = 0, len = rows.length; i < len; i++) {
      if(rows[i][field] !== undefined) {
        total += parseFloat(rows[i][field])
      }
      else {
        if (rows[i].hasOwnProperty('_subRows')) total += sumHiddenColumn(rows[i]._subRows, field)
      }
    }
  }
  return total
}
