import moment from "moment";
import {
  LOAD_PUBLIHERS_POSTBACKS,
  ADD_PUBLIHERS_POSTBACKS,
  ADD_PUBLIHERS_POSTBACKS_ERROR,
  FILTER_PUBLIHERS_POSTBACKS,
} from './../actions/postbacksLogsActions'

const initialState = {
  items: [],
  total: 0,
  filters: {
    date_from: moment().subtract(1, 'days').startOf("day"),
    date_to: moment().endOf("day"),
    publisher_ids: ['all'],
    campaign_ids: [],
  },
  loading: false,
  loaded: false,
}

export const postbacks_logs = (state=initialState, action) => {
  switch (action.type) {
    case LOAD_PUBLIHERS_POSTBACKS:
      return {
        ...state,
        loading: true,
        loaded: false
      }

    case ADD_PUBLIHERS_POSTBACKS:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: action.data.items || [],
        total: action.data.total,
      }

    case ADD_PUBLIHERS_POSTBACKS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: [],
        total: 0,
      };

    case FILTER_PUBLIHERS_POSTBACKS:
      return {
        ...state,
        filters: action.filters
      }

    default:
      return state
  }
}
