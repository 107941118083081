import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import ResetPasswordForm from './components/ResetPasswordForm';

class ResetPassword extends Component {

  render(){
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">
                Reset Password
               </h3>
                <h4 className="account__subhead subhead">Your credentials</h4>
            </div>
            <ResetPasswordForm history={this.props.history} onSubmit />
           </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
