import React from 'react'
import {CardBody, Col, Row} from "reactstrap"
import { connect } from "react-redux"

import ReferralsCount from '../commissionPaidPart/ReferralsCount'
import CommissionDue from '../commissionPaidPart/CommissionDue'
import TotalCommission from '../commissionPaidPart/TotalCommission'
import CommissionPaid from '../commissionPaidPart/CommissionPaid'

class ReferralsCommissionPaidPart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentDetails: { ...props.paymentDetails },
    }
  }

  componentDidMount() {
  }

  render() {
    const { user, paymentDetails } = this.props;

    return (
      <Row>
        <Col sm={6}>
          <ReferralsCount
            history={paymentDetails.history || []}
            paymentDetails={paymentDetails || {}}
            user={user}
          />
        </Col>
        {/*<Col sm={3}>*/}
        {/*  <CommissionDue*/}
        {/*    history={paymentDetails.history || []}*/}
        {/*    paymentDetails={paymentDetails || {}}*/}
        {/*  />*/}
        {/*</Col>*/}
        <Col sm={6}>
          <TotalCommission
            history={paymentDetails.history || []}
            paymentDetails={paymentDetails || {}}
          />
        </Col>
        {/*<Col sm={3}>*/}
        {/*  <CommissionPaid*/}
        {/*    history={paymentDetails.history || []}*/}
        {/*    paymentDetails={paymentDetails || {}}*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>
    )
  }
}

ReferralsCommissionPaidPart.defaultProps = {
  history: [],
  paymentDetails: {},
  user: {},
}

const mapStateToProps = (state) => ({
  user: state.users.currentUser,
})

export default connect(
  mapStateToProps,
)(ReferralsCommissionPaidPart)
