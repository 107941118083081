import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';

class MainWrapper extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    const { theme } = this.props;

    let faviconLink = this.props.settings && this.props.settings.favicon_url ? this.props.settings.favicon_url : '/favicon.png'
    let link = document.querySelector("link[rel*='shortcut']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = faviconLink
    document.getElementsByTagName('head')[0].appendChild(link)
    let title = this.props.settings && this.props.settings.title ? this.props.settings.title : ''
    document.title = title
    document.querySelector('meta[property="og:title"]').setAttribute("content", title);
    document.querySelector('meta[property="og:image"]').setAttribute("content", faviconLink);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", faviconLink);
    return (
      <div className={theme.className}>
        <div className="wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  settings: state.users ? state.users.settings : {},
}))(MainWrapper);
