import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from '../containers/App/store'
import { signOutAction } from './../redux/actions/usersActions'
import { TrafficDashboard } from '../containers/Dashboard'

const isLoggedIn = () => {
    return !!store.getState().users.currentUser
}

// Routes with auth checking`
export class PublicRoute extends React.Component {

    render() {
        const users = store.getState().users || {};
        let path = this.props.path
        let component = this.props.component
        if(this.props.path === '/referral' && users.settings && !users.settings.referral_program) {
            if(users.settings.referral_program && !users.settings.referral_program.enabled) {
                path = '/dashboard'
                component = TrafficDashboard
                window.history.replaceState({}, document.title,  "/dashboard")
            } else if(!users.settings.referral_program) {
                path = '/dashboard'
                component = TrafficDashboard
                window.history.replaceState({}, document.title,  "/dashboard")
            }
        } else if(this.props.path === '/referral' && users.settings && users.settings.referral_program) {
            if(users.settings.referral_program && !users.settings.referral_program.enabled) {
                path = '/dashboard'
                component = TrafficDashboard
                window.history.replaceState({}, document.title,  "/dashboard")
            }
        } else if (this.props.path === '/referral' && !users.settings) {
            path = '/dashboard'
            component = TrafficDashboard
            window.history.replaceState({}, document.title,  "/dashboard")
        }
        return (
            <Route {...this.props} path={path} component={component} />
        )
    }
}

export function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => isLoggedIn()
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
        />
    )
}
