import React from 'react';
import numeral from 'numeral';

import { sum, sumHiddenColumn } from '../../utils/Helpers';
import {
  // renderConvTypeAovDescription,
  // renderConvTypeAovTitle,
  // renderConvTypeCpaDescription,
  // renderConvTypeCpaTitle,
  // renderConvTypeRateDescription,
  // renderConvTypeRoiTitle,
  // renderConvTypeRoiDescription,
  renderConvTypeRateTitle,
  renderConvTypeTitle,
  renderRevenueTypeTitle,
} from '../../utils/index';

/**
 * @param user {object}
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderConvType = (user, convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
    .map((count) => (
      {
        id: `convtype${count}`,
        title: renderConvTypeTitle(`convtype${count}`, 'conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: `convtype${count}`,
        Header: () => (
          <span>
            {renderConvTypeTitle(`convtype${count}`, 'conv', user)}
          </span>
        ),
        Footer: (t) => sum(t.data.map((r) => r[`convtype${count}`])),
        aggregate: values => sum(values),
      }
    ))
);

// /**
//  * @param user {object}
//  * @param props {object}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderStatConvType = (user, props, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convtype${count}`,
//         title: renderConvTypeTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `stat.convtype${count}`,
//         minWidth: 70,
//         Header: (t) => {
//           const value = sum(t.data.map((r) => r[`convtype${count}`]));
//           return (
//             <HeaderWithTotal
//               titleNode={(
//                 <Tooltip title={renderConvTypeTitle(`convtype${count}`, 'conv', user, props)} arrow placement="top">
//                   <span>
//                     {renderConvTypeTitle(`convtype${count}`, 'conv', user, props)}
//                   </span>
//                 </Tooltip>
//             )}
//               totalValueNode={<HeaderTotalCell value={value} />}
//             />
//           );
//         },
//         Cell: (row) => (row.value || 0),
//         Footer: (t) => sum(t.data.map((r) => r[`convtype${count}`])),
//       }
//     ))
// );

/**
 * @param user {object}
 * @param props {object}
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderConvRateType = (user, props, convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
    .map((count) => (
      {
        id: `convratetype${count}`,
        title: renderConvTypeRateTitle(`convtype${count}`, 'conv', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: `convratetype${count}`,
        Cell: (data) => numeral(data.row[`convtype${count}`] && data.row.clicks !== 0 ? data.row[`convtype${count}`] / data.row.clicks : 0).format('0.00%'),
        Header: () => (
          <span>{renderConvTypeRateTitle(`convtype${count}`, 'conv', user)}</span>
        ),
        Footer: (t) => {
          const conversions = sum(t.data.map((r) => r[`convtype${count}`]));
          const clicks = sum(t.data.map((r) => r.clicks));
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%');
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, `convtype${count}`);
          const clicks = sumHiddenColumn(rows, 'clicks');
          return clicks !== 0 ? conversions / clicks : 0;
        },
      }
    ))
);

// /**
//  * @param user {object}
//  * @param props {object}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderStatConvRateType = (user, props, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convratetype${count}`,
//         title: renderConvTypeRateTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `stat.convratetype${count}`,
//         description: renderConvTypeRateDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (row) => numeral(row.original.stat[`convtype${count}`] && row.original.stat.clicks !== 0 ? row.original.stat[`convtype${count}`] / row.original.stat.clicks : 0)
//           .format('0.00%'),
//         Header: (t) => {
//           const conversions = sum(t.data.map((r) => r[`convtype${count}`]));
//           const clicks = sum(t.data.map((r) => r.clicks));
//           const value = numeral(clicks !== 0 ? conversions / clicks : 0)
//             .format('0.00%');
//           return (
//             <HeaderWithTotal
//               titleNode={(
//                 <Tooltip
//                   title={renderConvTypeRateDescription(`convtype${count}`, 'conv', user, props)}
//                   arrow
//                   placement="top"
//                 >
//                   <span>
//                     {renderConvTypeRateTitle(`convtype${count}`, 'conv', user, props)}
//                   </span>
//                 </Tooltip>
//             )}
//               totalValueNode={<HeaderTotalCell value={value} />}
//             />
//           );
//         },
//         Footer: (t) => {
//           const conversions = sum(t.data.map((r) => r[`convtype${count}`]));
//           const clicks = sum(t.data.map((r) => r.clicks));
//           return numeral(clicks !== 0 ? conversions / clicks : 0)
//             .format('0.00%');
//         },
//       }
//     ))
// );

/**
 * @param user {object}
 * @param currencySign {string}
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderRevenueType = (user, currencySign = '', convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
    .map((count) => (
      {
        id: `revenuetype${count}`,
        title: renderRevenueTypeTitle(`revenuetype${count}`, 'revenue', user),
        className: '',
        minWidth: 180,
        width: 180,
        accessor: `revenuetype${count}`,
        Header: () => (
          <span>
            {renderRevenueTypeTitle(`revenuetype${count}`, 'revenue', user)}
          </span>
        ),
        Cell: row => `${currencySign}${numeral(row.value || 0).format('0.00[00]')}`,
        Footer: t => `${currencySign}${numeral(sum(t.data.map(r => r[`revenuetype${count}`]))).format('0.00[00]')}`,
        aggregate: values => sum(values),
      }
    ))
);

// /**
//  * @param user {object}
//  * @param props {object}
//  * @param currencySign {string}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderStatRevenueType = (user, props, currencySign, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `revenuetype${count}`,
//         title: renderRevenueTypeTitle(`revenuetype${count}`, 'revenue', user, props),
//         className: 'text-right',
//         accessor: `stat.revenuetype${count}`,
//         minWidth: 70,
//         Header: (t) => {
//           const value = (
//             <FormattedData
//               currencySign={currencySign}
//               value={sum(t.data.map((r) => r[`revenuetype${count}`]))}
//             />
//           );
//           return (
//             <HeaderWithTotal
//               titleNode={(
//                 <Tooltip
//                   title={renderRevenueTypeTitle(`revenuetype${count}`, 'revenue', user, props)}
//                   arrow
//                   placement="top"
//                 >
//                   <span>
//                     {renderRevenueTypeTitle(`revenuetype${count}`, 'revenue', user, props)}
//                   </span>
//                 </Tooltip>
//             )}
//               totalValueNode={<HeaderTotalCell value={value} />}
//             />
//           );
//         },
//         Cell: (row) => <FormattedData currencySign={currencySign} value={row.value} />,
//         Footer: (t) => (
//           <FormattedData
//             currencySign={currencySign}
//             value={sum(t.data.map((r) => r[`revenuetype${count}`]))}
//           />
//         ),
//       }
//     ))
// );

// /**
//  * @param user {object}
//  * @param props {object}
//  * @param currencySign {string}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderConvTypeCpa = (user, props, currencySign = '', convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convcpatype${count}`,
//         title: renderConvTypeCpaTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `convcpatype${count}`,
//         description: renderConvTypeCpaDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (data) => {
//           const value = data.row.cost && data.row[`convtype${count}`] !== 0 ? data.row.cost / data.row[`convtype${count}`] : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//         Header: () => (
//           <Tooltip
//             arrow
//             placement="top"
//             title={renderConvTypeCpaDescription(`convtype${count}`, 'conv', user, props)}
//           >
//             <span>{renderConvTypeCpaTitle(`convtype${count}`, 'conv', user, props)}</span>
//           </Tooltip>
//         ),
//         Footer: (t) => {
//           const conversions = sum(t.data.map((r) => r[`convtype${count}`]));
//           const cost = sum(t.data.map((r) => r.cost));
//           const value = conversions !== 0 ? cost / conversions : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//         aggregate: (values, rows) => {
//           const conversions = sumHiddenColumn(rows, `convtype${count}`);
//           const cost = sumHiddenColumn(rows, 'cost');
//           return conversions !== 0 ? cost / conversions : 0;
//         },
//       }
//     ))
// );
//
// /**
//  * @param user {object}
//  * @param props {object}
//  * @param currencySign {string}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderStatConvTypeCpa = (user, props, currencySign, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convcpatype${count}`,
//         title: renderConvTypeCpaTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `stat.convcpatype${count}`,
//         description: renderConvTypeCpaDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (row) => {
//           const value = (row.original.stat.cost && (row.original.stat[`convtype${count}`] !== 0))
//             ? row.original.stat.cost / row.original.stat[`convtype${count}`] : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//         Header: (t) => {
//           const conversions = sum(t.data.map((r) => r._original.stat[`convtype${count}`]));
//           const cost = sum(t.data.map((r) => r._original.stat.cost));
//           const value = conversions !== 0 ? cost / conversions : 0;
//           const renderValue = <FormattedData currencySign={currencySign} value={value} />;
//           return (
//             <HeaderWithTotal
//               titleNode={(
//                 <Tooltip
//                   arrow
//                   placement="top"
//                   title={renderConvTypeCpaDescription(`convtype${count}`, 'conv', user, props)}
//                 >
//                   <span>
//                     {renderConvTypeCpaTitle(`convtype${count}`, 'conv', user, props)}
//                   </span>
//                 </Tooltip>
//             )}
//               totalValueNode={<HeaderTotalCell value={renderValue} />}
//             />
//           );
//         },
//         Footer: (t) => {
//           const conversions = sum(t.data.map((r) => r._original.stat[`convtype${count}`]));
//           const cost = sum(t.data.map((r) => r._original.stat.cost));
//           const value = conversions !== 0 ? cost / conversions : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//       }
//     ))
// );
//
// /**
//  * @param user {object}
//  * @param props {object}
//  * @param currencySign {string}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderConvTypeAov = (user, props, currencySign = '', convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convaovtype${count}`,
//         title: renderConvTypeAovTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `convaovtype${count}`,
//         description: renderConvTypeAovDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (data) => {
//           const value = data.row[`revenuetype${count}`] && data.row[`convtype${count}`] !== 0 ? data.row[`revenuetype${count}`] / data.row[`convtype${count}`] : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//         Header: () => (
//           <Tooltip
//             arrow
//             placement="top"
//             title={renderConvTypeAovDescription(`convtype${count}`, 'conv', user, props)}
//           >
//             <span>{renderConvTypeAovTitle(`convtype${count}`, 'conv', user, props)}</span>
//           </Tooltip>
//         ),
//         Footer: (t) => {
//           const conversions = sum(t.data.map((r) => r[`convtype${count}`]));
//           const revenue = sum(t.data.map((r) => r[`revenuetype${count}`]));
//           const value = conversions !== 0 ? revenue / conversions : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//         aggregate: (values, rows) => {
//           const conversions = sumHiddenColumn(rows, `convtype${count}`);
//           const revenue = sumHiddenColumn(rows, `revenuetype${count}`);
//           return conversions !== 0 ? revenue / conversions : 0;
//         },
//       }
//     ))
// );
//
// /**
//  * @param user {object}
//  * @param props {object}
//  * @param currencySign {string}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderStatConvTypeAov = (user, props, currencySign, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convaovtype${count}`,
//         title: renderConvTypeAovTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `stat.convaovtype${count}`,
//         description: renderConvTypeAovDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (row) => {
//           const value = (row.original.stat[`revenuetype${count}`] && (row.original.stat[`convtype${count}`] !== 0))
//             ? row.original.stat[`revenuetype${count}`] / row.original.stat[`convtype${count}`] : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//         Header: (t) => {
//           const conversions = sum(t.data.map((r) => r._original.stat[`convtype${count}`]));
//           const revenue = sum(t.data.map((r) => r._original.stat[`revenuetype${count}`]));
//           const value = conversions !== 0 ? revenue / conversions : 0;
//           const renderValue = <FormattedData currencySign={currencySign} value={value} />;
//           return (
//             <HeaderWithTotal
//               titleNode={(
//                 <Tooltip
//                   arrow
//                   placement="top"
//                   title={renderConvTypeAovDescription(`convtype${count}`, 'conv', user, props)}
//                 >
//                   <span>
//                     {renderConvTypeAovTitle(`convtype${count}`, 'conv', user, props)}
//                   </span>
//                 </Tooltip>
//             )}
//               totalValueNode={<HeaderTotalCell value={renderValue} />}
//             />
//           );
//         },
//         Footer: (t) => {
//           const conversions = sum(t.data.map((r) => r._original.stat[`convtype${count}`]));
//           const revenue = sum(t.data.map((r) => r._original.stat[`revenuetype${count}`]));
//           const value = conversions !== 0 ? revenue / conversions : 0;
//           return <FormattedData currencySign={currencySign} value={value} />;
//         },
//       }
//     ))
// );

// /**
//  * @param user {object}
//  * @param props {object}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderConvTypeRoi = (user, props, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convroitype${count}`,
//         title: renderConvTypeRoiTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `convroitype${count}`,
//         description: renderConvTypeRoiDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (data) => numeral(data.row[`revenuetype${count}`] && data.row.cost !== 0 ? data.row[`revenuetype${count}`] / data.row.cost : 0).format('0.00%'),
//         Header: () => (
//           <Tooltip
//             arrow
//             placement="top"
//             title={renderConvTypeRoiDescription(`convtype${count}`, 'conv', user, props)}
//           >
//             <span>{renderConvTypeRoiTitle(`convtype${count}`, 'conv', user, props)}</span>
//           </Tooltip>
//         ),
//         Footer: (t) => {
//           const revenuetype = sum(t.data.map((r) => r[`revenuetype${count}`]));
//           const cost = sum(t.data.map((r) => r.cost));
//           return numeral(cost !== 0 ? revenuetype / cost : 0).format('0.00%');
//         },
//         aggregate: (values, rows) => {
//           const revenuetype = sumHiddenColumn(rows, `revenuetype${count}`);
//           const cost = sumHiddenColumn(rows, 'cost');
//           return cost !== 0 ? revenuetype / cost : 0;
//         },
//       }
//     ))
// );
//
// /**
//  * @param user {object}
//  * @param props {object}
//  * @param convTypeCount {number}
//  * @returns {array}
//  */
// export const renderStatConvRoiType = (user, props, convTypeCount = 20) => (
//   Array.from({ length: convTypeCount }, (el, i) => i + 1)
//     .map((count) => (
//       {
//         id: `convroitype${count}`,
//         title: renderConvTypeRoiTitle(`convtype${count}`, 'conv', user, props),
//         className: 'text-right',
//         accessor: `stat.convroitype${count}`,
//         description: renderConvTypeRoiDescription(`convtype${count}`, 'conv', user, props),
//         Cell: (row) => numeral(row.original.stat[`revenuetype${count}`] && row.original.stat.cost !== 0 ? row.original.stat[`revenuetype${count}`] / row.original.stat.cost : 0)
//           .format('0.00%'),
//         Header: (t) => {
//           const revenuetype = sum(t.data.map((r) => r[`revenuetype${count}`]));
//           const cost = sum(t.data.map((r) => r.cost));
//           const value = numeral(cost !== 0 ? revenuetype / cost : 0)
//             .format('0.00%');
//           return (
//             <HeaderWithTotal
//               titleNode={(
//                 <Tooltip
//                   title={renderConvTypeRoiDescription(`convtype${count}`, 'conv', user, props)}
//                   arrow
//                   placement="top"
//                 >
//                   <span>
//                     {renderConvTypeRoiTitle(`convtype${count}`, 'conv', user, props)}
//                   </span>
//                 </Tooltip>
//               )}
//               totalValueNode={<HeaderTotalCell value={value} />}
//             />
//           );
//         },
//         Footer: (t) => {
//           const revenuetype = sum(t.data.map((r) => r[`revenuetype${count}`]));
//           const cost = sum(t.data.map((r) => r.cost));
//           return numeral(cost !== 0 ? revenuetype / cost : 0)
//             .format('0.00%');
//         },
//       }
//     ))
// );
