const validate = (values) => {
    const errors = {};
    if (!values.login) {
        errors.login = 'Email field shouldn’t be empty';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
        errors.login = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'Password field shouldn’t be empty';
    }

    return errors;
};

export default validate;
