import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';

import store from '../../App/store';
import { loadSettingsAction, getUserDataAction } from '../../../redux/actions/usersActions';
import { loadReferralsReportAction, changeReportPageAction, changeReportPageSizeAction } from '../../../redux/actions/reportActions';
import SharePart from '../components/SharePart';
import ReferralsCommissionPaidPart from '../components/ReferralsCommissionPaidPart';
import ReferralsProgramReportPart from '../components/ReferralsProgramReportPart';
import { REPORTS_REFERRAL_PROGRAM_OPTIONS } from '../../../constants/reports/referral';

class ReferralPageContainer extends Component {

  constructor(props) {
    super(props);
    const user = store.getState().users.currentUser || {}
    const referral_status = user
    this.state = {
      paymentDetails: {
        ...referral_status,
      },
    };
    this.onPaginationLoad = this.onPaginationLoad.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
  }

  componentDidMount() {
    const user = store.getState().users.currentUser || {}
    const referral_status = user
    store.dispatch(loadSettingsAction())
    store.dispatch(loadReferralsReportAction())
    if(!referral_status) {
      store.dispatch(getUserDataAction()).then(result => {
        this.setState({
          paymentDetails: {
            ...result,
          }
        })
      })
    }
  }

  onPaginationLoad(p) {
    store.dispatch(changeReportPageAction(REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE, p));
    store.dispatch(loadReferralsReportAction());
  };

  onPageSizeChange(ps) {
    store.dispatch(changeReportPageSizeAction(REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE, ps));
    store.dispatch(loadReferralsReportAction());
  };

  render() {
    const paymentDetails = this.state.paymentDetails
    const users = store.getState().users || {};
    const usersSettings = users.settings || {};

    return (
      <>
        <Col md={6} style={{margin: 'auto'}}>
          <Card>
            <div className="form__form-group">
              <SharePart
                usersSettings={usersSettings}
              />
            </div>
            <div className="form__form-group">
              <ReferralsCommissionPaidPart
                paymentDetails={paymentDetails || {}}
                user={users.currentUser || {}}
              />
            </div>
          </Card>
        </Col>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <ReferralsProgramReportPart
                onPaginationLoad={this.onPaginationLoad}
                onPageSizeChange={this.onPageSizeChange}
              />
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}


export default ReferralPageContainer
