import React, { Component } from 'react';
import store from "../../containers/App/store";
import { Container, Row } from 'reactstrap';
import OffersTable from './components/OffersTable'
import { loadSettingsAction } from "../../redux/actions/usersActions";

class Offers extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        store.dispatch(loadSettingsAction())
    }


    render () {
        return (
            <Container>
                <Row>
                    <OffersTable />
                </Row>
            </Container>
        )
    }

}

export default Offers
