import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import EditIcon from 'mdi-react/EditIcon';
import DriveDocumentIcon from 'mdi-react/DriveDocumentIcon';
import { sum } from '../utils/Helpers';

const STATUS_OPTIONS = [
  {
    label: 'New',
    value: '0',
    color: 'blue',
  },
  {
    label: 'Declined',
    value: 1,
    color: 'red',
  },
  {
    label: 'Partially Paid',
    value: 2,
    color: '#f6c956',
  },
  {
    label: 'Paid in full',
    value: 3,
    color: 'green',
  },
];
const PAGINATION_OPTIONS = [
  {
    label: 'Disabled',
    value: 100000,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '500',
    value: 500,
  },
  {
    label: '750',
    value: 750,
  },
  {
    label: '1000',
    value: 1000,
  },
];
const getPublishersPayoutsColumns = (currencySign = '', userData = {}, actions = {}) => [
  {
    id: 'serial_number',
    title: '#',
    accessor: 'serial_number',
    width: 60,
  },
  {
    id: 'created_at',
    title: 'Date',
    accessor: 'created_at',
    description: 'Date',
    Header: () => (
      <span>Date</span>
    ),
    Cell: (row) => (moment(row.value).format('YYYY-DD-MM hh:mm')),
    Footer: <span>Total:</span>,
  },
  {
    id: 'status',
    title: 'Status',
    accessor: 'status',
    description: 'Status',
    Header: () => (
      <span>Status</span>
    ),
    Cell: (row) => {
      const { value } = row;
      const statusValue = STATUS_OPTIONS.find((el) => Number(el.value) === value);
      return (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <span style={{ color: (statusValue || {}).color }}>{((statusValue || {}).label || '')}</span>
          {value === 0 && <div className="ml-10">
            <a href="javascript:;">
              <EditIcon
                onClick={() => actions.showModal ? actions.showModal(row) : null}
                className="customize-icon"
              />
            </a>
          </div>}
        </div>
      );
    },
  },
  {
    id: 'payment_date',
    title: 'Payment date',
    accessor: 'payment_date',
    description: 'Payment date',
    Header: () => (
      <span>Payment date</span>
    ),
    Cell: (row) => (row.value ? moment(row.value).format('YYYY-DD-MM hh:mm') : ''),
  },
  {
    id: 'amount',
    title: 'Requested Amount',
    accessor: 'amount',
    description: 'Requested Amount',
    Header: () => (
      <span>Requested Amount</span>
    ),
    Cell: (row) => `${numeral(row.value).format('0.00[00]')} ${currencySign}`,
    Footer: (t) => {
      const requestedAmount = sum(t.data.map(r => r.amount))
      return <span>{`${numeral(requestedAmount).format('0.00[00]')} ${currencySign}`}</span>;
    },
  },
  {
    id: 'amount_paid',
    title: 'Paid Amount',
    accessor: 'amount_paid',
    description: 'Paid Amount',
    Header: () => (
      <span>Paid Amount</span>
    ),
    Cell: (row) => {
      const { value } = row;
      return <span>{`${numeral(value).format('0.00[00]')} ${currencySign}`}</span>;
    },
    Footer: (t) => {
      const paidAmount = sum(t.data.map(r => r.amount_paid))
      return <span>{`${numeral(paidAmount).format('0.00[00]')} ${currencySign}`}</span>;
    },
  },
  {
    id: 'amount',
    title: 'Pending Amount',
    accessor: 'amount',
    description: 'Pending Amount',
    Header: () => (
      <span>Pending Amount</span>
    ),
    Cell: (row) => {
      const { original } = row;
      const requestedAmount = original.amount;
      const paidAmount = original.amount_paid;
      const result = (requestedAmount || 0) - (paidAmount || 0);
      return <span>{`${numeral(result).format('0.00[00]')} ${currencySign}`}</span>;
    },
    Footer: (t) => {
      const requestedAmount = sum(t.data.map(r => r.amount))
      const paidAmount = sum(t.data.map(r => r.amount_paid))
      const result = (requestedAmount || 0) - (paidAmount || 0);
      return <span>{`${numeral(result).format('0.00[00]')} ${currencySign}`}</span>;
    },
  },
  {
    id: 'note',
    title: 'Notes',
    accessor: 'note',
    description: 'Notes',
    Header: () => (
      <span>Notes</span>
    ),
    Cell: (row) => (row.value || 'N/A'),
  },
  {
    id: 'invoice',
    title: 'Invoice',
    accessor: 'invoice',
    description: 'Invoice',
    Header: () => (
      <span>Invoice</span>
    ),
    Cell: (row) => {
      let cellNode = (
        <div className="ml-10">
          <a href="javascript:;"
             onClick={() => actions.downloadInvoice
               ? actions.downloadInvoice(row.original, userData.user, userData.usersSettings) : null}
          >
            <DriveDocumentIcon
              className="customize-icon"
            />
          </a>
        </div>
      );
      if (row.original.status === undefined) {
        cellNode = (
          <span>{''}</span>
        );
      }
      return cellNode;
    },
  },
];

export {
  getPublishersPayoutsColumns,
  STATUS_OPTIONS,
  PAGINATION_OPTIONS,
};
