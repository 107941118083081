/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react'
import ZipBoxOutlineIcon from 'mdi-react/ZipBoxOutlineIcon'
import { Card, CardBody, Row, Col } from 'reactstrap'

const CreativesUrlLinkFormatter = ({ value }) => {
  return <a target={'blank'} href={value.image_url} title={value.title} download={value.title}>Download</a>
}

const CreativesUrlLinkFormatterZip = ({ value }) => {
  return <a target={'blank'} href={value.image_url} title={value.title} download={value.title}><ZipBoxOutlineIcon className="customize-icon" /></a>
}

class OfferCreativesPart extends Component {
  constructor (props) {
    super(props)
  }

  _renderCreativesList = (creatives) => {
    return creatives.map((el, i) => {
      const ZIP_PATTERN = /^.*\.(zip)$/
      const isZipFile = ZIP_PATTERN.test(el.image_url)
      return (
        <div className="form__form-group-field no-margin-left no-padding-left mb-10">
          <Row className='offer-details__creatives-row-resize'>
            <Col md={6} lg={6} sm={6}>
              <div className="offer-details__img-wrap">
                {isZipFile ? CreativesUrlLinkFormatterZip({ value: el }) : <img className="offer-details__img" src={`${el.image_url}`} alt={el.title} />}
                <div className="form__form-group-field mb-5 mt-5">{CreativesUrlLinkFormatter({ value: el })}</div>
              </div>
            </Col>
            <Col md={6} lg={6} sm={6}>
              <div className='creatives-description__container'>
            <span>
              {el.description}
            </span>
              </div>
            </Col>
          </Row>
        </div>
      )
    })
  }

  render () {
    const { resource } = this.props;

    return (
      <Card>
        <CardBody
          className="panel__body creatives-panel__body no-padding-left no-padding-bottom"
        >
          <div className="card__title">
            <h5 className="bold-text">Creatives:</h5>
          </div>
          <div className="form__form-group mb-10 mt-10">
            {this._renderCreativesList(resource.creatives)}
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default OfferCreativesPart
