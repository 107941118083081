import axios from 'axios'
import store from '../containers/App/store'
import {signOutAction} from "../redux/actions/usersActions";
import {UNAUTHORIZED_USER_STATUS} from "../constants";

let apihost = '/api';

function makeUrl(uri, options = {}, host = apihost) {
    return `${host}/${uri}?api_key=${store.getState().users.currentUser.apikey}`
}

export const signin = credentials => {
    return axios.post(`${apihost}/auth`, credentials, {headers: {'Content-Type': 'multipart/form-data'}})
}

export const loadSettings = (params, callback) => {
    return axios.get('/settings')
        .then((items) => {
            callback(items.data)
            if (items.data.gtm_id) {
                const script = document.createElement('script')
                script.async = true
                script.src = 'https://www.googletagmanager.com/gtm.js?id=' + items.data.gtm_id
                document.getElementsByTagName("head")[0].prepend(script)
            }
        })
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const uniqueEmail = (email) => {
    return axios.get(`/email?login=${email}`)
}

export const loadCountries = data => {
    return axios({
        method: 'GET',
        url: '/countries',
    })
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const signup = credentials => {
    let params = {}
    let url = `${apihost}/publishers`
    if (credentials.invite) {
        params.invite = credentials.invite
        url = `${apihost}/publishers?invite=${params.invite}`
        delete credentials.invite
    }
    return axios.post(url, JSON.stringify(credentials), {headers: {'Content-Type': 'multipart/form-data'}})
}

export const resetPassword = credentials => {
    return axios.post(`${apihost}/reset_password`, credentials, {headers: {'Content-Type': 'multipart/form-data'}})
}

export const loadPublishersPostbacks = (data, callback) => {
    return axios.post(makeUrl('postbacks'), JSON.stringify(data), {headers: {'Content-Type': 'multipart/form-data'}})
        .then((items) => {
            callback(items.data, items.data.total);
            return items.data;
        })
        .catch(error => {
            catchErrorToSignOut(error);
            return Promise.reject(error);
        })
}

export const updateUser = data => {
    return axios.put(makeUrl('profile'), data)
        .then((response) => response)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const getUserData = data => {
    return axios.get(makeUrl('me'), data)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const getUnregisteredUserData = data => {
    return axios.get(`${apihost}/me?api_key=${data}`)
}

export const loadCampaigns = (params, callback) => {
    return axios.get(makeUrl('campaigns'), {params: params})
        .then((items) => {
            callback(items.data);
            return items.data
        })
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const loadReport = (params = {}, callback) => {
    return axios.get(makeUrl('reports/traffic'), {params: params})
        .then((resource) => resource)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
};

export const loadConversionReport = (params = {}, callback) => {
    return axios.get(makeUrl('reports/conversions'), {params: params})
        .then((items) => {
            callback(items.data)
        })
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
};

export const loadTrafficReport = (params = {}, callback) => {
    return axios.get(makeUrl('reports/traffic'), {params: params})
        .then((items) => {
            callback(items.data)
        })
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
};

export const requestPayment = data => {
    return axios.post(makeUrl('payments'), JSON.stringify(data), {headers: {'Content-Type': 'multipart/form-data'}})
        .then((resource) => resource)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const updateRequestedAmount = data => {
    return axios.put(makeUrl(`payments/${data.id}`), data)
        .then((resource) => resource)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const loadPayments = (params, callback) => {
    return axios.get(makeUrl('payments'), {params: params})
        .then((resource) => resource)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const loadReferrals = (params, callback) => {
    return axios.get(makeUrl('referrals'), {params: params})
        .then((resource) => resource)
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const loadStat = (params, callback) => {
    return axios.get(makeUrl('stat'), {params: params})
        .then((items) => {
            callback(items.data)
        })
        .catch(error => {
            catchErrorToSignOut(error)
            return Promise.reject(error)
        })
}

export const catchErrorToSignOut = (error) => {
    if (error.response && error.response.status === UNAUTHORIZED_USER_STATUS) {
        store.dispatch(signOutAction)
    }
}

export const signout = () => {
    console.log('sign out')
}
