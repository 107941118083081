import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames';
import store from "../../../containers/App/store";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { loadPublishersPostbacksAction } from '../../../redux/actions/postbacksLogsActions'
import PostbacksLogsTable from "./PostbacksLogsTable";

class PostbacksLogs extends Component {
  constructor(props) {
    super(props);
    const users = store.getState().users || {};
    this.state = {
      usersSettings: users.settings || {},
    };
    this.onPaginationLoad = this.onPaginationLoad.bind(this);
  }

  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
  }

  componentDidMount() {
    const users = store.getState().users || {};
    if (users.settings && !users.settings.show_postbacks_logs) {
     this.props.history.push('/dashboard')
    }
    store.dispatch(loadPublishersPostbacksAction)
  }

  onPaginationLoad() {
    store.dispatch(loadPublishersPostbacksAction);
  };

  render () {

    return (
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <PostbacksLogsTable
                  onPaginationLoad={this.onPaginationLoad}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }

}

export default (PostbacksLogs);
