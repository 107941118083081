import React, { Fragment } from 'react'
import numeral from "numeral"
import { CardBody } from 'reactstrap';

class ReferralsCount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

  render() {
    const { user, paymentDetails } = this.props;
    // const UserCount = paymentDetails.referrals_count || 0
    const UserCount = user.referral_count || 0

    return (
      <CardBody
        style={{
          marginBottom: 0,
          padding: '15px 0 15px 0',
        }}
      >
          <div
            className="card__title"
            style={{
              height: '30px',
              marginBottom: 0,
              borderBottom: '1px solid transparent',
              borderBottomColor: '#dddddd',
            }}
          >
            <h5
              className="bold-text"
              style={{
                textTransform: 'none',
                textAlign: 'center',
              }}
            >
              Number of referrals
            </h5>
          </div>
        <div className="panel-body">
          <h1
            className="no-margin text-semibold text-center"
            style={{
              paddingTop: 10,
            }}
          >
            {numeral(UserCount).format('0')}
          </h1>
        </div>
      </CardBody>
    )
  }
}

ReferralsCount.defaultProps = {
  history: [],
  paymentDetails: {},
  user: {},
}

export default (ReferralsCount)
