import { capitalize } from '../utils/Helpers';

export const CSVFields = (group, csvFields) => ([
  ...group.map((key) => ({
    label: capitalize(key),
    value: key,
  })),
  ...csvFields,
]);

/**
 * @param columnsData {array}
 * @param titleKey {string}
 * @param idKey {string}
 * @returns [*]
 */
export const getCSVFieldsColumnsList = (columnsData, titleKey, idKey) => {
  const csvFields = [];
  columnsData.forEach((column) => {
    const templateField = {
      label: column[titleKey] ||'',
      value: column[idKey] || '',
    };
    if (templateField.label && templateField.value) {
      csvFields.push(templateField);
    }
  });
  return csvFields;
}

/**
 * @param columnsData {array}
 * @param usersSettings {object}
 * @param titleKey {string}
 * @param idKey {string}
 * @returns [*]
 */
export const getCSVFields = (columnsData, usersSettings, titleKey, idKey) => {
  const columnsOrder = usersSettings && usersSettings.settings ? usersSettings.settings : [];
  const csvFields = [];
  columnsOrder.forEach((column) => {
    if (column.enabled) {
      const findColumn = columnsData.find((el) => el[idKey] === column.id);
      const templateField = {
        label: findColumn ? findColumn[titleKey] : '',
        value: findColumn ? findColumn[idKey] : '',
      };
      if (templateField.label && templateField.value) {
        csvFields.push(templateField);
      }
    }
  });
  return csvFields;
}

export const downloadCSV = (opts={}) => {
  const exportFilename = opts.filename || 'data.csv'
  const csvData = new Blob([opts.data], { type: 'text/csv;charset=utf-8;' })
  //IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, exportFilename)
  } else {
    //In FF link must be added to DOM to be clicked
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(csvData)
    link.setAttribute('download', exportFilename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
