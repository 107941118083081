/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Button, ButtonToolbar, ButtonGroup, Col } from 'reactstrap';
import Select from 'react-select';
import Switch from "react-switch";

import store from '../../../containers/App/store';
import { filterAction, loadReportAction } from '../../../redux/actions/reportActions';
import renderIntervalDatePickerField from '../../../common/DatePicker';
import FancyGroupingPanel from '../../../common/FancyGroupingPanel';
import ReportExportButton from '../../General/Table/navbar/export/ReportExportButton';
import { capitalize } from '../../../utils/Helpers'

import { MAX_GROUPING_VALUES } from '../../../constants';
import { BASE_FANCY_GROUP_OPTIONS, BASE_GROUPING_OPTIONS } from '../../../constants/filters';

class GlobalReportFilters extends Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: store.getState().reports.global_report_filters,
      scope: 'global_report',
      baseOptions: BASE_GROUPING_OPTIONS.map(opt =>
        ({ label: capitalize(opt), value: opt, isDisabled: false })
      ),
    };
    this.loadDataForExport = this.loadDataForExport.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.applyGrouping = this.applyGrouping.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleSubmit() {
    const { campaign_ids } = this.props;
    const { filters, scope } = this.state;
    store.dispatch(loadReportAction(scope, {  ...filters, campaign_ids: campaign_ids }))
  };

  loadDataForExport(filters = this.state.filters) {
    const { campaign_ids } = this.props;
    const { scope } = this.state;
    return store.dispatch(loadReportAction(scope, {  ...filters, campaign_ids: campaign_ids }));
  };

  applyGrouping(option) {
    const { filters, baseOptions, scope } = this.state;
    let newBaseOptions = baseOptions.map(el => ({ ...el, isDisabled: false }));
    const filtersData = {
      ...filters,
      group: [option],
    };
    store.dispatch(filterAction(scope, filtersData));
    this.setState({
      filters:
        { ...filtersData },
      baseOptions: [...newBaseOptions],
    }, () => this.handleSubmit())
  };

  handleChange(event) {
    const { filters, scope } = this.state;
    const filtersData = {
      ...filters,
      date_from: moment(event.start),
      date_to: moment(event.end),
    };
    store.dispatch(filterAction(scope, filtersData));
    this.setState({
      filters: {
        ...filtersData,
      }
    })
  };

  handleChangeSelect(event) {
    const { filters, baseOptions, scope } = this.state;
    let newBaseOptions = [...baseOptions],
      groupingOptions = [];
    if (event) {
      if (event.length <= MAX_GROUPING_VALUES) {
        groupingOptions = event.map((element, i) => element.value);
      }
      newBaseOptions = baseOptions.map(el => ({ ...el, isDisabled: event.length === MAX_GROUPING_VALUES }));
    }
    const filtersData = {
      ...filters,
      group: groupingOptions,
    };
    store.dispatch(filterAction(scope, filtersData));
    this.setState({
      filters: {
        ...filtersData,
      },
      baseOptions: newBaseOptions,
    });
  };

  render () {
    const { filters, baseOptions } = this.state;
    const {
      exportSettings,
      globalReportFilters,
      isChartControlPresent,
      showChart,
      isChartVisible,
      scope,
    } = this.props;
    const multiSelectValue = [];
    filters.group.map((groupOption) => {
      baseOptions.map((option) => {
        if (option.value === groupOption) {
          multiSelectValue.push(option)
        }
      })
    });
    const label = isChartVisible ? `Hide charts for Report` : `Show charts for Report`;
    const exportSettingsProps = {
      ...exportSettings,
      loadReportData: this.loadDataForExport,
    };
    return (
      <>
        <form className="form form--horizontal">
          <ButtonGroup
            style={{
              marginBottom: 20,
            }}
          >
            <ReportExportButton { ...exportSettingsProps }/>
          </ButtonGroup>
        </form>
        <FancyGroupingPanel
          items={BASE_FANCY_GROUP_OPTIONS}
          active={filters.group[0]}
          onSelect={this.applyGrouping}
        />
        <form className="form form--horizontal" onSubmit={this.handleSubmit}>
          <Col md={4} lg={4} sm={4} className="no-padding-left">
            <div
              className="form__form-group datepicker_custom"
            >
              <span className="form__form-group-label">Period</span>
              <div className="form__form-group-field">
                <Field
                  name="global_report"
                  component={renderIntervalDatePickerField}
                  onChange={this.handleChange}
                  filters={filters}
                />
              </div>
            </div>
          </Col>

          <Col md={4} lg={4} sm={4}>
            <div
              className="form__form-group"
            >
              <span className="form__form-group-label">Group by</span>
              <div className="form__form-group-field">
                <Select
                  style={{
                    minWidth: '100%',
                  }}
                  isMulti
                  name='select'
                  value={multiSelectValue}
                  defaultValue={baseOptions.find(option => option.value === globalReportFilters.group[0])}
                  onChange={this.handleChangeSelect}
                  options={baseOptions}
                  clearable={false}
                  closeOnSelect={false}
                  removeSelected={false}
                  className="react-select"
                  placeholder='Choose grouping'
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </Col>

          {isChartControlPresent && <Col md={2} lg={2} sm={2}>
            <div className="form__form-group custom-label-switch">
              <span className="form__form-group-label">{label}</span>
              <div className="form__form-group-field">
                <Switch
                  onChange={showChart}
                  checked={isChartVisible}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={38}
                  onColor="#64bd63"
                  offColor="#cecece"
                  className="react-switch"
                />
              </div>
            </div>
          </Col>}
          <ButtonToolbar
            className="form__button-toolbar"
            style={{
              marginBottom: 0,
              marginTop: '5px',
              marginLeft: 0,
            }}
          >
            <Button
              color="primary"
              onClick={this.handleSubmit}
              style={{
                height: 42,
                lineHeight: '10px',
              }}
            >
              Apply
            </Button>
          </ButtonToolbar>
        </form>
      </>
    );
  }
}

GlobalReportFilters.defaultProps = {
  campaign_ids: '',
  isChartControlPresent: false,
  isChartVisible: false,
  scope: 'global_report',
  showChart: () => {},
}

GlobalReportFilters.propTypes = {
  exportSettings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
})

export default reduxForm({
  form: 'horizontal_form',
})(connect(mapStateToProps)(GlobalReportFilters))
