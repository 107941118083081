import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Postback from './components/Postback';

const PostbackPage = () => (
  <Container className="dashboard">
    <Row>
      <Postback />
    </Row>
  </Container>
);

export default PostbackPage;
