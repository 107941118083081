const REPORTS_OFFERS_OPTIONS = {
  SCOPE: 'offers',
  TITLE: 'Campaign report',
};
const DEFAULT_CHART_ITEM = {
  value: 'cost',
  label: 'Commission',
  yaxisProperties: {
    type: 'number',
    tick: { fill: '#000000' },
    allowDecimals: false,
  },
  lineChartProperties: {
    stroke: '#000000',
    dot: { fill: '#ffffff', strokeWidth: 1 },
    strokeWidth: 2,
  },
};
const REPORTS_CHART_ITEMS = [
  {
    value: 'impressions',
    label: 'Impressions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#f15854' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#f15854',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'clicks',
    label: 'Clicks',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#5da5da' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#5da5da',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'conversions',
    label: 'Conversions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#82ca9d' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#82ca9d',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'total_conversions',
    label: 'Total Actions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ca2900' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ca2900',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'transactions',
    label: 'Transactions',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#f19500' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#f19500',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'epc',
    label: 'epc',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#8a391c' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#8a391c',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'cost',
    label: 'Commission',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#000000' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#000000',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenue',
    label: 'Revenue',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#8884d8' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#8884d8',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype1',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#e5e500' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ffff00',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype2',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ffc800' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ffc800',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype3',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff9b00' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff9b00',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype4',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff6200' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff6200',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype5',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff2f00' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff2f00',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype6',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff1d00' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff1d00',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype7',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ca002b' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ca002b',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype8',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#c70027' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#c70027',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype9',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff004e' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff004e',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'convtype10',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ce004a' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ce004a',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype1',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff00b4' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff00b4',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype2',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff00f9' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff00f9',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype3',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff00ff' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff00ff',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype4',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#d500ff' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#d500ff',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype5',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#a700ff' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#a700ff',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype6',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#9400ff' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#9400ff',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype7',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#5300ff' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#5300ff',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype8',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#0007cf' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#0007cf',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype9',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#f265f2' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#f265f2',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'revenuetype10',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#ff97fa' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#ff97fa',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'total_revenue',
    label: 'Total Revenue',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#0042d8' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#0042d8',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'cr',
    label: 'Conversion rate',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#91f100' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#91f100',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
  {
    value: 'roi',
    label: 'ROI',
    yaxisProperties: {
      type: 'number',
      tick: { fill: '#0100a8' },
      allowDecimals: false,
    },
    lineChartProperties: {
      stroke: '#0100a8',
      dot: { fill: '#ffffff', strokeWidth: 1 },
      strokeWidth: 2,
    },
  },
];


/**
 * @param convTypeCount {number}
 * @returns {array}
 */
const renderConvRateTypeExport = (convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      value: `convratetype${count}`,
      calculateValue: (value) => ((value[`convtype${count}`] && value.clicks !== 0) ? value[`convtype${count}`] / value.clicks : 0),
    }
  ))
);

/**
 * @param convTypeCount {number}
 * @returns {array}
 */
export const renderConvTypeRoiExport = (convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      value: `convroitype${count}`,
      calculateValue: (value) => ((value[`revenuetype${count}`] && value.cost !== 0) ? value[`revenuetype${count}`] / value.cost : 0),
    }
  ))
);

/**
 * @param convTypeCount {number}
 * @returns {array}
 */
const renderConvTypeAovExport = (convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      value: `convaovtype${count}`,
      calculateValue: (value) => ((value[`revenuetype${count}`] && value[`convtype${count}`] !== 0) ? value[`revenuetype${count}`] / value[`convtype${count}`] : 0),
    }
  ))
);

/**
 * @param convTypeCount {number}
 * @returns {array}
 */
const renderConvTypeCpaExport = (convTypeCount = 20) => (
  Array.from({ length: convTypeCount }, (el, i) => i + 1)
  .map((count) => (
    {
      value: `convcpatype${count}`,
      calculateValue: (value) => ((value.cost && value[`convtype${count}`] !== 0) ? value.cost / value[`convtype${count}`] : 0),
    }
  ))
);

const NOT_PRESENT_VALUES_FROM_REPORT = [
  ...renderConvRateTypeExport(),
  ...renderConvTypeRoiExport(),
  ...renderConvTypeAovExport(),
  ...renderConvTypeCpaExport(),
  {
    value: 'total_conversions_cr',
    calculateValue: (value) => ((value.total_conversions && value.clicks !== 0) ? value.total_conversions / value.clicks : 0),
  },
  {
    value: 'aov',
    calculateValue: (value) => ((value.revenue && value.conversions !== 0) ? value.revenue / value.conversions : 0),
  },
  {
    value: 'epuc',
    calculateValue: (value) => (value.unique_clicks ? value.total_revenue / value.unique_clicks : 0),
  },
  {
    value: 'cpa',
    calculateValue: (value) => ((value.cost && value.conversions !== 0) ? value.cost / value.conversions : 0),
  },
  {
    value: 'roas',
    calculateValue: (value) => ((value.total_revenue && value.cost !== 0) ? value.total_revenue / value.cost : 0),
  },
  {
    value: 'epc_lp',
    calculateValue: (value) => ((value.total_revenue && value.lp_clicks !== 0) ? value.total_revenue / value.lp_clicks : 0),
  },
  {
    value: 'prelp_click_ctr',
    calculateValue: (value) => ((value.prelp_clicks && value.clicks !== 0) ? value.prelp_clicks / value.clicks : 0),
  },
  {
    value: 'lp_click_ctr',
    calculateValue: (value) => ((value.lp_clicks && value.clicks !== 0) ? value.lp_clicks / value.clicks : 0),
  },
  {
    value: 'approved_rate',
    calculateValue: (value) => ((value.approved && value.conversions !== 0) ? value.approved / value.conversions : 0),
  },
  {
    value: 'pending_rate',
    calculateValue: (value) => ((value.pending && value.conversions !== 0) ? value.pending / value.conversions : 0),
  },
  {
    value: 'declined_rate',
    calculateValue: (value) => ((value.declined && value.conversions !== 0) ? value.declined / value.conversions : 0),
  },
  {
    value: 'other_rate',
    calculateValue: (value) => ((value.other && value.conversions !== 0) ? value.other / value.conversions : 0),
  },
];

export {
  DEFAULT_CHART_ITEM,
  REPORTS_OFFERS_OPTIONS,
  REPORTS_CHART_ITEMS,
  NOT_PRESENT_VALUES_FROM_REPORT,
}
