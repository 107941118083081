/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { change, Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar, Col, UncontrolledTooltip } from 'reactstrap';
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon';
import { connect } from 'react-redux';
import { requestPaymentAction } from "../../../redux/actions/paymentsActions";
import { getCurrencySign } from '../../../utils';
import noty from "../../../utils/noty";

const OfferChSettingsLinkFormatter = ({ value }) => {
  return <Link to={`/profile`}>(change settings)</Link>
}

class RequestPaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_type: props.user.payment_type || '',
      balance: 'Balance',
      payment_method: '',
      payment_amount: '',
    }
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleSubmitRequestPayment = this.handleSubmitRequestPayment.bind(this);
  }

  componentDidMount() {
    const { users, settings } = this.props;
    const user = users.currentUser || {};
    const usersSettings = settings || {};
    const currencySign = getCurrencySign(usersSettings);
    const balance = (user && users && usersSettings && usersSettings.show_payouts)
      ? `${parseFloat(user.balance.toFixed(2)) + parseFloat(user.referral_balance.toFixed(2))}${currencySign}` : 'Balance'
    let preferredPaymentOptions = undefined;
    let payment_method = '';
    if (usersSettings.payment_request_settings && usersSettings.payment_request_settings.payment_types) {
      preferredPaymentOptions = usersSettings.payment_request_settings.payment_types.map(element => {
        if (element.is_enabled) {
          let label = '';
          if (element.name === 'wire') {
            label = `Wire transfer (min ${element.amount}${currencySign})`;
          } else if (element.name === 'paypal') {
            label = `PayPal (min ${element.amount}${currencySign})`;
          } else if (element.name === 'paxum') {
            label = `Paxum (min ${element.amount}${currencySign})`;
          }
          return { value: element.name, amount: element.amount, label: label };
        }
      })
      preferredPaymentOptions = preferredPaymentOptions.filter(el => el);
      const foundPaymentType = preferredPaymentOptions.find(el => el.value === user.payment_type);
      if (foundPaymentType) { foundPaymentType.isDisabled = true }
      payment_method = foundPaymentType ? foundPaymentType : '';
    }
    this.setState({ balance: balance, payment_method }, () => {
      this.props.dispatch(change('payments_form', 'available_balance', this.state.balance));
      this.props.dispatch(change('payments_form', 'payment_method', this.state.payment_method ? this.state.payment_method.label : ''));
      this.props.dispatch(change('payments_form', 'payment_amount', this.state.payment_amount));
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { user, users, settings } = props;
    const currencySign = getCurrencySign(settings);
    const balance = (user && users && settings && settings.show_payouts)
      ? `${parseFloat(user.balance.toFixed(2)) + parseFloat(user.referral_balance.toFixed(2))}${currencySign}` : 'Balance';
    props.dispatch(change('payments_form', 'available_balance', balance));
    return {
      balance: balance,
    }
  }

  handleChangeAmount(event) {
    const { value, name} = event.target;
    const currentValue = parseFloat(value || 0);
    this.setState({ [name]: currentValue });
  }

  handleSubmitRequestPayment(event) {
    event.preventDefault();
    const { payment_amount } = this.state;
    const { _requestPaymentAction } = this.props;
    _requestPaymentAction({ amount: payment_amount })
    .then(() => {
      noty({ text: 'Invoice generated successfully', type: 'success' });
      noty({ text: 'Payment request send successfully', type: 'success' });
    })
    .catch((error) => error);
  }

  render() {
    const {
      user,
    } = this.props;
    const {
      payment_method,
      payment_amount,
    } = this.state;
    let isRequestPayment = true;
    const balance = parseFloat(user.balance.toFixed(2)) + parseFloat(user.referral_balance.toFixed(2));
    const paymentMethodAmount = (payment_method && payment_method.amount) ? payment_method.amount : 0;
    if (payment_method && (balance >= payment_method.amount)) {
      isRequestPayment = payment_amount < payment_method.amount || payment_amount > balance;
    }
    let error = ''
    if (parseFloat(payment_amount) && parseFloat(payment_amount) > balance) {
      error = <span style={{ position: 'absolute', top: '37px' }} className="form__form-group-error">Payment amount must be less than available balance</span>;
    }
    if (parseFloat(payment_amount) && (parseFloat(payment_amount) < paymentMethodAmount)) {
      error = <span style={{ position: 'absolute', top: '37px' }} className="form__form-group-error">Payment amount must be more than payment method amount</span>;
    }

    return (
      <>
        <div className="card__title">
          <h5 className="bold-text">Request payment</h5>
        </div>
        <div
          className="form form--horizontal"
          style={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Col md={3} lg={3} sm={3} className="no-padding-left">
            <div
              className="form__form-group"
            >
              <span className="form__form-group-label">Available balance:</span>
              <div className="form__form-group-field">
                <Field
                  name="available_balance"
                  component="input"
                  type="text"
                  value={this.state.balance}
                  placeholder={'Balance'}
                  disabled
                />
              </div>
            </div>
          </Col>

          <Col md={3} lg={3} sm={3} className="">
            <div
              className="form__form-group"
            >
              <span className="form__form-group-label">Payment method:</span>
              <div className="form__form-group-field">
                <Field
                  name="payment_method"
                  component="input"
                  type="text"
                  value={payment_method ? payment_method.label : ''}
                  placeholder={'Payment method'}
                  disabled
                />
                {!payment_method && !payment_method.label && <div style={{ lineHeight: '32px', marginLeft: '10px' }}>
                  <AlertOutlineIcon id='alert_outline_icon' style={{ color: '#efc038' }} />
                  <UncontrolledTooltip placement="top" target='alert_outline_icon'>
                    Please add available payment method in profile settings
                  </UncontrolledTooltip>
                </div>}
              </div>
            </div>
          </Col>
          <Col md={3} lg={3} sm={3} className="">
            <div
              className="form__form-group"
            >
              <span
                className="form__form-group-label"
                style={{
                  marginTop: '7px',
                }}
              >
              {OfferChSettingsLinkFormatter({ value: '' })}
              </span>
            </div>
          </Col>
        </div>
        <form className="form form--horizontal" onSubmit={this.handleSubmit}>
          <Col md={3} lg={3} sm={3} className="no-padding-left">
            <div
              className="form__form-group"
            >
              <span className="form__form-group-label">Payment amount:</span>
              <div className="form__form-group-field">
                <Field
                  name="payment_amount"
                  component="input"
                  type="number"
                  min={(payment_method && payment_method.amount) ? payment_method.amount : 0}
                  max={balance}
                  value={payment_amount}
                  onChange={this.handleChangeAmount}
                  placeholder={'Payment amount'}
                  disabled={false}
                />
                {error}
              </div>
            </div>
          </Col>
          <Col md={3} lg={3} sm={3} className="">
            <div
              className="form__form-group"
            >
              <ButtonToolbar
                className="form__button-toolbar"
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  marginLeft: 0,
                }}
              >
                <Button
                  color="primary"
                  onClick={isRequestPayment ? () => {} : this.handleSubmitRequestPayment}
                  style={{
                    height: 32,
                    lineHeight: '10px',
                  }}
                  disabled={isRequestPayment}
                >
                  Request payment
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </form>
      </>
    );
  }
}

RequestPaymentContainer.defaultProps = {
  _loadSettingsAction: () => null,
};

const mapDispatchToProps = (dispatch) => ({
  _requestPaymentAction: (data) => dispatch(requestPaymentAction(data)),
});

const mapStateToProps = (state) => ({
  user: state.users.currentUser || {},
  users: state.users || {},
  settings:  state.users.settings,
})

export default reduxForm({
  form: 'payments_form',
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestPaymentContainer));
