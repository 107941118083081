import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import {connect} from "react-redux";
import classnames from 'classnames';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'

import store from "../../App/store";
import { getCurrencySign } from '../../../utils';

class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, settings, user } = this.props;
    const users = store.getState().users || {};
    const usersSettings = users.settings || {};
    const hideCampaigns = users.settings && users.settings.domain && users.settings.domain === 'login.wemonetize.click' ? true : false
    const currencySign = getCurrencySign(usersSettings);
    const currentPath = this.props.location.pathname
    const activeStyles = {
      trafficDashboard: currentPath === '/dashboard' ? 'active-link' : '',
      offers: currentPath === '/offers' ? 'active-link' : '',
      report: currentPath === '/report' ? 'active-link' : '',
      payments: currentPath === '/payments' ? 'active-link' : '',
      postback: currentPath === '/postback' ? 'active-link' : '',
      postbacks_logs: currentPath === '/postbacks_logs' ? 'active-link' : '',
    }
    let manager_email = user && user && user.manager_email ? user.manager_email : ''
    if (!manager_email) manager_email = settings && settings.manager_email ? settings.manager_email : ''
    let preferredPaymentOptions = []
    let payment_type = ''
    if (usersSettings.payment_request_settings && usersSettings.payment_request_settings.payment_types) {
      preferredPaymentOptions = usersSettings.payment_request_settings.payment_types.map(element => {
        if (element.is_enabled) {
          let label = ''
          if (element.name === 'wire') {
            label = `Wire transfer (min ${element.amount}${currencySign})`
          } else if (element.name === 'paypal') {
            label = `PayPal (min ${element.amount}${currencySign})`
          } else if (element.name === 'paxum') {
            label = `Paxum (min ${element.amount}${currencySign})`
          }
          return { value: element.name, amount: element.amount, label: label }
        }
      })
      preferredPaymentOptions = preferredPaymentOptions.filter(el => el)
      const foundPaymentType = preferredPaymentOptions.find(el => el.value === (user.payment_type || ''))
      if (foundPaymentType) { foundPaymentType.isDisabled = true }
      payment_type = foundPaymentType ? foundPaymentType : ''
    }

    return (
      <div className="topbar topbar--navigation">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
            <Link className={this.props.settings && this.props.settings.logo_url ? "topbar__logo__empty" : "topbar__logo"} to="/dashboard" >
              {this.props.settings && this.props.settings.logo_url ?
                  <img src={this.props.settings.logo_url} className="account__rtk-logo-header " /> :
                  null
              }
            </Link>
            <Link className={classnames("topbar__nav-link-custom", activeStyles.trafficDashboard)} to="/dashboard">Dashboard</Link>
            {!hideCampaigns &&
              <Link className={classnames("topbar__nav-link-custom", activeStyles.offers)} to="/offers">Campaigns</Link>
            }
            {
              <Link className={classnames("topbar__nav-link-custom", activeStyles.report)} to="/report">Report</Link>
            }
            {users.settings && users.settings.show_payouts &&
              <Link className={classnames("topbar__nav-link-custom", activeStyles.payments)} to="/payments">Payments</Link>
            }
            {users.settings && users.settings.show_postbacks_logs &&
              <Link className={classnames("topbar__nav-link-custom", activeStyles.postbacks_logs)} to="/postbacks_logs">Conversion logs</Link>
            }
            <Link className={classnames("topbar__nav-link-custom", activeStyles.postback)} to="/postback">Postback</Link>
            {manager_email &&
            <div className="topbar__nav-link  balance_custom" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <span className="topbar__nav-link">{`Manager:`}</span>
              <span className="topbar__nav-link">{`${manager_email}`}</span>
            </div>
            }
          </div>
          {/*<TopbarNav />*/}
          <div className="topbar__right">
            {this.props.user && users && users.settings && users.settings.show_payouts &&
              <div className="topbar__nav-link  balance_custom" style={{ flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 0, paddingRight: 0 }}>
                <span className="topbar__nav-link">
                Balance: {this.props.user.balance.toFixed(2)}{currencySign}
                  {payment_type && (parseFloat(this.props.user.balance.toFixed(2)) >= parseFloat(payment_type.amount)) && <Fragment>
                    <Link to={`/payments`} style={{ marginLeft: 10, marginBottom: 3 }}>
                      <AlertCircleOutlineIcon id='id_go_profile' style={{ color: '#1cce1c' }} size={18} />
                      <UncontrolledTooltip placement="top" target='id_go_profile'>
                        Payout ready
                      </UncontrolledTooltip>
                    </Link>
                  </Fragment>}
                </span>
                <span className="topbar__nav-link">
                  Referral balance: {this.props.user.referral_balance.toFixed(2)}{currencySign}
                </span>
              </div>
            }
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.currentUser,
  settings:  state.users.settings
})

export default connect(
    mapStateToProps,
)(TopbarWithNavigation)
