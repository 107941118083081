import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table-v6'

import 'react-table-v6/react-table.css'

export default class CustomizableTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: this.props.columns,
    }
  }

  static getDerivedStateFromProps (props, state) {
    return {
      ...state,
      columns: props.columns,
    }
  }

  render() {

    const {
      innerRef,
      componentClass: Component,
      ...props
    } = this.props

    return (
      <div style={{ maxHeight: "100%", display: 'flex', width: '100%' }}>
        <Component
          {...props}
          ref={innerRef}
          columns={this.state.columns}
        />
      </div>
    )
  }
}

CustomizableTable.propTypes = {
  columns: PropTypes.array,
}

CustomizableTable.defaultProps = {
  columns: [],
  componentClass: ReactTable,
}
