import React, { Fragment, Component } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import classNames from "classnames";

import store from '../../App/store'
import { signUpAction, loadCountriesAction } from './../../../redux/actions/usersActions'
import validate from './validateAdditional'

import {
	SIGNUP_ADDINTIONAL_FIELDS_LIST,
	MESSENGER_TYPE,
	FIND_US_TYPE,
	FIND_US_FIELD,
	FIND_US_OTHER,
	REFERRAL_TYPE,
	REFERRAL_FIELD,
	PAYOUT_VOLUME_TYPE,
	VERTICALS_TYPE,
	GEOS_TYPE,
	AFFILIATE_TYPE,
	TRAFFIC_TYPE,
	TRAFFIC_FIELD,
	TRAFFIC_OTHER,
	PAYOUT_TYPE,
} from '../../../constants/signup'

const renderField = ({
						 input, placeholder, type, meta: { touched, error },
					 }) => (
	<div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
		<input {...input} placeholder={placeholder} type={type} />
		{touched && error && <span className="form__form-group-error">{error}</span>}
	</div>
);

renderField.propTypes = {
	input: PropTypes.shape().isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
};

renderField.defaultProps = {
	placeholder: '',
	meta: null,
	type: 'text',
};

class SignUpAdditionalForm extends Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			firstname: props.userInfo && props.userInfo.firstname ? props.userInfo.firstname : "",
			lastname: props.userInfo && props.userInfo.lastname ? props.userInfo.lastname : "",
			messenger_type: props.userInfo && props.userInfo.messenger_type ? props.userInfo.messenger_type : "",
			messenger: props.userInfo && props.userInfo.messenger ? props.userInfo.messenger : "",
			country: props.userInfo && props.userInfo.country ? props.userInfo.country : "",
			find_us: props.userInfo && props.userInfo.find_us ? props.userInfo.find_us : "",
			find_us_details: props.userInfo && props.userInfo.find_us_details ? props.userInfo.find_us_details : "",
			referral: props.userInfo && props.userInfo.referral ? props.userInfo.referral : "",
			referral_details: props.userInfo && props.userInfo.referral_details ? props.userInfo.referral_details : "",
			verticals: props.userInfo && props.userInfo.verticals ? props.userInfo.verticals : [],
			verticals_details: props.userInfo && props.userInfo.verticals_details ? props.userInfo.verticals_details : "",
			geos: props.userInfo && props.userInfo.geos ? props.userInfo.geos : [],
			affiliate_types: props.userInfo && props.userInfo.affiliate_types ? props.userInfo.affiliate_types : [],
			traffic_types: props.userInfo && props.userInfo.traffic_types ? props.userInfo.traffic_types : [],
			traffic_types_details: props.userInfo && props.userInfo.traffic_types_details ? props.userInfo.traffic_types_details : "",
			payout_types: props.userInfo && props.userInfo.payout_types ? props.userInfo.payout_types : [],
			payout_volume: props.userInfo && props.userInfo.payout_volume ? props.userInfo.payout_volume : "",
			errorsFields: {},
			showThankYouText: false,
		};
	}

	componentDidMount() {
		const {
			firstname,
			lastname,
			messenger_type,
			messenger,
			country,
			find_us,
			find_us_details,
			referral,
			referral_details,
			verticals,
			verticals_details,
			geos,
			affiliate_types,
			traffic_types,
			traffic_types_details,
			payout_types,
			payout_volume,
		} = this.state
		store.dispatch(loadCountriesAction)
		this.props.dispatch(change('sign_up_additional_form', 'firstname', firstname));
		this.props.dispatch(change('sign_up_additional_form', 'lastname', lastname));
		this.props.dispatch(change('sign_up_additional_form', 'messenger_type', messenger_type));
		this.props.dispatch(change('sign_up_additional_form', 'messenger', messenger));
		this.props.dispatch(change('sign_up_additional_form', 'country', country));
		this.props.dispatch(change('sign_up_additional_form', 'find_us', find_us));
		this.props.dispatch(change('sign_up_additional_form', 'find_us_details', find_us_details));
		this.props.dispatch(change('sign_up_additional_form', 'referral', referral));
		this.props.dispatch(change('sign_up_additional_form', 'referral_details', referral_details));
		this.props.dispatch(change('sign_up_additional_form', 'verticals', verticals));
		this.props.dispatch(change('sign_up_additional_form', 'verticals_details', verticals_details));
		this.props.dispatch(change('sign_up_additional_form', 'geos', geos));
		this.props.dispatch(change('sign_up_additional_form', 'affiliate_types', affiliate_types));
		this.props.dispatch(change('sign_up_additional_form', 'traffic_types', traffic_types));
		this.props.dispatch(change('sign_up_additional_form', 'traffic_types_details', traffic_types_details));
		this.props.dispatch(change('sign_up_additional_form', 'payout_types', payout_types));
		this.props.dispatch(change('sign_up_additional_form', 'payout_volume', payout_volume));
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value.trim() })
	}

	handleChangeSelect = (key, event) => {
		this.setState({ [key]: event ? event.value : '' })
	}

	sendEvent = (obj) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(obj);
	}

	handleChangeMultiSelect = (key, event) => {
		let data = [];
		if (event) {
			data = event.map((element, i) => element.value);
		}
		this.setState({ [key]: data });
	};

	handleSubmit = (event) =>{
		event.preventDefault()
		const users = store.getState().users
		const usersSettings = users && users.settings ? users.settings : {}
		const { changeStepToPrevious } = this.props
		let stateData = JSON.parse(JSON.stringify(this.state))
		let errorsFields = validate(stateData)
		if (Object.keys(errorsFields).length === 0) {
			this.setState({ errorsFields })
			SIGNUP_ADDINTIONAL_FIELDS_LIST.map(field => {
				if (!stateData[field]) {
					if (field === REFERRAL_FIELD) {
						if (!stateData[field]) {
							delete stateData.referral_details
						}
						if (stateData[field] === "") {
							delete stateData[field]
						}
					} else {
						delete stateData[field]
					}
					if (field === REFERRAL_FIELD && !stateData[field]) {
						delete stateData.referral_details
					} else if (field === REFERRAL_FIELD && stateData[field] === false) {

					} else {

					}
				} else if (Array.isArray(stateData[field])) {
					if (stateData[field].length === 0) {
						delete stateData[field]
					} else {
						if (field === TRAFFIC_FIELD && !stateData[field].includes(TRAFFIC_OTHER)) {
							delete stateData.traffic_types_details
						}
					}
				} else if (field === FIND_US_FIELD && stateData[field] !== FIND_US_OTHER) {
					delete stateData.find_us_details
				}
			})
			stateData.email = localStorage.getItem('l')
			stateData.password = localStorage.getItem('p')
			let authData = new FormData()
			for ( var key in stateData ) {
				authData[key] = stateData[key]
			}
			if (this.props.invite) { authData.invite = this.props.invite }
			localStorage.removeItem('l')
			localStorage.removeItem('p')
			store.dispatch(signUpAction(authData))
			.then((user) => {
				this.sendEvent({ event: 'sing_up' })
				if (user.data && user.data.status === 1) {
					this.props.history.push('/dashboard')
				} else {
					let showThankYouText = false
					if (usersSettings.allow_signup && usersSettings.needs_approval) {
						showThankYouText = true
					}
					this.setState({
						errors: 'Account is unverified',
						showThankYouText,
					})
					setTimeout(function () {
						this.setState({
							errors: null
						})
						// this.props.history.push('/')
					}.bind(this), 10000)
				}
			})
			.catch((error) => {
				const { response } = error
				if (response && response.status === 400 && response.data) {
					let errorText = response.data.error;
					if (response.data.errors) {
						errorText = response.data.errors;
					}
					changeStepToPrevious(errorText || '', { ...stateData })
				}
			})
		} else {
			this.setState({ errorsFields })
		}
	}

	render() {
		const {
			firstname,
			lastname,
			messenger_type,
			messenger,
			country,
			find_us,
			find_us_details,
			referral,
			referral_details,
			verticals,
			verticals_details,
			geos,
			affiliate_types,
			traffic_types,
			traffic_types_details,
			payout_types,
			payout_volume,
			showThankYouText,
			errorsFields,
		} = this.state
		const settings = this.props.settings
		const currentMessengerType = MESSENGER_TYPE.find(el => el.value === messenger_type);
		let countryOptions = []
		if (this.props.countryOptions) {
			countryOptions = store.getState().users.countryOptions.map(country => ({ value: country.iso, label: country.title }))
		}
		const currentCountry = countryOptions.find(el => el.value === country);
		const currentFindUs = FIND_US_TYPE.find(el => el.value === find_us);
		const currentReferral = REFERRAL_TYPE.find(el => el.value === referral);
		const multiSelectVerticals = [];
		verticals.map((verticalsOption) => {
			VERTICALS_TYPE.map((option) => {
				if (option.value === verticalsOption) {
					multiSelectVerticals.push(option)
				}
			})
		});
		const multiSelectGeos = [];
		geos.map((geosOption) => {
			GEOS_TYPE.map((option) => {
				if (option.value === geosOption) {
					multiSelectGeos.push(option)
				}
			})
		});
		const multiSelectAffiliate = [];
		affiliate_types.map((affiliateTypesOption) => {
			AFFILIATE_TYPE.map((option) => {
				if (option.value === affiliateTypesOption) {
					multiSelectAffiliate.push(option)
				}
			})
		});
		const multiSelectTraffic = [];
		traffic_types.map((trafficTypesOption) => {
			TRAFFIC_TYPE.map((option) => {
				if (option.value === trafficTypesOption) {
					multiSelectTraffic.push(option)
				}
			})
		});
		const multiSelectPayout = [];
		payout_types.map((payoutTypesOption) => {
			PAYOUT_TYPE.map((option) => {
				if (option.value === payoutTypesOption) {
					multiSelectPayout.push(option)
				}
			})
		});
		const currentPayoutVolume = PAYOUT_VOLUME_TYPE.find(el => el.value === payout_volume);

		return (
			<Fragment>
				{showThankYouText ?
				<div className='text-center'>
					<h4>
						Thank you for signing up for our affiliate program. We will review your application. One of our account managers will contact you shortly.
					</h4>
				</div> :
					<form className="form" onSubmit={this.handleSubmit}>
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_first_name && <div className="form__form-group">
							<span className="form__form-group-label">First Name</span>
							<div className="form__form-group-field">
								<Field
									name="firstname"
									component={renderField}
									type={'text'}
									value={firstname}
									placeholder="First name"
									onChange={this.handleChange.bind(this)}
								/>
							</div>
						</div>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_last_name && <div className="form__form-group">
							<span className="form__form-group-label">Last name</span>
							<div className="form__form-group-field">
								<Field
									name="lastname"
									component={renderField}
									type={'text'}
									value={lastname}
									placeholder="Last name"
									onChange={this.handleChange.bind(this)}
								/>
							</div>
						</div>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_messenger_type && <div className="form__form-group">
							<span className="form__form-group-label">Messenger type</span>
							<div className="form__form-group-field">
								<div className="form__form-group-input-wrap">
									<Select
										name="messenger_type"
										placeholder={'Messenger type'}
										options={MESSENGER_TYPE}
										value={currentMessengerType}
										className={classNames('react-select__input')}
										onChange={this.handleChangeSelect.bind(this, 'messenger_type')}
										classNamePrefix="react-select__input"
									/>
								</div>
							</div>
						</div>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_messenger && <div className="form__form-group">
							<span className="form__form-group-label">Messenger</span>
							<div className="form__form-group-field">
								<Field
									name="messenger"
									component={renderField}
									type={'text'}
									value={messenger}
									placeholder="Skype/Telegram/Etc."
									onChange={this.handleChange.bind(this)}
								/>
							</div>
						</div>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_country && <div className="form__form-group">
							<span className="form__form-group-label">Country</span>
							<div className="form__form-group-input-wrap">
								<Select
									name="country"
									placeholder={'Select country'}
									options={countryOptions}
									value={currentCountry}
									className={classNames('react-select__input')}
									onChange={this.handleChangeSelect.bind(this, 'country')}
									classNamePrefix="react-select__input"
								/>
							</div>
						</div>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_find_us && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">How did you hear about us?</span>
								<div className="form__form-group-input-wrap">
									<Select
										name="find_us"
										placeholder={'How did you hear about us?'}
										options={FIND_US_TYPE}
										value={currentFindUs}
										className={classNames('react-select__input')}
										onChange={this.handleChangeSelect.bind(this, 'find_us')}
										classNamePrefix="react-select__input"
									/>
								</div>
							</div>
							{find_us === FIND_US_OTHER && <div className="form__form-group">
								<span className="form__form-group-label">Other</span>
								<div className="form__form-group-field" style={{ flexDirection: 'column' }}>
									<Field
										name="find_us_details"
										component="textarea"
										style={{
											resize: 'none',
											minHeight: '64px',
											fontSIze: '13px',
										}}
										value={find_us_details}
										disabled={false }
										onChange={this.handleChange.bind(this)}
									/>
									{!!errorsFields.find_us_details && <span className="form__form-group-error">{errorsFields.find_us_details}</span>}
								</div>
							</div>}
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_referral && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">Were you referred by our AM or another publisher?</span>
								<div className="form__form-group-input-wrap">
									<Select
										name="referral"
										placeholder={'Were you referred by our AM or another publisher?'}
										options={REFERRAL_TYPE}
										value={currentReferral}
										className={classNames('react-select__input')}
										onChange={this.handleChangeSelect.bind(this, 'referral')}
										classNamePrefix="react-select__input"
									/>
								</div>
							</div>
							{referral && <div className="form__form-group">
								<span className="form__form-group-label">Details</span>
								<div className="form__form-group-field" style={{ flexDirection: 'column' }}>
									<Field
										name="referral_details"
										component="textarea"
										style={{
											resize: 'none',
											minHeight: '64px',
											fontSIze: '13px',
										}}
										value={referral_details}
										disabled={false }
										onChange={this.handleChange.bind(this)}
									/>
									{!!errorsFields.referral_details && <span className="form__form-group-error">{errorsFields.referral_details}</span>}
								</div>
							</div>}
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_verticals && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">Your main verticals</span>
								<div className="form__form-group-input-wrap">
									<Select
										style={{
											minWidth: '100%',
										}}
										isMulti
										name='select'
										value={multiSelectVerticals}
										onChange={this.handleChangeMultiSelect.bind(this, 'verticals')}
										options={VERTICALS_TYPE}
										clearable={false}
										closeOnSelect={false}
										removeSelected={false}
										className="react-select"
										placeholder='Your main verticals'
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_verticals_details && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">Please tell us more about offers you work with</span>
								<div className="form__form-group-input-wrap">
									<Field
										name="verticals_details"
										component="textarea"
										style={{
											resize: 'none',
											fontSIze: '13px',
										}}
										placeholder={'Please tell us more about offers you work with'}
										value={verticals_details}
										disabled={false }
										onChange={this.handleChange.bind(this)}
									/>
								</div>
							</div>
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_geos && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">Your top GEOs</span>
								<div className="form__form-group-input-wrap">
									<Select
										style={{
											minWidth: '100%',
										}}
										isMulti
										name='select'
										value={multiSelectGeos}
										onChange={this.handleChangeMultiSelect.bind(this, 'geos')}
										options={GEOS_TYPE}
										clearable={false}
										closeOnSelect={false}
										removeSelected={false}
										className="react-select"
										placeholder='Your top GEOs'
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_affiliate_type && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">What is your specialization?</span>
								<div className="form__form-group-input-wrap">
									<Select
										style={{
											minWidth: '100%',
										}}
										isMulti
										name='select'
										value={multiSelectAffiliate}
										onChange={this.handleChangeMultiSelect.bind(this, 'affiliate_types')}
										options={AFFILIATE_TYPE}
										clearable={false}
										closeOnSelect={false}
										removeSelected={false}
										className="react-select"
										placeholder='What is your specialization?'
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_traffic_type && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">What is your primary traffic type?</span>
								<div className="form__form-group-input-wrap">
									<Select
										style={{
											minWidth: '100%',
										}}
										isMulti
										name='select'
										value={multiSelectTraffic}
										onChange={this.handleChangeMultiSelect.bind(this, 'traffic_types')}
										options={TRAFFIC_TYPE}
										clearable={false}
										closeOnSelect={false}
										removeSelected={false}
										className="react-select"
										placeholder='What is your primary traffic type?'
										classNamePrefix="react-select"
									/>
								</div>
							</div>
							{traffic_types.includes(TRAFFIC_OTHER) && <div className="form__form-group">
								<span className="form__form-group-label">Other</span>
								<div className="form__form-group-field" style={{ flexDirection: 'column' }}>
									<Field
										name="traffic_types_details"
										component="textarea"
										style={{
											resize: 'none',
											minHeight: '64px',
											fontSIze: '13px',
										}}
										value={traffic_types_details}
										disabled={false }
										onChange={this.handleChange.bind(this)}
									/>
									{!!errorsFields.traffic_types_details && <span className="form__form-group-error">{errorsFields.traffic_types_details}</span>}
								</div>
							</div>}
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_payout_type && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">What payout types do you work with?</span>
								<div className="form__form-group-input-wrap">
									<Select
										style={{
											minWidth: '100%',
										}}
										isMulti
										name='select'
										value={multiSelectPayout}
										onChange={this.handleChangeMultiSelect.bind(this, 'payout_types')}
										options={PAYOUT_TYPE}
										clearable={false}
										closeOnSelect={false}
										removeSelected={false}
										className="react-select"
										placeholder='What payout types do you work with?'
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</Fragment>}
						{settings && settings.sign_up_form_fields && settings.sign_up_form_fields.show_payout_volume && <Fragment>
							<div className="form__form-group">
								<span className="form__form-group-label">What is your monthly volume for your offers?</span>
								<div className="form__form-group-input-wrap">
									<Select
										name="payout_volume"
										placeholder={'What is your monthly volume for your offers?'}
										options={PAYOUT_VOLUME_TYPE}
										value={currentPayoutVolume}
										className={classNames('react-select__input')}
										onChange={this.handleChangeSelect.bind(this, 'payout_volume')}
										classNamePrefix="react-select__input"
									/>
								</div>
							</div>
						</Fragment>}
						<div className="form__form-group">
							<div className="form__form-group-field">
								{this.state.errors &&
								<span className="form__form-group-error">{this.state.errors}</span>
								}
							</div>
						</div>
						<button type="submit" className="btn btn-primary account__btn account__btn--small">Complete Sign Up</button>
						{this.props.settings.website && <div style={{textAlign: 'center', width: '100%'}}><a target={"_blank"} href={this.props.settings.website}>{this.props.settings.website}</a></div>}
					</form>
				}
			</Fragment>
		);
	}
}

SignUpAdditionalForm.defaultProps = {
	changeStepToPrevious: () => {},
	userInfo: {},
}

const mapStateToProps = (state) => ({
	countryOptions: state.users.countryOptions,
})

export default reduxForm({
	form: 'sign_up_additional_form',
	validate,
})(connect(
	mapStateToProps,
)(SignUpAdditionalForm));
