import {
  signin,
  signup,
  updateUser,
  signout,
  getUserData,
  loadSettings,
  loadCountries,
  getUnregisteredUserData,
  resetPassword,
  uniqueEmail,
} from '../../utils/apiAdapter'
import noty from '../../utils/noty'
import { updateSessionObject, removeSessionItem } from '../../utils/index'
import { SESSION_OBJECT_KEY } from '../../constants'
import { errorOccuredWithNotificationAction as errorOccuredAction } from './errors'

export const SIGN_IN = 'SIGN_IN';
export const SET_CURRENT_USER  = 'SET_CURRENT_USER';
export const USER_LOAD_ALL_COUNTRIES_ACTION  = 'USER_LOAD_ALL_COUNTRIES_ACTION';
export const SET_SETTINGS = 'SET_SETTINGS'
export const SIGN_OUT          = 'SIGN_OUT'
export const ERROR_OCCURED = 'ERROR_OCCURED'



export const signInAction = (credentials) => (
    (dispatch) => (
        signin(credentials)
        .then(user => {
            dispatch(setCurrentUserAction(user.data))
        })
    )
)

export const loadSettingsAction = (credentials) => (
    (dispatch) => {
      // const sessionObject = JSON.parse(sessionStorage.getItem(SESSION_OBJECT_KEY));
      // const expires = new Date();
      // if (sessionObject) {
      //   if (!window.onbeforeunload) {
      //     window.onbeforeunload = removeSessionItem
      //   }
      //   if (expires.getTime() > sessionObject.expiresAt) {
      //     updateSessionObject(sessionObject, expires)
      //     return loadSettings(credentials, (data) => dispatch(setSettingsAction(data)))
      //   }
      // } else {
      //   updateSessionObject(sessionObject, expires)
      //   return loadSettings(credentials, (data) => dispatch(setSettingsAction(data)))
      // }
      return loadSettings(credentials, (data) => dispatch(setSettingsAction(data)))
    }
)

export const signUpAction = (credentials) => (
	(dispatch) => (
		signup(credentials)
			.then(user => {

				dispatch(setCurrentUserAction(user.data))
				return user
			})
      .catch(error => {throw error})
	)
)

export const signUpUniqueEmailAction = (email) => uniqueEmail(email)

export const resetPasswordAction = email =>
    dispatch => (
        resetPassword(email).then(response => {
            if (!response.status == 204) {
                return response.json().then(err => {throw err})
            }
        })
        .then(() => noty({ text: 'Your password has been reset successfully', type: 'success' }))
    )

export const getUserDataAction = () => (
	(dispatch) => (
		getUserData()
			.then(user => {
				dispatch(setCurrentUserAction(user.data))
        return user.data
			})
	)
)

export const loadCountriesAction = (dispatch) => {
  return loadCountries()
    .then(countries => {
      dispatch(setCountriesAction(countries.data))
      return countries.data
    })
}

export const getUserDataForBecomeAction = data =>
	dispatch => (
		getUnregisteredUserData(data).then(response => {
			if (!response.status == 204) {
				return response.json().then(err => {throw err})
			}
			return response.data
		})

	)

export const updateUserAction = (credentials, opts={ notice: false }) => (
    (dispatch) => (
        updateUser(credentials)
            .then(user => {
                dispatch(setCurrentUserAction(user.data))
                if (opts.notice) dispatch(userUpdatedAction(user.data, { text: 'Profile updated successfully' }))
                return user
            })
            .catch(error => {
              const { response } = error
              if (response && response.data) {
                dispatch(errorOccuredAction(response.data))
              }
              throw error;
            })
    )
)

export const signOutAction = (dispatch) => {
    dispatch({type: SIGN_OUT});// TODO check if user is actually signed in
    window.location.href = '/'
}

export const setCurrentUserAction = (user) => {
    return { type: SET_CURRENT_USER, data: user }
}

export const setCountriesAction = (countries) => {
    return { type: USER_LOAD_ALL_COUNTRIES_ACTION, data: countries }
}

export const setSettingsAction = (settings) => {
    return { type: SET_SETTINGS, data: settings }
}

export const userUpdatedAction = (data, opts) => (
    (dispatch) => {
        noty({ text: opts.text, type: 'success' })
        return data
    }
)
