import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        {/*<ul className="sidebar__block">*/}
          {/*<SidebarLink title="Log In" icon="exit" route="/log_in" onClick={this.hideSidebar} />*/}
          {/*<SidebarCategory title="Layout" icon="layers">*/}
            {/*<button className="sidebar__link" onClick={this.props.changeToLight}>*/}
              {/*<p className="sidebar__link-title">Light Theme</p>*/}
            {/*</button>*/}
            {/*<button className="sidebar__link" onClick={this.props.changeToDark}>*/}
              {/*<p className="sidebar__link-title">Dark Theme</p>*/}
            {/*</button>*/}
          {/*</SidebarCategory>*/}
        {/*</ul>*/}
        <ul className="sidebar__block">
          <SidebarLink icon="chart-bars" title="Dashboard" route="/dashboard" onClick={this.hideSidebar} />
          <SidebarLink icon="briefcase" title="Offers" route="/pages/two" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
