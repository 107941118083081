import React, { Component } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { loadSettingsAction, signUpAction, signUpUniqueEmailAction } from './../../../redux/actions/usersActions'
import store from '../../App/store'
import PropTypes from 'prop-types';
import validate from './validate';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { hasWhiteSpace } from "../../../utils";

const renderField = ({
						 input, placeholder, type, meta: { touched, error },
					 }) => (
	<div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
		<input {...input} placeholder={placeholder} type={type} />
		{touched && error && <span className="form__form-group-error">{error}</span>}
	</div>
);

class SignUpForm extends Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			email: props.userInfo && props.userInfo.email ? props.userInfo.email : "",
			password: "",
			accepted: false,
			errors: null
		};
	}

	componentDidMount() {
		const { email } = this.state
		this.props.dispatch(change('sign_up_form', 'email', email));
		document.title = 'Sign up'
		store.dispatch(loadSettingsAction())
	}

	showPassword = (e) => {
		e.preventDefault();
		this.setState({
			showPassword: !this.state.showPassword,
		});
	}

	sendEvent = (obj) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(obj);
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value.trim() })
	}

	checkPass = () => {
		const { password } = this.state;
		let isValid = false;
		const passCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
		const whiteSpace = hasWhiteSpace(password);
		if (!whiteSpace && password.match(passCheck)) {
			isValid = true;
		}
		return isValid;
	}

	setErrorsGoNext = (isEmailTaken, isValidationDone) => {
		const { nextSignUpStep, setErrors } = this.props;
		let errors = [];
		if (!isValidationDone) {
			errors = [ ...errors, ...[
				'password cannot contain whitespace characters',
				'password must contain at least 1 digit',
				'password must contain at least 1 capital letter',
				'password must contain at least 1 lowercase letter',
			]]
		}
		if (isEmailTaken) {
			errors = [...[
				'email must be unique',
			], ...errors]
		}
		setErrors(errors);
		if (!isEmailTaken && isValidationDone) {
			nextSignUpStep();
		}
}

	handleSubmit = (event) =>{
		event.preventDefault()
		localStorage.setItem('l', this.state.email);
		localStorage.setItem('p', this.state.password);
		if (this.props.settings && this.props.settings.show_sign_up) {
			let isValidationDone = this.checkPass();
			let isEmailTaken = false;
			signUpUniqueEmailAction(this.state.email)
				.then(dataValidation => {
					if (dataValidation.status === 204) {
						isEmailTaken = true;
						isValidationDone = this.checkPass();
						this.setErrorsGoNext(isEmailTaken, isValidationDone);
					}
				})
				.catch(error => {
					if (error && error.response && (error.response.status === 404)) {
						isEmailTaken = false;
						isValidationDone = this.checkPass();
						this.setErrorsGoNext(isEmailTaken, isValidationDone);
					}
				});
		} else {
			const users = store.getState().users
			const usersSettings = users && users.settings ? users.settings : {}
			const { changeStepToPrevious } = this.props
			let stateData = this.state
			stateData.email = localStorage.getItem('l')
			stateData.password = localStorage.getItem('p')
			let authData = new FormData()
			for ( var key in stateData ) {
				authData[key] = stateData[key]
			}
			if (this.props.invite) { authData.invite = this.props.invite }
			localStorage.removeItem('l')
			localStorage.removeItem('p')
			store.dispatch(signUpAction(authData))
			.then((user) => {
				this.sendEvent({ event: 'sing_up' })
				if (user.data && user.data.status === 1) {
					this.props.history.push('/dashboard')
				} else {
					let showThankYouText = false
					if (usersSettings.allow_signup && usersSettings.needs_approval) {
						showThankYouText = true
					}
					this.setState({
						errors: 'Account is unverified',
						showThankYouText,
					})
					setTimeout(function () {
						this.setState({
							errors: null
						})
						// this.props.history.push('/')
					}.bind(this), 10000)
				}
			})
			.catch((error) => {
				const { response } = error
				if (response && response.status === 400 && response.data) {
					let errorText = response.data.error;
					if (response.data.errors) {
						errorText = response.data.errors;
					}
					changeStepToPrevious(errorText || '', { ...stateData })
				}
			})
		}
	}

	renderLabelTermsPrivacy = () => {
		const agreeText = 'I agree to the '
		let isTermsPresent = !!this.props.settings.terms_conditions_url
		let isPrivacyPresent = !!this.props.settings.privacy_policy_url
		const terms = <a href={this.props.settings.terms_conditions_url} target={"_blank"}>Terms and Conditions</a>
		let privacy = <a href={this.props.settings.privacy_policy_url} target={"_blank"}>Privacy Policy</a>
		const privacyDash = ` / `
		if (isPrivacyPresent && isTermsPresent) {
			return <label htmlFor={"checkbox_one"} style={{position: 'absolute', left: 30, top: 0}}>{agreeText}{terms}{privacyDash}{privacy}</label>
		} else {
			return <label htmlFor={"checkbox_one"} style={{position: 'absolute', left: 30, top: 0}}>{agreeText}{isTermsPresent ? terms : ''}{isPrivacyPresent ? privacy : ''}</label>
		}
	}

	render() {
		let { email, password, accepted } = this.state;
		const { userInfo } = this.props;
		let isTermsPrivacyPresent = true;
		let errors = this.state.errors,
			emailAlert = '',
			passwordAlert = '',
			isErrorsArray = false;
		if (this.props.errors) {
			errors = this.props.errors
		}
		if (userInfo) {
			emailAlert = userInfo.email ? 'emailSignUpDanger' : emailAlert
		}
		if (Array.isArray(errors) && errors.length) {
			isErrorsArray = Array.isArray(errors);
			const findEmailError = errors.find(el => el === 'email must be unique')
			emailAlert = findEmailError ? 'emailSignUpDanger' : '';
			passwordAlert = errors.length === 1 ? '' : 'passwordSignUpDanger';
		}
		const isTermsPresent = !this.props.settings.terms_conditions_url
		const isPrivacyPresent = !this.props.settings.privacy_policy_url
		if (isTermsPresent && isPrivacyPresent) {
			isTermsPrivacyPresent = !isTermsPrivacyPresent
		}
		return (
			<form className="form" onSubmit={this.handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">Login</span>
					<div className={`form__form-group-field ${emailAlert}`}>
						<div className="form__form-group-icon">
							<AccountOutlineIcon />
						</div>
						<Field
							name="email"
							component={renderField}
							value={email}
							type={'text'}
							placeholder="your@email.com"
							onChange={this.handleChange.bind(this)}
						/>
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Password</span>
					<div className={`form__form-group-field ${passwordAlert}`}>
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="password"
							component={renderField}
							type={'password'}
							placeholder="Password"
							onChange={this.handleChange.bind(this)}
						/>
					</div>
				</div>

				<div className="form__form-group">
					<div className="account__signin">
						<Link to="/signin">
							Sign In
						</Link>
					</div>
				</div>

				{this.props.settings && this.props.settings.terms_enabled && isTermsPrivacyPresent &&
					<div className="form__form-group" style={{position: 'relative'}}>
						<Field
							name="checkbox_one"
							component={renderCheckBoxField}
							color="#70bbfd"
							className="colored-click"
							onChange={(e) => {this.setState({accepted: e.target.checked})}}
						/>
						{this.renderLabelTermsPrivacy()}
					</div>
				}

				<div className="form__form-group">
					<div
						className="form__form-group-field"
						style={{ flexDirection: isErrorsArray ? 'column' : 'row' }}
					>
						{(errors && !isErrorsArray) ?
						<span className="form__form-group-error">{errors}</span> :
							<>
								{errors && errors.map((error) => (<span className="form__form-group-error">{error}</span>))}
							</>
						}
					</div>
				</div>
				{this.props.settings && this.props.settings.terms_enabled && isTermsPrivacyPresent ?
					<button disabled={email && password && accepted ? false : true} type="submit" className="btn btn-primary account__btn account__btn--small">Sign Up</button> :
					<button disabled={email && password ? false : true} type="submit" className="btn btn-primary account__btn account__btn--small">Sign Up</button>
				}
			</form>
		);
	}
}

SignUpForm.defaultProps = {
	nextSignUpStep: () => {},
	userInfo: {},
}

export default reduxForm({
	form: 'sign_up_form',
	validate,
})(SignUpForm);
