/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import numeral from 'numeral'

import store from "../../../containers/App/store";
import { LoadableContainer } from '../../../widgets'
import { CSVFields, getCSVFields } from "../../../widgets/export";
import ReportsCharts from "../reportsCharts/ReportsCharts";
import { capitalize, sum, sumHiddenColumn } from '../../../utils/Helpers';
import {
  getSavedOrderOfColumns,
  renderRevenueTypeTitle,
  renderConvTypeTitle,
  getCurrencySign,
} from '../../../utils';
import {
  renderConvType,
  renderConvRateType,
  renderRevenueType,
} from '../../../utils/columns/convTypes';
import GlobalReportFilters from './GlobalReportFilters';
import CustomizableTable from '../../../common/table/customizableTable';

import { NA_VALUE, DEFAULT_GROUPING_BY_DATE, GROUPING_BY_OFFER, CONVTYPE_COUNT } from '../../../constants';
import { DASHBOARD_EXCLUDE_METRICS, METRICS_DATA } from '../../../constants/dashboard';
import { NOT_PRESENT_VALUES_FROM_REPORT } from '../../../constants/reports';
import { REPORTS_CAMPAIGNS_OPTIONS } from '../../../constants/reports/globalReport';

class GlobalReportTable extends Component {
  constructor (props) {
    super(props);
    const users = store.getState().users || {};
    this.state = {
      usersSettings: users.settings || {},
      scope: props.scope,
      heads: [],
      headsWithOutGrouping: [],
      defaultHeads: [],
      defaultHeadColumn: {
        title: 'Date',
        Header: () => <span>Date</span>,
        accessor: 'date',
        id: 'date',
        minWidth: 170,
        width: 170,
        PivotValue: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
        value: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
        Footer: <span>Total:</span>,
        // Expander: () => null,
      },
      groupingHeadColumn: [],
      isChartVisible: true,
      isChartNeed: true,
      columnsRenderer: this._renderGlobalReportColumns,
      defaultChartsReportsMetrics: [
        'clicks', 'total_conversions', 'cost', 'total_revenue',
      ],
    };
    this._renderGlobalReportColumns = this._renderGlobalReportColumns.bind(this);
    this.showChart = this.showChart.bind(this);
    this.renderRowColor = this.renderRowColor.bind(this);
    this.getTrProps = this.getTrProps.bind(this);
    this.calculateExportValues = this.calculateExportValues.bind(this);
  }

  static getDerivedStateFromProps (props, state) {
    const usersSettings = props.users.settings
    const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
    const sortedHeads = getSavedOrderOfColumns(state.columnsRenderer(props.global_report, usersSettings), tables, 'id')
    if (props.global_report_filters) {
      if (props.global_report_filters.group.length === 0) {
        return {
          usersSettings: usersSettings,
          heads: [{ ...state.defaultHeadColumn }, ...sortedHeads],
          headsWithOutGrouping: [...sortedHeads],
        }
      } else {
        let groupingHeadColumn = props.global_report_filters.group.map((groupingKey, index, array) => {
          let customProperty = {}
          if (array.length - 1 === index) {
            // customProperty.Expander = () => null
          }
          if (index === 0) {
            customProperty.Footer = <span>Total:</span>
          }
          if (groupingKey === 'hour_of_day') {
            customProperty.sortMethod = (a, b) => (a - b)
          }
          return ({
            title: capitalize(groupingKey),
            Header: () => <span>{capitalize(groupingKey)}</span>,
            accessor: groupingKey,
            id: groupingKey,
            minWidth: 170,
            width: 170,
            PivotValue: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
            value: item => <span>{(item.value !== '') ? item.value : NA_VALUE}</span>,
            ...customProperty,

          })
        });
        return {
          usersSettings: usersSettings,
          heads: [...groupingHeadColumn, ...sortedHeads],
          headsWithOutGrouping: [...sortedHeads],
          groupingHeadColumn: [...groupingHeadColumn],
        }
      }
    }
    return null
  }

  componentDidMount() {
    const { global_report } = this.props;
    const { usersSettings } = this.state;
    this.setState({ defaultHeads: this._renderGlobalReportColumns(global_report, usersSettings) })
  }

  _renderGlobalReportColumns(global_report, user) {
    const currencySign = getCurrencySign(user);
    return [
      {
        id: 'impressions',
        title: 'Impressions',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'impressions',
        Header: () => <span>Impressions</span>,
        Footer: t => sum((global_report || []).map(r => r.impressions)),
        aggregate: values => sum(values),
      },
      {
        id: 'clicks',
        title: 'Clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'clicks',
        Header: () => <span>Clicks</span>,
        Footer: t => sum((global_report || []).map(r => r.clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'unique_clicks',
        title: 'Unique clicks',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'unique_clicks',
        Header: () => <span>Unique clicks</span>,
        Footer: t => sum((global_report || []).map(r => r.unique_clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'ctr',
        title: 'CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'ctr',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>CTR</span>,
        aggregate: (values, rows) => {
          const impressions = sumHiddenColumn(rows, 'impressions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return impressions !== 0 ? clicks / impressions : 0
        },
        Footer: t => {
          const impressions = sum((global_report || []).map(r => r.impressions))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(impressions !== 0 ? clicks / impressions : 0).format('0.00%')
        },
      },
      {
        id: 'lp_views',
        title: 'LP Views',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_views',
        description: 'Landing page views',
        Header: () => <span>LP Views</span>,
        Footer: t => sum((global_report || []).map(resource => resource.lp_views)),
        aggregate: values => sum(values),
      },
      {
        id: 'lp_clicks',
        title: 'LP clicks',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_clicks',
        description: 'Clicks from Lander to offer',
        Header: () => <span>LP Clicks</span>,
        Footer: t => sum((global_report || []).map(resource => resource.lp_clicks)),
        aggregate: values => sum(values),
      },
      {
        id: 'lp_ctr',
        title: 'LP CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_ctr',
        description: 'LP Clicks to LP views ratio',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>LP CTR</span>,
        Footer: t => {
          const lp_clicks = sum((global_report || []).map(r => r.lp_clicks))
          const lp_views = sum((global_report || []).map(r => r.lp_views))
          return numeral(lp_views !== 0 ? lp_clicks / lp_views : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const lp_clicks = sumHiddenColumn(rows, 'lp_clicks')
          const lp_views = sumHiddenColumn(rows, 'lp_views')
          return lp_views !== 0 ? lp_clicks / lp_views : 0
        },
      },
      {
        id: 'lp_click_ctr',
        title: 'LP Click CTR',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'lp_click_ctr',
        description: 'Clicks to LP clicks Ratio',
        Cell: data => numeral((data.row.lp_clicks && data.row.clicks !== 0) ? data.row.lp_clicks/data.row.clicks : 0).format('0.00%'),
        Header: () => <span>LP Click CTR</span>,
        Footer: t => {
          const lp_clicks = sum((global_report || []).map(r => r.lp_clicks))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? lp_clicks / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const lp_clicks = sumHiddenColumn(rows, 'lp_clicks')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? lp_clicks / clicks : 0
        },
      },
      {
        id: 'conversions',
        title: renderConvTypeTitle('conv_default_type','conv_', user),
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'conversions',
        Cell: row => (row.value || 0),
        Header: () => <span>{renderConvTypeTitle('conv_default_type','conv_', user)}</span>,
        Footer: t => sum((global_report || []).map(r => r.conversions)),
        aggregate: values => sum(values),
      },
      {
        id: 'total_conversions',
        title: 'Total Actions',
        className: '',
        minWidth: 150,
        width: 150,
        accessor: 'total_conversions',
        Cell: row => (row.value || 0),
        Header: () => <span>Total Actions</span>,
        Footer: footer => sum((global_report || []).map(row => row.total_conversions)),
        aggregate: values => sum(values),
      },
      {
        id: 'cr',
        title: 'CR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cr',
        description: 'Conversion rate (clicks to conversions)',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>CR</span>,
        Footer: t => {
          const conversions = sum((global_report || []).map(r => r.conversions))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'conversions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'total_conversions_cr',
        title: 'Total Actions CR',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'total_conversions_cr',
        Cell: data => numeral(data.row.total_conversions && data.row.clicks !== 0 ? data.row.total_conversions / data.row.clicks : 0).format('0.00%'),
        Header: () => <span>Total Actions CR</span>,
        Footer: t => {
          const conversions = sum((global_report || []).map(r => r.total_conversions))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? conversions / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'total_conversions')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? conversions / clicks : 0
        },
      },
      {
        id: 'approved',
        title: 'Approved',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'approved',
        Header: () => <span>Approved</span>,
        Footer: t => sum((global_report || []).map(r => r.approved)),
        aggregate: values => sum(values),
      },
      {
        id: 'approved_rate',
        title: 'Approved rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'approved_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Approved rate</span>,
        Footer: t => {
          const approved = sum((global_report || []).map(r => r.approved))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? approved / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const approved = sumHiddenColumn(rows, 'approved')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? approved / clicks : 0
        },
      },
      {
        id: 'pending',
        title: 'Pending',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'pending',
        Header: () => <span>Pending</span>,
        Footer: t => sum((global_report || []).map(r => r.pending)),
        aggregate: values => sum(values),
      },
      {
        id: 'pending_rate',
        title: 'Pending rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'pending_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Pending rate</span>,
        Footer: t => {
          const pending = sum((global_report || []).map(r => r.pending))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? pending / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const pending = sumHiddenColumn(rows, 'pending')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? pending / clicks : 0
        },
      },
      {
        id: 'declined',
        title: 'Declined',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'declined',
        Header: () => <span>Declined</span>,
        Footer: t => sum((global_report || []).map(r => r.declined)),
        aggregate: values => sum(values),
      },
      {
        id: 'declined_rate',
        title: 'Declined rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'declined_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Declined rate</span>,
        Footer: t => {
          const declined = sum((global_report || []).map(r => r.declined))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? declined / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const declined = sumHiddenColumn(rows, 'declined')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? declined / clicks : 0
        },
      },
      {
        id: 'other',
        title: 'Other',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'other',
        Header: () => <span>Other</span>,
        Footer: t => sum((global_report || []).map(r => r.other)),
        aggregate: values => sum(values),
      },
      {
        id: 'other_rate',
        title: 'Other rate',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'other_rate',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>Other rate</span>,
        Footer: t => {
          const other = sum((global_report || []).map(r => r.other))
          const clicks = sum((global_report || []).map(r => r.clicks))
          return numeral(clicks !== 0 ? other / clicks : 0).format('0.00%')
        },
        aggregate: (values, rows) => {
          const other = sumHiddenColumn(rows, 'other')
          const clicks = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? other / clicks : 0
        },
      },
      {
        id: 'transactions',
        title: 'Transactions',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'transactions',
        Header: () => <span>Transactions</span>,
        Footer: t => sum((global_report || []).map(r => r.transactions)),
        aggregate: values => sum(values),
      },
      ...renderConvType(user, CONVTYPE_COUNT),
      ...renderConvRateType(user, CONVTYPE_COUNT),
      ...renderRevenueType(user, currencySign, CONVTYPE_COUNT),
      {
        id: 'revenue',
        title: renderRevenueTypeTitle('revenue_default_type','revenue_', user),
        className: 'bolder',
        minWidth: 180,
        width: 180,
        accessor: 'revenue',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>{renderRevenueTypeTitle('revenue_default_type','revenue_', user)}</span>,
        Footer: t => `${currencySign}${numeral(sum((global_report || []).map(r => r.revenue))).format('0.00[00]')}`,
        aggregate: values => sum(values),
      },
      {
        id: 'total_revenue',
        title: 'Total Revenue',
        className: 'bolder',
        minWidth: 150,
        width: 150,
        accessor: 'total_revenue',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>Total Revenue</span>,
        Footer: footer => `${currencySign}${numeral(sum((global_report || []).map(row => row.total_revenue))).format('0.00[00]')}`,
        aggregate: values => sum(values),
      },
      {
        id: 'cost',
        title: 'Cost',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cost',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>Cost</span>,
        Footer: t => `${currencySign}${numeral(sum((global_report || []).map(r => r.cost))).format('0.00[00]')}`,
        aggregate: values => sum(values),
      },
      {
        id: 'pub_revenue',
        title: 'Commission',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'pub_revenue',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>Commission</span>,
        Footer: footer => `${currencySign}${numeral(sum((global_report || []).map(row => row.pub_revenue))).format('0.00[00]')}`,
        aggregate: values => sum(values),
      },
      {
        id: 'profit',
        title: 'Profit',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'profit',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>Profit</span>,
        Footer: t => `${currencySign}${numeral(sum((global_report || []).map(r => r.profit))).format('0.00[00]')}`,
        aggregate: values => sum(values),
      },
      {
        id: 'roi',
        title: 'ROI',
        className: 'bolder',
        minWidth: 120,
        width: 120,
        accessor: 'roi',
        description: 'Return on Investment',
        Cell: row => numeral(row.value).format('0.00%'),
        Header: () => <span>ROI</span>,
        aggregate: (values, rows) => {
          const profit = sumHiddenColumn(rows, 'profit')
          const cost   = sumHiddenColumn(rows, 'cost')
          return cost !== 0 ? profit / cost : 0
        },
        Footer: (t) => {
          const profit = sum((global_report || []).map(r => r.profit))
          const cost   = sum((global_report || []).map(r => r.cost))
          return numeral(cost !== 0 ? profit / cost : 0).format('0.00%')
        },
      },
      {
        id: 'cpa',
        title: 'CPA',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cpa',
        description: 'Cost per Action',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>CPA</span>,
        aggregate: (values, rows) => {
          const conversions = sumHiddenColumn(rows, 'conversions')
          const cost   = sumHiddenColumn(rows, 'cost')
          return conversions !== 0 ? cost / conversions : 0
        },
        Footer: (t) => {
          const conversions = sum((global_report || []).map(r => r.conversions))
          const cost   = sum((global_report || []).map(r => r.cost))
          return `${currencySign}${numeral(conversions !== 0 ? cost / conversions : 0).format('0.00[00]')}`
        },
      },
      {
        id: 'cpc',
        title: 'CPC',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cpc',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        description: 'Cost per Click',
        Header: () => <span>CPC</span>,
        aggregate: (values, rows) => {
          const clicks = sumHiddenColumn(rows, 'clicks')
          const cost = sumHiddenColumn(rows, 'cost')
          return clicks !== 0 ? cost / clicks : 0
        },
        Footer: (t) => {
          const clicks = sum((global_report || []).map(r => r.clicks))
          const cost   = sum((global_report || []).map(r => r.cost))
          return `${currencySign}${numeral(clicks !== 0 ? cost / clicks : 0).format('0.00[00]')}`
        },
      },
      {
        id: 'cpt',
        title: 'CPT',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'cpt',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        description: 'Cost per Transaction',
        Header: () => <span>CPT</span>,
        aggregate: (values, rows) => {
          const transactions = sumHiddenColumn(rows, 'transactions')
          const cost = sumHiddenColumn(rows, 'cost')
          return transactions !== 0 ? cost / transactions : 0
        },
        Footer: (t) => {
          const transactions = sum((global_report || []).map(r => r.transactions))
          const cost   = sum((global_report || []).map(r => r.cost))
          return `${currencySign}${numeral(transactions !== 0 ? cost / transactions : 0).format('0.00[00]')}`
        },
      },
      {
        id: 'epc',
        title: 'EPC',
        className: '',
        minWidth: 120,
        width: 120,
        accessor: 'epc',
        description: 'Earning per click',
        Cell: row => `${currencySign}${numeral(row.value).format('0.00[00]')}`,
        Header: () => <span>EPC</span>,
        aggregate: (values, rows) => {
          const revenue = sumHiddenColumn(rows, 'total_revenue')
          const clicks   = sumHiddenColumn(rows, 'clicks')
          return clicks !== 0 ? revenue / clicks : 0
        },
        Footer: (t) => {
          const revenue = sum((global_report || []).map(r => r.total_revenue))
          const clicks   = sum((global_report || []).map(r => r.clicks))
          return `${currencySign}${numeral(clicks !== 0 ? revenue / clicks : 0).format('0.00[00]')}`
        },
      },
    ];
  }

  showChart() {
    const { isChartVisible } = this.state;
    this.reportsCharts.showChart()
    this.setState({
      isChartVisible: !isChartVisible,
    });
  };

  calculateExportValues(data) {
    if (data) {
      data.forEach((el) => {
        NOT_PRESENT_VALUES_FROM_REPORT.forEach((value) => {
          el[value.value] = value.calculateValue(el);
        });
      });
    }
    return data;
  };

  renderRowColor(row) {
    let rowColor = '';
    const index  = row.index|| row.viewIndex;
    if (index % 2 === 0) {
      rowColor = 'custom-odd'
    } else {
      rowColor = 'custom-even'
    }
    return rowColor;
  };

  getTrProps(state, item, column, instance) {
    return {
      className: (item && item.subRows ? this.renderRowColor(item, item.row.roi) : 'hidden'),
    }
  }

  render () {
    const { heads, isChartVisible, isChartNeed, defaultChartsReportsMetrics, headsWithOutGrouping } = this.state;
    const { global_report, scope, global_report_filters, loading, loaded, settings } = this.props;
    let groupBy = global_report_filters.group;
    if (groupBy.length === 0) {
      groupBy = [  ...GROUPING_BY_OFFER, ...DEFAULT_GROUPING_BY_DATE ];
    }
    const exportGroup = global_report_filters.group.includes(DEFAULT_GROUPING_BY_DATE[0])
      ? global_report_filters.group : [...global_report_filters.group, ...DEFAULT_GROUPING_BY_DATE];

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <GlobalReportFilters
              scope={scope}
              globalReportFilters={global_report_filters}
              isChartControlPresent={isChartNeed}
              showChart={this.showChart}
              isChartVisible={isChartVisible}
              exportSettings={
                {
                  scope,
                  resourceName: REPORTS_CAMPAIGNS_OPTIONS.TITLE,
                  data: global_report,
                  fields: CSVFields(exportGroup, getCSVFields(heads, settings, 'title', 'id')),
                  addNotPresentValues: this.calculateExportValues,
                }
              }
            />
            <LoadableContainer
              loading={loading}
              loaded={loaded}
              style={{
                position: 'relative',
                width: '100%',
                maxHeight: '350px'
              }}
            >
              {global_report &&
                <Fragment>
                  <ReportsCharts
                    scope={scope}
                    isChartVisible={isChartVisible}
                    optionsFilters={store.getState().reports[`${scope}_filters`]}
                    reportData={global_report}
                    chartItems={defaultChartsReportsMetrics}
                    headsWithOutGrouping={headsWithOutGrouping}
                    onRef={ref => (this.reportsCharts = ref)}
                  />
                  <CustomizableTable
                    className='custom-data-offer-table'
                    columns={heads}
                    data={global_report}
                    pivotBy={groupBy}
                    style={{ maxHeight: "100%", borderLeft: 0, borderRight: 0, borderBottom: 0, width: '100%' }}
                    showPagination={false}
                    getTrProps={this.getTrProps}
                    pageSize={global_report.length}
                    loading={loading}
                  />
                </Fragment>
              }
            </LoadableContainer>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

GlobalReportTable.defaultProps = {
  scope: 'global_report',
}

const mapStateToProps = (state) => ({
  users: state.users,
  user: state.users.currentUser || {},
  settings:  state.users.settings || {},
  global_report: state.reports.global_report,
  loading: state.reports.global_report_loading,
  loaded: state.reports.global_report_loaded,
  global_report_filters: state.reports.global_report_filters,
});

export default connect(
  mapStateToProps,
)(GlobalReportTable)
