import React, { Component } from 'react';
import OfferDetailsContainer from './components/OfferDetailsContainer'

class OfferDetails extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <OfferDetailsContainer
        match={this.props.match}
      />
    )
  }

}

export default OfferDetails
