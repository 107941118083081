import noty from '../../utils/noty'

export const ERROR_OCCURED = 'ERROR_OCCURED'
export const RESET_ERRORS  = 'RESET_ERRORS'

export const errorOccuredWithNotificationAction = (data) => (
  (dispatch) => {
    const ul = list => '<ul class="no-margin">'+list.map((l) => '<li>'+l+'</li>').join('')+'</ul>'
    const msg = (typeof data.errors  !== "undefined") ? ul(data.errors) : data.error
    noty({ text: msg, type: 'error' })
    dispatch(errorOccuredAction(data.error))
    throw data
  }
)

export const errorOccuredAction = (message) =>
  ({ type: ERROR_OCCURED, data: message })
