import React, { Fragment, Component } from 'react'
import { Col, Row } from 'reactstrap'

import OfferDescriptionPart from './OfferDetailsParts/OfferDescriptionPart'
import OfferDeepLinkUrlPart from './OfferDetailsParts/OfferDeepLinkUrlPart'
import OfferRedirectTrackUrlPart from './OfferDetailsParts/OfferRedirectTrackUrlPart'
import OfferDirectTrackingUrlPart from './OfferDetailsParts/OfferDirectTrackingUrlPart'
import OfferReportPart from './OfferDetailsParts/OfferReportPart'
import OfferCreativesPart from './OfferDetailsParts/OfferCreativesPart'


class OfferDetailsRightPart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { resource } = this.props;
    const pubDetailsDomain = resource && resource.publisher_details && resource.publisher_details.domain
    const directTrafficDomain = pubDetailsDomain ? resource.publisher_details.domain : ''
    const { hide_deeplinks, hide_direct_tracking_urls, hide_redirect_tracking_url, hide_redirect_impression_url } = resource.publisher_details

    return (
      <Col md={6} lg={6} sm={6}>
        <Row noGutters={true}>
          <OfferDescriptionPart
            resource={resource}
          />
        </Row>
        {!hide_deeplinks && <Row noGutters={true}>
          <OfferDeepLinkUrlPart
            resource={resource}
          />
        </Row>}
        {!hide_direct_tracking_urls && directTrafficDomain && <Row noGutters={true}>
          <OfferDirectTrackingUrlPart
            resource={resource}
          />
        </Row>}
        {!hide_redirect_tracking_url && <Row noGutters={true}>
          <OfferRedirectTrackUrlPart
            resource={resource}
            showImpression={!hide_redirect_impression_url}
          />
        </Row>}
        <Row noGutters={true}>
          <OfferReportPart
            resource={resource}
          />
        </Row>
        {resource.creatives && resource.creatives.length !== 0 &&
          <Row noGutters={true}>
            <OfferCreativesPart
            resource={resource}
            />
          </Row>}
      </Col>
    )
  }

}

export default OfferDetailsRightPart
