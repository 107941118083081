import React, { Component } from 'react';
import { connect } from 'react-redux'
import classnames from 'classnames';
import store from "../../../containers/App/store";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { loadSettingsAction } from "../../../redux/actions/usersActions";
import { loadDashboardReportAction } from '../../../redux/actions/dashboardsActions'
import Graphs from "../components/Graphs/Graphs";
import { METRICS_DATA, TRAFFIC_DASHBOARD_SCOPE } from "../../../constants/dashboard";
import DashboardFilters from "../../Dashboard/components/filtersGroup/DashboardFilters";
import { DASHBOARD_BY_DAY, DASHBOARD_BY_OFFER } from '../../../constants'
import DateTable from "../components/tableByDate/DateTable";
import OfferTable from "../components/tableByOffer/OfferTable";

class TrafficDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTable: DASHBOARD_BY_DAY,
      scope: TRAFFIC_DASHBOARD_SCOPE,
      metrics: [
        { value: METRICS_DATA[0].value },
        { value: METRICS_DATA[1].value },
        { value: METRICS_DATA[2].value },
        { value: METRICS_DATA[3].value },
      ],
    };
  }

  componentDidMount() {
    const { scope } = this.state;
    store.dispatch(loadSettingsAction())
    store.dispatch(loadDashboardReportAction(scope,{ ...store.getState().dashboards[`${scope}_filters`] }))
  }

  _renderMetricsReportsColumns = (metrics) => {
    this.setState({ metrics: metrics })
  };

  toggle = (currentTableKey, filters) => {
    const { currentTable, scope } = this.state
    if (currentTable !== currentTableKey) {
      this.setState({
        currentTable: currentTableKey,
      }, () => {
        if (this.state.currentTable === DASHBOARD_BY_DAY) {
        } else if (this.state.currentTable === DASHBOARD_BY_OFFER) {
          store.dispatch(loadDashboardReportAction(scope, {  ...filters }, false))
        }
      });
    }
  };

  render () {
    const { currentTable, scope } = this.state;
    const { trafficReportDataByDate, trafficReportData, loading, loaded, users } = this.props;
    return (
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <DashboardFilters
                  scope={scope}
                  currentTable={currentTable}
                  toggleAction={this.toggle}
                />
                {Object.keys(users.settings).length && <Graphs
                  users={users}
                  scope={scope}
                  stat={trafficReportDataByDate}
                  renderReportColumns={this._renderMetricsReportsColumns}
                />}
                {currentTable === DASHBOARD_BY_DAY && <DateTable
                  reportData={trafficReportDataByDate}
                  loading={loading}
                  loaded={loaded}
                />}
                {currentTable === DASHBOARD_BY_OFFER && <OfferTable
                  reportData={trafficReportData}
                  loading={loading}
                  loaded={loaded}
                />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }

}

const mapStateToProps = (state) => ({
  trafficReportData: state.dashboards[TRAFFIC_DASHBOARD_SCOPE],
  trafficReportDataByDate: state.dashboards[`${TRAFFIC_DASHBOARD_SCOPE}_by_date`],
  loading: state.dashboards[`${TRAFFIC_DASHBOARD_SCOPE}_loading`],
  loaded: state.dashboards[`${TRAFFIC_DASHBOARD_SCOPE}_loaded`],
  users: state.users,
});

export default connect(
  mapStateToProps,
)(TrafficDashboard)
