import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { resetPasswordAction } from './../../../redux/actions/usersActions'
import store from '../../App/store'
import PropTypes from 'prop-types';
import validate from './validate';

const renderField = ({
                           input, placeholder, type, meta: { touched, error },
                     }) => (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <input {...input} placeholder={placeholder} type={type} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

class ResetPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
        email: "",
        errors: null
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.trim() })
  }

  handleSubmit = (event) =>{
    event.preventDefault()
    let authData = new FormData()
    let stateData = this.state
    delete stateData.errors
    for ( var key in stateData ) {
      authData.append(key, stateData[key]);
    }
    store.dispatch(resetPasswordAction(authData)).then(_ => {
        this.setState({
            success: true
        })
        setTimeout(function () {
           this.props.history.push('/')
        }.bind(this), 5000)
    })
        .catch(error => {
            if (error.response) {
                this.setState({
                    errors: error.response.data.error
                })
                setTimeout(function () {
                    this.setState({
                        errors: null
                    })
                }.bind(this), 5000)
            }
            else {
                this.setState({
                    errors: 'Invalid credentials'
                })
                setTimeout(function () {
                    this.setState({
                        errors: null
                    })
                }.bind(this), 5000)
            }
        })
  }

  render() {
    return (
        <div>
            {!this.state.success ?
              <form className="form" onSubmit={this.handleSubmit}>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Email</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <Field
                              name="email"
                              component={renderField}
                              type="text"
                              placeholder="john@gmail.com"
                              onChange={this.handleChange.bind(this)}
                          />
                      </div>
                  </div>
                  <div className="form__form-group">
                      <div className="form__form-group-field">
                          {this.state.errors &&
                          <span className="form__form-group-error">{this.state.errors}</span>
                          }
                      </div>
                  </div>
                  <button disabled={this.state.email  ? false : true} type="submit" className="btn btn-primary account__btn account__btn--small">Reset</button>
              </form>
            :
            <div>
                Your password is resetted, check email
            </div>
            }
        </div>
    );
  }
}

export default reduxForm({
  form: 'reset_password_form',
  validate,
})(ResetPasswordForm);
