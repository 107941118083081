/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import shortid from "shortid";
import Select from "react-select";
import CopyToClipboard from 'react-copy-to-clipboard';
import ClipboardFileIcon from 'mdi-react/ClipboardFileIcon';

import store from "../../../../containers/App/store";
import noty from '../../../../utils/noty';
import { getQueryUrlSign } from '../../../../utils';

import { COPY_NOTIFICATION } from '../../../../constants';

const copyTrackbackLink = (url) => noty({ text: COPY_NOTIFICATION })

class OfferDeepLinkUrlPart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      deepLinkOption: {
        value: 'default',
        label: 'Default',
      },
      deepLinksOptions: [],
      direct_url: '',
      redirect_url: '',
      impression_url: '',
    };
  }

  componentDidMount() {
    const { resource } = this.props;
    const user = store.getState().users.currentUser || {};
    const pubDetails = (resource && resource.publisher_details) || {};
    const pubDetailsDeepLinks = pubDetails.deeplinks || [];
    const trackback_url = resource && resource.trackback_url ? resource.trackback_url : '';
    const deeplinkTrackbackUrl = `${trackback_url}${getQueryUrlSign(trackback_url)}deeplink={deeplink}`;
    const pubId = user && user.id ? user.id : '';
    const offerId = resource && resource.id ? resource.id : '';
    const directUrl = `{deeplink}?cmpid=${offerId}&pubid=${pubId}`;
    let deepLinksOptions = pubDetailsDeepLinks.map((deepLink) => {
      let option = {
        value: `id_${shortid.generate()}_${deepLink.name}`,
        label: deepLink.name,
        direct_url: (deepLink.active_direct && deepLink.direct_url) || '',
        redirect_url: (deepLink.active_redirect && deepLink.redirect_url) || '',
        impression_url: (deepLink.active_impression && deepLink.impression_url) || '',
      };
      if (!deepLink.active_direct
        && !deepLink.active_redirect
        && !deepLink.active_impression) {
        option = undefined;
      }
      return option
    });
    deepLinksOptions.unshift({
      value: 'default',
      label: 'Default',
      isDisabled: true,
      direct_url: directUrl || '',
      redirect_url: deeplinkTrackbackUrl || '',
      impression_url: '',
    });
    deepLinksOptions = deepLinksOptions.filter((option) => option);

    this.setState({
      direct_url: directUrl,
      redirect_url: deeplinkTrackbackUrl,
      impression_url: '',
      deepLinksOptions,
    }, () => {
      this.props.dispatch(change('deep_links_urls_form', 'trackback_url', this.state.direct_url));
      this.props.dispatch(change('deep_links_urls_form', 'trackback_url_deeplink', this.state.redirect_url));
      this.props.dispatch(change('deep_links_urls_form', 'impression_url', this.state.impression_url));
    })
  }

  handleChangeSelect = (event) => {
    const { deepLinksOptions } = this.state;
    if (event) {
      deepLinksOptions.map(option => option.isDisabled = option.value === event.value)
    } else {
      deepLinksOptions.map(option => option.isDisabled = false);
    }
    this.setState({
      deepLinksOptions,
      deepLinkOption: event,
      direct_url: event.direct_url,
      redirect_url: event.redirect_url,
      impression_url: event.impression_url,
    });
  };

  render () {
    const {
      deepLinkOption,
      deepLinksOptions,
      direct_url,
      redirect_url,
      impression_url,
    } = this.state;
    const directId = shortid.generate();
    const reDirectId = shortid.generate();
    const impressionId = shortid.generate();

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="form form--horizontal">
            <div className="form__form-group mb-10 no-margin-top">
              <span
                className="form__form-group-label"
                style={{
                  fontWeight: '600'
                }}
              >
                Select DeepLink
              </span>
              <div className="form__form-group-field no-margin-left no-padding-left">
                <div
                  className='offer-details__field-container'
                  style={{
                    width: '50%'
                  }}
                >
                  <Select
                    style={{
                      minWidth: '50%',
                    }}
                    isMulti={false}
                    name='select'
                    value={deepLinkOption}
                    onChange={this.handleChangeSelect}
                    options={deepLinksOptions}
                    isClearable={false}
                    closeOnSelect={false}
                    removeSelected={false}
                    className="react-select"
                    placeholder='Select DeepLink'
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group mb-10 mt-10">
              <p>
                Select the desired deeplink from the available options, in the fields with the URLS you will be able to copy the format of the URL that you need.
              </p>
              <p>
                {'If you Default deeplink - you have to replace the {deeplink} with the product URL.'}
              </p>
            </div>
            {direct_url && (
              <div className="form__form-group mb-10 mt-10">
                <span className="form__form-group-label">Direct URL DeepLink</span>
                <div className="form__form-group-field no-margin-left no-padding-left">
                  <div
                    id={`id_${directId}_direct`}
                    className='offer-details__field-container'
                  >
                    <Field
                      name="direct_url"
                      component="input"
                      type="text"
                      value={direct_url}
                      placeholder={direct_url}
                      disabled
                    />
                  </div>
                  <UncontrolledTooltip placement="top" target={`id_${directId}_direct`}>
                    {direct_url}
                  </UncontrolledTooltip>
                  <div className="form__form-group-icon customize-icon-container">
                    <CopyToClipboard id={`id_${directId}_direct_link`} text={direct_url} onCopy={copyTrackbackLink}>
                      <div className="">
                        <a href="javascript:;">
                          <ClipboardFileIcon
                            className="customize-icon"
                          />
                        </a>
                      </div>
                    </CopyToClipboard>
                    <UncontrolledTooltip placement="top" target={`id_${directId}_direct_link`}>
                      {direct_url}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            )}
            {redirect_url && (
              <div className="form__form-group mb-10 mt-10">
                <span className="form__form-group-label">Redirect URL DeepLink</span>
                <div className="form__form-group-field no-margin-left no-padding-left">
                  <div
                    id={`id_${reDirectId}_redirect`}
                    className='offer-details__field-container'
                  >
                    <Field
                      name="redirect_url"
                      component="input"
                      type="text"
                      value={redirect_url}
                      placeholder={redirect_url}
                      disabled
                    />
                  </div>
                  <UncontrolledTooltip placement="top" target={`id_${reDirectId}_redirect`}>
                    {redirect_url}
                  </UncontrolledTooltip>
                  <div className="form__form-group-icon customize-icon-container">
                    <CopyToClipboard id={`id_${reDirectId}_redirect_link`} text={redirect_url} onCopy={copyTrackbackLink}>
                      <div className="">
                        <a href="javascript:;">
                          <ClipboardFileIcon
                            className="customize-icon"
                          />
                        </a>
                      </div>
                    </CopyToClipboard>
                    <UncontrolledTooltip placement="top" target={`id_${reDirectId}_redirect_link`}>
                      {redirect_url}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            )}
            {impression_url && (
              <div className="form__form-group mb-10 mt-10">
                <span className="form__form-group-label">Impression URL DeepLink</span>
                <div className="form__form-group-field no-margin-left no-padding-left">
                  <div
                    id={`id_${impressionId}_imp`}
                    className='offer-details__field-container'
                  >
                    <Field
                      name="impression_url"
                      component="input"
                      type="text"
                      value={impression_url}
                      placeholder={impression_url}
                      disabled
                    />
                  </div>
                  <UncontrolledTooltip placement="top" target={`id_${impressionId}_imp`}>
                    {impression_url}
                  </UncontrolledTooltip>
                  <div className="form__form-group-icon customize-icon-container">
                    <CopyToClipboard id={`id_${impressionId}_imp_link`} text={impression_url} onCopy={copyTrackbackLink}>
                      <div className="">
                        <a href="javascript:;">
                          <ClipboardFileIcon
                            className="customize-icon"
                          />
                        </a>
                      </div>
                    </CopyToClipboard>
                    <UncontrolledTooltip placement="top" target={`id_${impressionId}_imp_link`}>
                      {impression_url}
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default reduxForm({
  form: 'deep_links_urls_form',
})(OfferDeepLinkUrlPart)
