/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'

class OfferDescriptionPart extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { resource } = this.props;
    const pubDetails = resource.publisher_details
    const descriptions = pubDetails && pubDetails.description ? pubDetails.description : ''

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Campaign Description</h5>
          </div>
          <div className="form__form-group mb-10 mt-10">
            <div className="form__form-group-field no-margin-left no-padding-left">
              <div dangerouslySetInnerHTML={{__html: descriptions}}></div>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default OfferDescriptionPart
