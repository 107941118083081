import moment from 'moment'
import { LOAD_STAT, ADD_STAT, FILTER_STAT } from './../actions/statActions'

export const STATUS_ACTIVE   = 1
export const STATUS_ARCHIVED = 2

const initialState = {
    items: [],
    filters: {
        date_from: moment().subtract(29, 'days').startOf("day"),
        date_to: moment().endOf("day"),
    },
    loading: false,
    loaded: false,
}

export const stat = (state=initialState, action) => {
    switch (action.type) {

        case LOAD_STAT:
            return {
                ...state,
                loading: true,
                loaded: false,
            }

        case ADD_STAT:
            return {
                ...state,
                loading: false,
                loaded: false,
                items: action.data.items
            }

        case FILTER_STAT:
            return {
                filters: action.filters
            }

        default:
            return state

    }
}
