import React, { Component, Fragment } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { ButtonGroup, Col, UncontrolledTooltip } from 'reactstrap';
import store from "../../../containers/App/store";
import { Field, reduxForm } from "redux-form";
import renderIntervalDatePickerField from '../common/DatePicker'
import { loadPublishersPostbacksAction, filterAction } from '../../../redux/actions/postbacksLogsActions'
import { loadCampaignsAction } from "../../../redux/actions/campaignsActions";
import ReportExportButton from '../../General/Table/navbar/export/ReportExportButton';

class PostbacksLogsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersOptions: [],
      filteredBy: null,
      filters: store.getState().postbacks_logs.filters,
    };
    this.loadDataForExport = this.loadDataForExport.bind(this);
  }

  componentDidMount() {
    store.dispatch(loadCampaignsAction).then((data) => {
      const campaignsData = data || []
      let offersOptions = undefined
      if (campaignsData) {
        offersOptions = campaignsData.map(campaign => ({ value: campaign.id, label: campaign.title }))
        offersOptions = [{ value: 'all', label: 'All' }, ...offersOptions]
      }
      this.setState({ offersOptions });
    })
  }

  loadDataForExport(filters = this.state.filters) {
    store.dispatch(filterAction(filters));
    return store.dispatch(loadPublishersPostbacksAction)
      .then((resource) => ({ data: resource.items || 0 }));
  };

  handleSubmit = () => {
    store.dispatch(filterAction(this.state.filters));
    store.dispatch(loadPublishersPostbacksAction);
  };

  handleChange = (event) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        date_from: moment(event.start),
        date_to: moment(event.end),
      }
    })
  }

  handleChangeSelect = (event) => {
    const { filters, offersOptions } = this.state;
    if (event) {
      filters.campaign_ids = [event.value];
      if (event.value === 'all') {
        filters.campaign_ids = [];
      }
      offersOptions.map(option => option.isDisabled = option.value === event.value)
    } else {
      offersOptions.map(option => option.isDisabled = false);
      delete filters.campaign_ids
    }
    this.setState({
      offersOptions,
      filters: {
        ...filters,
      },
      filteredBy: event,
    });
  };

  render() {
    const { filters, offersOptions, filteredBy } = this.state;
    const { exportSettings } = this.props;
    const exportSettingsProps = {
      ...exportSettings,
      loadReportData: this.loadDataForExport,
    };
    return (
      <Fragment>
        <form className="form form--horizontal">
          <ButtonGroup
            style={{
              marginBottom: 20,
            }}
          >
            <ReportExportButton { ...exportSettingsProps }/>
          </ButtonGroup>
        </form>
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="form__form-group mb-0">
            <div className="form__form-group-field">
              <Col md={3} className='custom-date-picker pl-0'>
                <Field
                  name="interval_date"
                  filters={filters}
                  component={renderIntervalDatePickerField}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md={3} lg={3} sm={3}>
                <div
                  className="filter__by-offer"
                >
                  <span className="form__form-group-label label">Filter by</span>
                  <div
                    className="form__form-group-field"
                    id='TooltipoffersOptions'
                  >
                    <Select
                      style={{
                        minWidth: '100%',
                      }}
                      isMulti={false}
                      name='select'
                      value={filteredBy}
                      onChange={this.handleChangeSelect}
                      options={offersOptions}
                      isClearable={false}
                      isDisabled={false}
                      closeOnSelect={false}
                      removeSelected={false}
                      className="react-select"
                      placeholder='Choose campaign'
                      classNamePrefix="react-select"
                    />
                  </div>
                  {/*<UncontrolledTooltip placement="top" target="TooltipoffersOptions">*/}
                  {/*  {'This functionality is currently unavailable, but we are working on its implementation. Once it’s ready to use, you will see it live'}*/}
                  {/*</UncontrolledTooltip>*/}
                </div>
              </Col>
              <Col md={2}>
                <div
                  style={{
                    width: 90,
                  }}
                >
                  <div
                    style={{
                      height: 42,
                      lineHeight: '20px'
                    }}
                    onClick={this.handleSubmit}
                    className="btn btn-primary account__btn"
                  >
                    Apply
                  </div>
                </div>
              </Col>

            </div>
          </div>
        </form>
      </Fragment>
    )
  }

}

PostbacksLogsFilters.defaultProps = {
}

export default reduxForm({
  form: 'date_picker_form',
})(PostbacksLogsFilters)
