/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { change, Field, reduxForm } from "redux-form";
import {
  Button,
  ButtonToolbar,
  Col,
  Row,
  Modal,
} from 'reactstrap';
import { connect } from 'react-redux';
import store from "../../App/store";
import { loadPaymentsAction, updateRequestedAmountAction } from "../../../redux/actions/paymentsActions";
import noty from "../../../utils/noty";

class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPayment: props.currentPayment,
    }
  }

  componentDidMount() {
    this.props.dispatch(change('payments_modal_form', 'amount', this.state.currentPayment.amount));
  }

  handleChangeAmount = (event) => {
    const { value, name } = event.target;
    const { currentPayment } = this.state;
    this.setState({
      currentPayment: {
        ...currentPayment,
        [name]: Number(value),
      },
    });
  }

  handleSubmitModal = (event) => {
    event.preventDefault()
    const { hideModal } = this.props;
    const { currentPayment } = this.state
    if (currentPayment.amount >= 0) {
      store.dispatch(updateRequestedAmountAction({ ...currentPayment }))
      .then(() => {
        noty({ text: 'Requested Amount changed successfully', type: 'success' });
        store.dispatch(loadPaymentsAction);
      })
      .catch((error) => error)
      hideModal();
    }
  }

  render() {
    const { showModal, hideModal } = this.props;
    const { currentPayment } = this.state;
    let error = ''
    if (parseFloat(currentPayment.amount) < 0) {
      error = <span style={{ position: 'absolute', top: '37px' }} className="form__form-group-error">Requested Amount must be greater than 0</span>
    }

    return (
      <Modal
        isOpen={showModal}
        toggle={hideModal}
        className="modal-dialog--primary modal-dialog--header"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" onClick={hideModal} />
          <h4 className="bold-text  modal__title">Change Requested Amount</h4>
        </div>
        <div className="modal__body">
          <div className="theme-light">
            <Row>
              <Col md={12} lg={12}>
                <form className="form form--horizontal" onSubmit={this.handleSubmit}>
                  <div
                    className="form__form-group"
                  >
                    <span className="form__form-group-label">Requested Amount:</span>
                    <div className="form__form-group-field">
                      <Field
                        name="amount"
                        component="input"
                        type="number"
                        min={0}
                        value={currentPayment.amount}
                        onChange={this.handleChangeAmount}
                        placeholder="Requested Amount"
                        disabled={false}
                      />
                      {error}
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button onClick={hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.handleSubmitModal}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.currentUser,
  users: state.users,
  settings:  state.users.settings,
  payments: state.payments,
  loading: state.payments.loading,
  loaded: state.payments.loaded,
})

export default reduxForm({
  form: 'payments_modal_form',
})(connect(
  mapStateToProps,
)(PaymentModal))
