import React, { Component } from 'react'
import {connect} from "react-redux";
import store from '../App/store'
import { getUserDataForBecomeAction, loadSettingsAction } from "../../redux/actions/usersActions"

class Become extends Component {

	constructor(props) {
		super(props)
		this.state = {
			apikey: null
		}
	}

	componentDidMount() {
		store.dispatch(loadSettingsAction())
		const url = new URL(window.location.href)
		this.setState({
			apikey: url.searchParams.get("usr")
		})
		store.dispatch(getUserDataForBecomeAction(url.searchParams.get("usr"))).then((data) => {
			localStorage.setItem("user", JSON.stringify(data))
			document.location.href = '/dashboard'
		})
	}

	render(){
		return (
			<div className="account">
				<div className="account__wrapper">
					Wait a bit, we are logging you in
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	settings: state.users ? state.users.settings : {}
})

export default connect(mapStateToProps)(Become)
