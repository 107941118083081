import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import ReferralPageContainer from './components/ReferralPageContainer';

const ReferralPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Referral Program</h3>
      </Col>
    </Row>
    <Row>
      <ReferralPageContainer />
    </Row>
  </Container>
);

export default ReferralPage;
