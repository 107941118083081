import moment from 'moment';
import {
  LOAD_REPORT,
  ADD_REPORT,
  ADD_FILTERS,
  LOADING_REPORT_DATA,
  ADD_REPORTS_FILTERS,
  ADD_REPORT_DATA,
  REPORT_CHANGE_PAGE,
  REPORT_CHANGE_PAGE_SIZE,
} from '../../redux/actions/reportActions';
import { DEFAULT_GROUPING_BY_DATE, GROUPING_BY_OFFER } from '../../constants';
import { REPORTS_REDUCER_NAMES } from '../../constants/reports/main';
import { REPORTS_REFERRAL_PROGRAM_OPTIONS } from '../../constants/reports/referral';

const DEFAULT_FILTERS = {
  date_from: moment().subtract(29, 'days'),
  date_to: moment(),
  group: DEFAULT_GROUPING_BY_DATE,
};

const initialState = {
  offers: [],
  offers_filters: { ...DEFAULT_FILTERS },
  offers_loading: false,
  offers_loaded: false,
  global_report: [],
  global_report_filters: {
    ...DEFAULT_FILTERS,
    group: [...GROUPING_BY_OFFER, ...DEFAULT_GROUPING_BY_DATE],
  },
  global_report_loading: false,
  global_report_loaded: false,

  [REPORTS_REDUCER_NAMES.REFERRAL_PROGRAM]: {
    items: [],
    filters: {},
    loading: false,
    loaded: false,
    totalItems: {},
    total: 0,
    page: 0,
    pageSize: REPORTS_REFERRAL_PROGRAM_OPTIONS.DEFAULT_PAGE_SIZE,
  },
};

export const reports = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_REPORT:
      return {
        ...state,
        [action.scope]: [],
        [`${action.scope}_filters`]: action.filters,
        [`${action.scope}_loading`]: true,
        [`${action.scope}_loaded`]: false,
      };

    case ADD_REPORT:
      return {
        ...state,
        [action.scope]: action.data.items,
        [`${action.scope}_total`]: action.data.total,
        [`${action.scope}_loading`]: false,
        [`${action.scope}_loaded`]: false,
      };

    case ADD_FILTERS:
      return {
        ...state,
        [`${action.scope}_filters`]: action.filters,
      };

    case LOADING_REPORT_DATA: {
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          loading: true,
          loaded: false,
        },
      };
    }

    case ADD_REPORTS_FILTERS: {
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          filters: action.filters,
        },
      };
    }

    case ADD_REPORT_DATA: {
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          items: action.data.items || [],
          loading: false,
          loaded: false,
          totalItems: action.data.totalItems || {},
          total: action.data.total || 0,
        },
      };
    }

    case REPORT_CHANGE_PAGE: {
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          page: action.payload,
        }
      };
    }

    case REPORT_CHANGE_PAGE_SIZE: {
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          pageSize: action.payload,
        }
      };
    }
    default:
      return state
  }
};
