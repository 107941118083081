import React, { Fragment } from 'react';

export default class LoadableContainer extends React.Component {
  render () {
    return (
      <Fragment>
        {this.props.loading &&
        <Fragment>
          <div className={`load${this.props.loaded ? '' : ' loaded'}`}
               style={{ ...this.props.style }}
          >
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
              </svg>
            </div>
          </div>

        </Fragment>
        }
        {!this.props.loading && this.props.children}
      </Fragment>
    )
  }
}

LoadableContainer.defaultProps = {
  loading: true,
  loaded: false,
};
