import { loadStat, } from '../../utils/apiAdapter'
import moment from 'moment'
import store from '../../containers/App/store'

export const LOAD_STAT   = 'LOAD_STAT'
export const ADD_STAT    = 'ADD_STAT'
export const FILTER_STAT = 'FILTER_STAT'

export const loadStatAction = (dispatch) => {
    dispatch({ type: LOAD_STAT })

    return loadStat({
        // status:        store.getState().campaigns.filters.status,
        date_from:     moment(store.getState().stat.filters.date_from).format('YYYY-MM-DD'),
        date_to:       moment(store.getState().stat.filters.date_to).format('YYYY-MM-DD'),
    }, (items, total) => dispatch(addStatAction({ items, total })))
}

export const addStatAction = data => ({ type: ADD_STAT, data: data })

export const filterAction = filters => ({ type: FILTER_STAT, filters })