import { loadConversionReport, loadTrafficReport } from '../../utils/apiAdapter'
import { DEFAULT_GROUPING_BY_DATE, GROUPING_BY_OFFER } from '../../constants/index'
import { CONVERSION_DASHBOARD_SCOPE, TRAFFIC_DASHBOARD_SCOPE } from '../../constants/dashboard'

export const LOAD_DASHBOARD_REPORT = 'LOAD_DASHBOARD_REPORT';
export const ADD_DASHBOARD_REPORT = 'ADD_DASHBOARD_REPORT';
export const ADD_DASHBOARD_GRAPHS_REPORT = 'ADD_DASHBOARD_GRAPHS_REPORT';

export const loadDashboardReportAction = (scope, filters = {}, isDefaultGrouping=true) => (
  dispatch => {
    dispatch({ type: LOAD_DASHBOARD_REPORT, scope, filters });
    const groupingWithDefault = filters.group.filter(el => el !== DEFAULT_GROUPING_BY_DATE[0])
    const grouping = isDefaultGrouping ? [ ...groupingWithDefault, ...DEFAULT_GROUPING_BY_DATE ] : [ ...groupingWithDefault, ...GROUPING_BY_OFFER ]
    let params = {
      ...filters,
      group: grouping.join(','),
      date_from: filters.date_from.format('YYYY-MM-DD'),
      date_to: filters.date_to.format('YYYY-MM-DD'),
    };
    if (params.group.length === 0) {
      delete params.group
    }
    if (scope === CONVERSION_DASHBOARD_SCOPE) {
      return loadConversionReport(params, (items, total) => {
        if (isDefaultGrouping) {
          dispatch(addReportGraphsDataAction(scope, { items, total }))
        } else {
          dispatch(addReportDataAction(scope, { items, total }))
        }
      })
    } else if (scope === TRAFFIC_DASHBOARD_SCOPE) {
      return loadTrafficReport(params, (items, total) => {
        if (isDefaultGrouping) {
          dispatch(addReportGraphsDataAction(scope, { items, total }))
        } else {
          dispatch(addReportDataAction(scope, { items, total }))
        }
      })
    }
  }
);

export const addReportDataAction = (scope, data) => ({ type: ADD_DASHBOARD_REPORT, data, scope });

export const addReportGraphsDataAction = (scope, data) => ({ type: ADD_DASHBOARD_GRAPHS_REPORT, data, scope });

