const CONVERSIONS_DEFAULT_TYPE = 'default_type'
const CONVERSIONS_DEFAULT_TYPE_ALIAS = 'Conversion'
const CONVERSIONS_TYPES_EMPTY_SHAPE = {
  default_type: CONVERSIONS_DEFAULT_TYPE_ALIAS,
  type1: "",
  type2: "",
  type3: "",
  type4: "",
  type5: "",
  type6: "",
  type7: "",
  type8: "",
  type9: "",
  type10: "",
}
const PUBLISHERS_STATISTICS_CONV_TYPES_COLUMNS = [
  {
    id: 'convtype1',
    show: false,
  },
  {
    id: 'convtype2',
    show: false,
  },
  {
    id: 'convtype3',
    show: false,
  },
  {
    id: 'convtype4',
    show: false,
  },
  {
    id: 'convtype5',
    show: false,
  },
  {
    id: 'convtype6',
    show: false,
  },
  {
    id: 'convtype7',
    show: false,
  },
  {
    id: 'convtype8',
    show: false,
  },
  {
    id: 'convtype9',
    show: false,
  },
  {
    id: 'convtype10',
    show: false,
  },
]
const PUBLISHERS_STATISTICS_REV_TYPES_COLUMNS = [
  {
    id: 'revenuetype1',
    show: false,
  },
  {
    id: 'revenuetype2',
    show: false,
  },
  {
    id: 'revenuetype3',
    show: false,
  },
  {
    id: 'revenuetype4',
    show: false,
  },
  {
    id: 'revenuetype5',
    show: false,
  },
  {
    id: 'revenuetype6',
    show: false,
  },
  {
    id: 'revenuetype7',
    show: false,
  },
  {
    id: 'revenuetype8',
    show: false,
  },
  {
    id: 'revenuetype9',
    show: false,
  },
  {
    id: 'revenuetype10',
    show: false,
  },
]

export {
  CONVERSIONS_TYPES_EMPTY_SHAPE,
  CONVERSIONS_DEFAULT_TYPE,
  CONVERSIONS_DEFAULT_TYPE_ALIAS,
  PUBLISHERS_STATISTICS_CONV_TYPES_COLUMNS,
  PUBLISHERS_STATISTICS_REV_TYPES_COLUMNS,
}
