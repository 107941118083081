const COMPANY_TYPE = [
  { value: 'affiliate_media_buyer', label: 'Solo affiliate / media-buyer' },
  { value: 'media_buying_tm', label: 'Media-buying team' },
  { value: 'advertising_agency', label: 'Advertising agency' },
  { value: 'affiliate_network', label: 'Affiliate network' },
  { value: 'e_com', label: 'E-com' },
  { value: 'advertiser', label: 'Advertiser' },
]
const MESSENGER_TYPE = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'skype', label: 'Skype' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'phone', label: 'Phone' },
  { value: 'other', label: 'Other' },
]
const SIGNUP_ADDINTIONAL_FIELDS_LIST = [
  "country",
  "find_us",
  "find_us_details",
  "referral",
  "referral_details",
  "verticals",
  "verticals_details",
  "geos",
  "affiliate_types",
  "traffic_types",
  "traffic_types_details",
  "payout_types",
  "payout_volume",
]

const FIND_US_FIELD = 'find_us'
const FIND_US_OTHER = 5
const TRAFFIC_FIELD = 'traffic_types'
const TRAFFIC_OTHER = 11
const REFERRAL_FIELD = 'referral'
const FIND_US_TYPE = [
  { value: 1, label: 'Forum' },
  { value: 2, label: 'Summit' },
  { value: 3, label: 'Paid promotion' },
  { value: 4, label: 'Recommendation' },
  { value: FIND_US_OTHER, label: 'Other' },
]
const REFERRAL_TYPE = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]
const VERTICALS_TYPE = [
  { value: 1, label: 'E-com' },
  { value: 2, label: 'Sweepstakes' },
  { value: 3, label: 'Nutra' },
  { value: 4, label: 'Casino' },
  { value: 5, label: 'Crypto' },
  { value: 6, label: 'Dating' },
  { value: 7, label: 'Adult' },
  { value: 8, label: 'Betting' },
  { value: 9, label: 'Business' },
  { value: 10, label: 'Casual' },
  { value: 11, label: 'Communication' },
  { value: 12, label: 'Education' },
  { value: 13, label: 'Entertainment' },
  { value: 14, label: 'Food & Drink' },
  { value: 15, label: 'Finance' },
  { value: 16, label: 'Games' },
  { value: 17, label: 'Gambling' },
  { value: 18, label: 'Health' },
  { value: 19, label: 'Lifestyle' },
  { value: 20, label: 'Music & Audio' },
  { value: 21, label: 'News & Magazines' },
  { value: 22, label: 'Personalization' },
  { value: 23, label: 'Photo & Video' },
  { value: 24, label: 'Productivity' },
  { value: 25, label: 'Rewards' },
  { value: 26, label: 'Shopping' },
  { value: 27, label: 'Social' },
  { value: 28, label: 'Sports' },
  { value: 29, label: 'Tools' },
  { value: 30, label: 'Travel' },
  { value: 31, label: 'Utilities' },
  { value: 32, label: 'Other' },
]
const GEOS_TYPE = [
  { value: 1, label: 'US / CA' },
  { value: 2, label: 'UK' },
  { value: 3, label: 'Europe' },
  { value: 4, label: 'AU + NZ' },
  { value: 5, label: 'Tier 1 - US, CA, GB, AU' },
  { value: 6, label: 'Tier 2 - Europe and Nordic' },
  { value: 7, label: 'Tier 3 - All Other Countries' },
]
const AFFILIATE_TYPE = [
  { value: 1, label: 'Website / App Owner' },
  { value: 2, label: 'Affiliate / Media Buyer' },
  { value: 3, label: 'Media Buying Agency' },
  { value: 4, label: 'Ad Network' },
  { value: 5, label: 'Affiliate Network' },
]
const TRAFFIC_TYPE = [
  { value: 1, label: 'Display / Banner' },
  { value: 2, label: 'Native' },
  { value: 3, label: 'Social (FB, etc.)' },
  { value: 4, label: 'Push' },
  { value: 5, label: 'Pop / Redirect' },
  { value: 6, label: 'Search / PPC' },
  { value: 7, label: 'SEO / SEM' },
  { value: 8, label: 'Review Site / Blog' },
  { value: 9, label: 'Email' },
  { value: 10, label: 'Video' },
  { value: TRAFFIC_OTHER, label: 'Other' },
]
const PAYOUT_TYPE = [
  { value: 1, label: 'CPL / CPA / CPS' },
  { value: 2, label: 'CPM + CPC' },
  { value: 3, label: 'RevShare' },
  { value: 4, label: 'Other' },
]
const PAYOUT_VOLUME_TYPE = [
  { value: 1, label: '< $1000' },
  { value: 2, label: '$1000 – $5000' },
  { value: 3, label: '$5000 – $10000' },
  { value: 4, label: '> $10000' },
]

export {
  COMPANY_TYPE,
  MESSENGER_TYPE,
  SIGNUP_ADDINTIONAL_FIELDS_LIST,
  FIND_US_TYPE,
  FIND_US_FIELD,
  FIND_US_OTHER,
  REFERRAL_TYPE,
  REFERRAL_FIELD,
  VERTICALS_TYPE,
  GEOS_TYPE,
  AFFILIATE_TYPE,
  TRAFFIC_TYPE,
  TRAFFIC_OTHER,
  TRAFFIC_FIELD,
  PAYOUT_TYPE,
  PAYOUT_VOLUME_TYPE,
}
