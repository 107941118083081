/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import { connect } from 'react-redux';

import { filterAction } from "../../../redux/actions/paymentsActions";
import { LoadableContainer } from "./../../../widgets";
import { getCurrencySign } from '../../../utils';
import PaymentModal from '../common/PaymentModal';
import CustomizableTable from '../../../common/table/customizableTable';
import { PAGINATION_OPTIONS, getPublishersPayoutsColumns } from '../../../constants/payouts';
import numeral from "numeral";

class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    const user = props.user;
    this.state = {
      currentPayment: {},
      payment_type: user.payment_type || '',
      balance: 'Balance',
      payment_method: '',
      payment_amount: '',
      showModal: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.renderRowColor = this.renderRowColor.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this._renderColumns = this._renderColumns.bind(this);
  }

  showModal(payment) {
    this.setState({
      showModal: true,
      currentPayment: { ...payment }
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  downloadInvoice(data, pub, usersSettings={}) {
    const title = ((pub || {}).email || '');
    const billingDetailsArray = pub.billing_details || '';
    const detailsStartPoint = 32;
    const defaultIndent = 5;
    const stringCount = 12;
    const paymentRequestSettings = usersSettings.payment_request_settings || {};
    const companyName = paymentRequestSettings.company_name || '';
    const paymentRequestDetailsArray = paymentRequestSettings.details || '';
    const invoiceNumber = `${data.pub_id.substr(data.pub_id.length - 3)}-${moment(data.payment_date).format('YYYYMMDD')}-1`;
    const currencySign = getCurrencySign(pub);
    const amount = `${currencySign}${numeral(data.amount).format('0.00')}`;
    const doc = new jsPDF();
    let billingMargin = 0;
    doc.setFontSize(16);
    doc.text(`Partner:\n${title}`, 10, 10);
    doc.setFontSize(14);
    doc.text('Billing details:', 10, 24);
    doc.setFontSize(11);
    doc.text(doc.splitTextToSize(billingDetailsArray, 90).slice(0, stringCount), 10, (detailsStartPoint + billingMargin));
    doc.setFontSize(14);
    doc.text('Company details:', 110, 24);
    doc.setFontSize(11);
    if (companyName) {
      doc.text(companyName, 110, detailsStartPoint);
    }
    doc.text(doc.splitTextToSize(paymentRequestDetailsArray, 90).slice(0, stringCount), 110, (detailsStartPoint + (companyName ? defaultIndent : 0)));
    doc.setTextColor(190, 0, 0);
    doc.setFontSize(16);
    doc.text('INVOICE', 10, 95);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(11);
    doc.text('INVOICE NO.', 127, 105);
    doc.text('DATE', 140, 110);
    if (paymentRequestSettings.payment_terms > 0) {
      doc.text('PAYMENT TERMS', 117, 115);
    }
    doc.text(invoiceNumber, 172, 105);
    doc.text(moment(data.payment_date).format('YYYY-MM-DD'), 180, 110);
    if (paymentRequestSettings.payment_terms > 0) {
      doc.text(paymentRequestSettings.payment_terms.toString(), 196, 115);
    }
    doc.line(10, 120, 200, 120);
    doc.setDrawColor(0);
    doc.setFillColor(227, 225, 225);
    doc.rect(10, 130, 190, 10, 'F');
    doc.text('ITEM', 15, 136);
    doc.text('AMOUNT', 193, 136, null, null, 'right');
    doc.text(`${companyName} referral payment to ${title}`, 15, 147);
    doc.text(`for the period of ${moment(data.payment_date).format('YYYY-MM')}`, 15, 152);
    doc.text(amount, 193, 147, null, null, 'right');
    doc.setLineDash([1]);
    doc.line(10, 156, 200, 156);
    doc.text('BALANCE DUE', 115, 165);
    doc.setFontSize(16);
    doc.text(amount, 193, 165, null, null, 'right');
    doc.setFontSize(10);
    doc.text('If no VAT is shown on this invoice then the customer is liable to account for the VAT on this supply of service under the', 105, 280, null, null, 'center');
    doc.text('reverse charge in accordance with the provisions of Article 44 of the VAT Directive 2006/112/EB', 105, 285, null, null, 'center');
    doc.save(`Invoice ${moment(data.payment_date).format('YYYY-MM-DD')} ${title}.pdf`);
  }

  _renderColumns() {
    const {
      user,
      settings,
    } = this.props;
    const currencySign = getCurrencySign(user);
    return [
      ...getPublishersPayoutsColumns(
        currencySign,
        {
          user: user,
          usersSettings: settings,
        },
        {
          showModal: this.showModal,
          downloadInvoice: this.downloadInvoice,
        }
      ),
    ];
  }

  renderRowColor(row) {
    let rowColor = '';
    const index  = row.index|| row.viewIndex;
    if (index % 2 === 0) {
      rowColor = 'custom-odd';
    } else {
      rowColor = 'custom-even';
    }
    return rowColor;
  };

  render() {
    const {
      payments,
      filters,
      loading,
      loaded,
      total,
      onPaginationLoad,
      _filterAction,
    } = this.props;
    const {
      showModal,
      currentPayment,
    } = this.state;

    const pageSize = PAGINATION_OPTIONS[1].value;
    const isPaginationRequired = (total > pageSize);
    const items = payments || [];

    return (
      <>
        <LoadableContainer
          loading={false}
          loaded={loaded}
          style={{
            position: 'relative',
            width: '100%',
            maxHeight: '350px',
          }}
        >
          <CustomizableTable
            sortable={false}
            className='custom-data-offer-table'
            columns={this._renderColumns()}
            sourceName="publisher_postbacks"
            keyField="id"
            data={items}
            defaultSortDesc={false}
            minRows={3}
            pageSize={isPaginationRequired ? pageSize : items.length}
            pages={isPaginationRequired ? Math.ceil(total / pageSize) : 0}
            showPagination={isPaginationRequired}
            showPaginationTop={false}
            showPaginationBottom={isPaginationRequired}
            showPageSizeOptions={false}
            manual={isPaginationRequired}
            pageSizeOptions={[pageSize]}
            onPageChange={(page) => {
              if (isPaginationRequired) {
                filters.page = page + 1;
                _filterAction(filters);
                onPaginationLoad();
              }
            }}
            style={{
              maxHeight: '100%',
              borderLeft: 0,
              borderRight: 0,
              borderBottom: 0,
              width: '100%',
            }}
            customDragDropColumnsClass={'min-drag-drop-context-container'}
            loading={loading}
            getTrProps={(state, row, column, instance) => ({
              className: (row && row.original && this.renderRowColor(row)),
            })}
          />
        </LoadableContainer>
        {showModal && (
          <PaymentModal
            showModal={showModal}
            currentPayment={currentPayment}
            hideModal={this.hideModal}
          />
        )}
      </>
    );
  }
}

PaymentHistory.defaultProps = {
  payments: [],
  total: 0,
  filters: {},
  _filterAction: () => null,
  onPaginationLoad: () => null,
};

const mapStateToProps = (state) => ({
  user: state.users.currentUser || {},
  users: state.users,
  settings:  state.users.settings || {},
  filters: state.payments.filters,
  payments: state.payments.items,
  total: state.payments.total,
  loading: state.payments.loading,
  loaded: state.payments.loaded,
})

const mapDispatchToProps = (dispatch) => ({
  _filterAction: (filters) => dispatch(filterAction(filters)),
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentHistory));
