import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

const BannersLayout = (props) => {
  const {
    settings,
  } = props;
  const usersSettings = settings || {};
  const promotionalBanners = usersSettings.promotional_banners || [];
  const count = promotionalBanners.length;
  const isPromotionalBanners = count > 0;

  const _renderBanners = () => {
    return (
      <div
        className="banners-container"
        style={{
          justifyContent: count > 1 ? 'space-around' : 'center',
        }}
      >
        {promotionalBanners.map((banner) => (
          <div className="banners-img-container" >
            <a href={banner.redirect_url} target="_blank">
              <img src={banner.picture_url} alt={banner.redirect_url}/>
            </a>
          </div>
        ))}
      </div>
    )
  };

  return (
    <>
      {isPromotionalBanners ? _renderBanners() : null
      }
    </>
  );
};

BannersLayout.propTypes = {};

BannersLayout.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.users.currentUser,
  settings:  state.users.settings
});

export default connect(
  mapStateToProps,
)(BannersLayout);
