import React from 'react';
import PropTypes from 'prop-types';
import json2csv from 'json2csv';
import moment from 'moment-timezone';
import {  Button } from 'reactstrap';
import ExportIcon from 'mdi-react/ExportIcon';

import { downloadCSV } from '../../../../../widgets/export';

const ReportExportButton = (props) => {
  const {
    scope,
    data,
    fields,
    resourceName,
    loadReportData,
    addNotPresentValues,
  } = props;

  const exportCSV = () => {
    if (loadReportData) {
      loadReportData()
      .then((reports) => {
        let reportsData = reports.data;
        if (addNotPresentValues) {
          reportsData = addNotPresentValues(reportsData);
        }
        downloadCSV({
          data: json2csv({ data: reportsData, fields: fields }),
          filename: `${resourceName || 'data'}_${moment().format('YYYY-MM-DD')}.csv`,
        });
      });
    } else {
      let exportData = data;
      if (addNotPresentValues) {
        exportData = addNotPresentValues(exportData);
      }
      downloadCSV({
        data: json2csv({ data: exportData, fields: fields }),
        filename: `${resourceName || 'data'}_${moment().format('YYYY-MM-DD')}.csv`,
      });
    }
  };

  return (
    <Button
      style={{
        marginBottom: 0,
      }}
      className="icon"
      variant="contained"
      color="secondary"
      onClick={exportCSV}
    >
      <p><ExportIcon /> Export</p>
    </Button>
  );
};

ReportExportButton.propTypes = {
  data: [],
  fields: [],
};

ReportExportButton.propTypes = {
  addNotPresentValues: PropTypes.func,
  loadReportData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
};

export default ReportExportButton;
