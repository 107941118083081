import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts'

import { dateFormat } from '../../../../utils'

class ChartReportComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartData: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      chartData: props.chartData,
    }
  }

  _renderYAxis = () => {
    const { lineCharts } = this.props
    const YAxises = []
    lineCharts.map((el, i) => {
      YAxises.push(
        <YAxis
          key={i}
          orientation={i > 1 ? 'right' : 'left'}
          yAxisId={i}
          { ...el.yaxisProperties }
        />
      )
    })
    return YAxises
  }

  _renderLine = () => {
    const { lineCharts } = this.props
    const Lines = []
    lineCharts.map((el, i) => {
      Lines.push(
        <Line
          key={i}
          dataKey={el.value}
          yAxisId={i}
          { ...el.lineChartProperties }
        />
      )
    })
    return Lines
  }

  render() {
    const { chartData } = this.state
    return (
      <ResponsiveContainer width="100%" height={180}>
        <ComposedChart
          data={chartData}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <XAxis dataKey="date" name="Date" tickFormatter={dateFormat}/>
          {this._renderYAxis()}
          {this._renderLine()}
          <Tooltip isAnimationActive={false}/>
          <Legend/>
          <CartesianGrid strokeDasharray="3 3"/>
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

}

ChartReportComponent.propTypes = {
}

ChartReportComponent.defaultProps = {
}

export default ChartReportComponent
