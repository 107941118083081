import { loadReport, loadReferrals } from '../../utils/apiAdapter'
import { DEFAULT_GROUPING_BY_DATE } from '../../constants/index'
import { errorOccuredAction } from '../actions/errors';
import { REPORTS_REFERRAL_PROGRAM_OPTIONS } from '../../constants/reports/referral';

export const LOAD_REPORT = 'LOAD_REPORT';
export const LOADING_REPORT_DATA = '@REPORTS/LOADING_REPORT_DATA';
export const ADD_REPORT = 'ADD_REPORT';
export const ADD_REPORT_DATA = '@REPORTS/ADD_REPORT_DATA';
export const ADD_FILTERS = 'ADD_FILTERS';
export const ADD_REPORTS_FILTERS = '@REPORTS/ADD_REPORTS_FILTERS';
export const REPORT_CHANGE_PAGE = '@REPORTS/REPORT_CHANGE_PAGE';
export const REPORT_CHANGE_PAGE_SIZE = '@REPORTS/REPORT_CHANGE_PAGE_SIZE';

export const startLoadReport = (scope = '') => ({ type: LOADING_REPORT_DATA, scope });
/**
 * @param scope {string}
 * @param data {array}
 * @returns {{data: *[], scope: string, type: string}}
 */
export const addReportData = (scope = '', data = []) => ({ type: ADD_REPORT_DATA, data, scope });
/**
 * @param scope {string}
 * @param filters {object}
 * @returns {{scope, filters, type: string}}
 * @private
 */
const addReportsFilters = (scope = '', filters = {}) => ({ type: ADD_REPORTS_FILTERS, filters, scope });
/**
 * @param scope {string}
 * @param pageSize {number}
 * @returns {{pageSize: number, scope: string, type: string}}
 */
const changeReportPageSize = (scope = '', pageSize = 0) => ({ type: REPORT_CHANGE_PAGE_SIZE, payload: pageSize, scope });
/**
 * @param scope {string}
 * @param page {number}
 * @returns {{page: number, scope: string, type: string}}
 */
const changeReportPage = (scope = '', page = 0) => ({ type: REPORT_CHANGE_PAGE, payload: page, scope });
/**
 * @param scope
 * @param page
 * @returns {(function(*): void)|*}
 */
export const changeReportPageAction = (scope = '', page = 0) => (dispatch) => {
  dispatch(changeReportPage(scope, page));
};

/**
 * @param scope
 * @param pageSize
 * @returns {(function(*): void)|*}
 */
export const changeReportPageSizeAction = (scope = '', pageSize = 0) => (dispatch) => {
  dispatch(changeReportPageSize(scope, pageSize));
};

/**
 * @param scope
 * @param data
 * @returns {(function(*): void)|*}
 */
export const filtersReportsAction = (scope = '', data = {}) => (dispatch) => {
  dispatch(addReportsFilters(scope, data));
};

/**
 * @param scope {string}
 * @param data {array}
 * @returns {(function(*): void)|*}
 */
export const addReportDataAction = (scope = '', data = []) => (dispatch) => {
  dispatch(addReportData(scope, data));
};
export const _addReportDataAction = (scope, data) => ({
  type: ADD_REPORT,
  data,
  scope,
});

export const _filterAction = (scope, filters) => ({ type: ADD_FILTERS, filters, scope });
export const filterAction = (scope, filters) => (dispatch) => {
  return dispatch(_filterAction(scope, filters));
};

export const loadReportAction = (scope, filters = {}, isDefaultGrouping=true) => (
  dispatch => {
    dispatch({ type: LOAD_REPORT, scope, filters });
    const groupingWithDefault = filters.group.filter(el => el !== DEFAULT_GROUPING_BY_DATE[0])
    const grouping = isDefaultGrouping ? [ ...groupingWithDefault, ...DEFAULT_GROUPING_BY_DATE ] : [ ...filters.group ]
    let params = {
      ...filters,
      group: grouping.join(','),
      date_from: filters.date_from.format('YYYY-MM-DD'),
      date_to: filters.date_to.format('YYYY-MM-DD'),
    };
    if (params.group.length === 0) {
      delete params.group
    }

    return loadReport(params)
    .then((resource) => {
      dispatch(_addReportDataAction(
        scope,
        {
          items: resource.items || resource.data,
          total: resource.total || 0,
        }
      ));
      return resource;
    })
    .catch((error) => dispatch(errorOccuredAction(error)));
  }
);

export const loadReferralsReportAction = (newFilters = {}) => (dispatch, getState) => {
  dispatch(startLoadReport(REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE));
  const { filters, page, pageSize } = getState().reports[REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE];
  let _filters = {
    ...filters,
    ...newFilters,
  };
  const data = {
    ..._filters,
    page: page + 1,
    per: pageSize,
  };
  return loadReferrals(data)
    .then((response) => {
      return dispatch(addReportData(REPORTS_REFERRAL_PROGRAM_OPTIONS.SCOPE, {
        items: response.data.publishers || [],
        total: response.data.pagination.total || 0,
    }));
  })
    .catch((error) => dispatch(errorOccuredAction(error)));
}
