/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'

const OfferReportUrlLinkFormatter = ({ value }) => {
  return <Link to={`/offers/report/${value}`}>View Report</Link>
}

class OfferReportPart extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { resource } = this.props;
    const offerLink = resource && resource.id ? resource.id : ''

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Detailed Report</h5>
          </div>
          <div className="form__form-group mb-10 mt-10">
            <div className="form__form-group-field no-margin-left no-padding-left">
              <span className="view-report-container">
                {OfferReportUrlLinkFormatter({ value: offerLink })}
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default OfferReportPart
