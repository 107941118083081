import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { loadSettingsAction } from "../../../redux/actions/usersActions";
import { loadPaymentsAction } from "../../../redux/actions/paymentsActions";
import PaymentsTable from './PaymentsTable';
import RequestPaymentContainer from "../common/RequestPaymentContainer";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onPaginationLoad = this.onPaginationLoad.bind(this);
  }

  componentDidMount() {
    const { _loadSettingsAction, _loadPaymentsAction } = this.props;
    _loadSettingsAction();
    _loadPaymentsAction();
  }

  onPaginationLoad() {
    const { _loadPaymentsAction } = this.props;
    _loadPaymentsAction();
  };

  render () {

    return (
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody
                style={{
                  position: 'relative',
                  width: '100%',
                  maxHeight: '100%',
                  minHeight: '100%',
                }}
              >
                <RequestPaymentContainer />
                <PaymentsTable
                  onPaginationLoad={this.onPaginationLoad}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

Payments.defaultProps = {
  _loadSettingsAction: () => null,
  _loadPaymentsAction: () => null,
};

const mapStateToProps = (state) => ({
  users: state.users || {},
});


const mapDispatchToProps = (dispatch) => ({
  _loadSettingsAction: () => dispatch(loadSettingsAction()),
  _loadPaymentsAction: () => dispatch(loadPaymentsAction),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Payments);
