/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, {Component, Fragment} from 'react';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import store from '../../../App/store'
import {connect} from "react-redux";
import {LoadableContainer} from "../../../../widgets";
import {
    getSavedOrderOfColumns,
    renderRevenueTypeTitle,
    renderConvTypeTitle,
    renderConvTypeRateTitle,
    getCurrencySign,
} from '../../../../utils';
import numeral from "numeral";
import {TOTAL_TEXT} from "../../../../constants";

const DEF_HEADS = (currencySign, usersSettings) => [
    {
        key: 'impressions',
        name: 'Impressions',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false,
    },
    {
        key: 'clicks',
        name: 'Clicks',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false,
    },
    {
        key: 'unique_clicks',
        name: 'Unique clicks',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false,
    },
    {
        key: 'ctr',
        name: 'CTR',
        formatter: ({value}) => numeral(value).format('0.00%'),
        resizable: true,
        sortable: false,
    },
    {
        key: 'lp_views',
        name: 'LP Views',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false,
    },
    {
        key: 'lp_clicks',
        name: 'LP clicks',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false,
    },
    {
        key: 'lp_ctr',
        name: 'LP CTR',
        formatter: ({value}) => numeral(value).format('0.00%'),
        resizable: true,
        sortable: false,
    },
    {
        key: 'lp_click_ctr',
        name: 'LP Click CTR',
        formatter: ({row}) => numeral(row.clicks && row.clicks !== 0 ? row.lp_clicks / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false,
    },
    {
        key: 'conversions',
        name: renderConvTypeTitle('conv_default_type', 'conv_', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'total_conversions',
        name: 'Total Actions',
        width: 150,
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'total_conversions_cr',
        name: 'Total Actions CR',
        width: 150,
        formatter: ({row}) => numeral(row.total_conversions && row.clicks !== 0 ? row.total_conversions / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'cr',
        name: 'CR',
        formatter: ({value}) => numeral(value).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'approved',
        name: 'Approved',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'approved_rate',
        name: 'Approved rate',
        formatter: ({row}) => numeral(row.approved ? row.approved / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'pending',
        name: 'Pending',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'pending_rate',
        name: 'Pending rate',
        formatter: ({row}) => numeral(row.pending ? row.pending / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'declined',
        name: 'Declined',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'declined_rate',
        name: 'Declined rate',
        formatter: ({row}) => numeral(row.declined ? row.declined / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'other',
        name: 'Other',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'other_rate',
        name: 'Other rate',
        formatter: ({row}) => numeral(row.other ? row.other / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'transactions',
        name: 'Transactions',
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'revenue',
        name: renderRevenueTypeTitle('revenue_default_type', 'revenue_', usersSettings),
        width: 180,
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'total_revenue',
        name: 'Total Revenue',
        width: 150,
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'cost',
        name: 'Cost',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'pub_revenue',
        name: 'Commission',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'profit',
        name: 'Profit',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'roi',
        name: 'ROI',
        formatter: ({value}) => numeral(value).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'cpa',
        name: 'CPA',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'cpc',
        name: 'CPC',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'cpt',
        name: 'CPT',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false
    },
    {
        key: 'epc',
        name: 'EPC',
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        resizable: true,
        sortable: false,
    },
    {
        key: 'convtype1',
        name: renderConvTypeTitle('convtype1', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype2',
        name: renderConvTypeTitle('convtype2', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype3',
        name: renderConvTypeTitle('convtype3', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype4',
        name: renderConvTypeTitle('convtype4', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype5',
        name: renderConvTypeTitle('convtype5', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype6',
        name: renderConvTypeTitle('convtype6', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype7',
        name: renderConvTypeTitle('convtype7', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype8',
        name: renderConvTypeTitle('convtype8', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype9',
        name: renderConvTypeTitle('convtype9', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype10',
        name: renderConvTypeTitle('convtype10', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype11',
        name: renderConvTypeTitle('convtype11', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype12',
        name: renderConvTypeTitle('convtype12', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype13',
        name: renderConvTypeTitle('convtype13', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype14',
        name: renderConvTypeTitle('convtype14', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype15',
        name: renderConvTypeTitle('convtype15', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype16',
        name: renderConvTypeTitle('convtype16', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype17',
        name: renderConvTypeTitle('convtype17', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype18',
        name: renderConvTypeTitle('convtype18', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype19',
        name: renderConvTypeTitle('convtype19', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convtype20',
        name: renderConvTypeTitle('convtype20', 'conv', usersSettings),
        formatter: ({value}) => (value || 0),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype1',
        name: renderConvTypeRateTitle('convtype1', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype1 && row.clicks !== 0 ? row.convtype1 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype2',
        name: renderConvTypeRateTitle('convtype2', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype2 && row.clicks !== 0 ? row.convtype2 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype3',
        name: renderConvTypeRateTitle('convtype3', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype3 && row.clicks !== 0 ? row.convtype3 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype4',
        name: renderConvTypeRateTitle('convtype4', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype4 && row.clicks !== 0 ? row.convtype4 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype5',
        name: renderConvTypeRateTitle('convtype5', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype5 && row.clicks !== 0 ? row.convtype5 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype6',
        name: renderConvTypeRateTitle('convtype6', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype6 && row.clicks !== 0 ? row.convtype6 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype7',
        name: renderConvTypeRateTitle('convtype7', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype7 && row.clicks !== 0 ? row.convtype7 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype8',
        name: renderConvTypeRateTitle('convtype8', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype8 && row.clicks !== 0 ? row.convtype8 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype9',
        name: renderConvTypeRateTitle('convtype9', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype9 && row.clicks !== 0 ? row.convtype9 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype10',
        name: renderConvTypeRateTitle('convtype10', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype10 && row.clicks !== 0 ? row.convtype10 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype11',
        name: renderConvTypeRateTitle('convtype11', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype11 && row.clicks !== 0 ? row.convtype11 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype12',
        name: renderConvTypeRateTitle('convtype12', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype12 && row.clicks !== 0 ? row.convtype12 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype13',
        name: renderConvTypeRateTitle('convtype13', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype13 && row.clicks !== 0 ? row.convtype13 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype14',
        name: renderConvTypeRateTitle('convtype14', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype14 && row.clicks !== 0 ? row.convtype14 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype15',
        name: renderConvTypeRateTitle('convtype15', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype15 && row.clicks !== 0 ? row.convtype15 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype16',
        name: renderConvTypeRateTitle('convtype16', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype16 && row.clicks !== 0 ? row.convtype16 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype17',
        name: renderConvTypeRateTitle('convtype17', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype17 && row.clicks !== 0 ? row.convtype17 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype18',
        name: renderConvTypeRateTitle('convtype18', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype18 && row.clicks !== 0 ? row.convtype18 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype19',
        name: renderConvTypeRateTitle('convtype19', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype19 && row.clicks !== 0 ? row.convtype19 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'convratetype20',
        name: renderConvTypeRateTitle('convtype20', 'conv', usersSettings),
        formatter: ({row}) => numeral(row.convtype20 && row.clicks !== 0 ? row.convtype20 / row.clicks : 0).format('0.00%'),
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype1',
        name: renderRevenueTypeTitle('revenuetype1', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype2',
        name: renderRevenueTypeTitle('revenuetype2', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype3',
        name: renderRevenueTypeTitle('revenuetype3', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype4',
        name: renderRevenueTypeTitle('revenuetype4', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype5',
        name: renderRevenueTypeTitle('revenuetype5', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype6',
        name: renderRevenueTypeTitle('revenuetype6', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype7',
        name: renderRevenueTypeTitle('revenuetype7', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype8',
        name: renderRevenueTypeTitle('revenuetype8', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype9',
        name: renderRevenueTypeTitle('revenuetype9', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype10',
        name: renderRevenueTypeTitle('revenuetype10', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype11',
        name: renderRevenueTypeTitle('revenuetype11', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype12',
        name: renderRevenueTypeTitle('revenuetype12', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype13',
        name: renderRevenueTypeTitle('revenuetype13', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype14',
        name: renderRevenueTypeTitle('revenuetype14', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype15',
        name: renderRevenueTypeTitle('revenuetype15', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype16',
        name: renderRevenueTypeTitle('revenuetype16', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype17',
        name: renderRevenueTypeTitle('revenuetype17', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype18',
        name: renderRevenueTypeTitle('revenuetype18', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype19',
        name: renderRevenueTypeTitle('revenuetype19', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
    {
        key: 'revenuetype20',
        name: renderRevenueTypeTitle('revenuetype20', 'revenue', usersSettings),
        formatter: ({value}) => `${currencySign}${numeral(value).format('0.00[00]')}`,
        width: 180,
        resizable: true,
        sortable: false
    },
]

class OfferTable extends Component {
    constructor(props) {
        super(props);
        const users = store.getState().users || {};
        const usersSettings = users.settings || {};
        const currencySign = getCurrencySign(usersSettings);
        this.state = {
            usersSettings: users.settings || {},
            heads: [],
            defaultHeads: DEF_HEADS(currencySign, usersSettings),
            defaultHeadColumn: {
                key: 'campaign',
                name: 'Campaign',
                sortable: false,
                width: 500,
            },
            filters: store.getState().campaigns.filters
        }
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(props, state) {
        const usersSettings = state.usersSettings
        const tables = usersSettings && usersSettings.settings ? usersSettings.settings : []
        const sortedHeads = getSavedOrderOfColumns(DEF_HEADS(getCurrencySign(usersSettings), usersSettings), tables, 'key')
        return {
            heads: [{...state.defaultHeadColumn}, ...sortedHeads],
        }
    }

    prepareTotalDataRow = (offers, heads) => {
        const {defaultHeads} = this.state;
        let newOffersData = [...offers];
        let totalKey = heads[0].key;
        let totalData = {};
        totalData[totalKey] = TOTAL_TEXT;
        defaultHeads.map((columnKey, i) => {
            totalData[columnKey.key] = offers.length !== 0 ? offers.reduce((accumulator, currentValue) => (accumulator + currentValue[columnKey.key]), 0) : 0;
        })
        defaultHeads.map((columnKey, i) => {
            switch (columnKey.key) {
                case 'ctr':
                    totalData.ctr = numeral(totalData.impressions !== 0 ? totalData.clicks / totalData.impressions : 0);
                    break;
                case 'lp_ctr':
                    totalData.lp_ctr = numeral(totalData.lp_views !== 0 ? totalData.lp_clicks / totalData.lp_views : 0);
                    break;
                case 'lp_click_ctr':
                    totalData.lp_click_ctr = numeral(totalData.clicks !== 0 ? totalData.lp_clicks / totalData.clicks : 0);
                    break;
                case 'cr':
                    totalData.cr = numeral(totalData.clicks !== 0 ? totalData.conversions / totalData.clicks : 0);
                    break;
                case 'total_conversions_cr':
                    totalData.total_conversions_cr = numeral(totalData.clicks !== 0 ? totalData.total_conversions / totalData.clicks : 0);
                    break;
                case 'convratetype1':
                    totalData.convratetype1 = numeral(totalData.clicks !== 0 ? totalData.convtype1 / totalData.clicks : 0);
                    break;
                case 'convratetype2':
                    totalData.convratetype2 = numeral(totalData.clicks !== 0 ? totalData.convtype2 / totalData.clicks : 0);
                    break;
                case 'convratetype3':
                    totalData.convratetype3 = numeral(totalData.clicks !== 0 ? totalData.convtype3 / totalData.clicks : 0);
                    break;
                case 'convratetype4':
                    totalData.convratetype4 = numeral(totalData.clicks !== 0 ? totalData.convtype4 / totalData.clicks : 0);
                    break;
                case 'convratetype5':
                    totalData.convratetype5 = numeral(totalData.clicks !== 0 ? totalData.convtype5 / totalData.clicks : 0);
                    break;
                case 'convratetype6':
                    totalData.convratetype6 = numeral(totalData.clicks !== 0 ? totalData.convtype6 / totalData.clicks : 0);
                    break;
                case 'convratetype7':
                    totalData.convratetype7 = numeral(totalData.clicks !== 0 ? totalData.convtype7 / totalData.clicks : 0);
                    break;
                case 'convratetype8':
                    totalData.convratetype8 = numeral(totalData.clicks !== 0 ? totalData.convtype8 / totalData.clicks : 0);
                    break;
                case 'convratetype9':
                    totalData.convratetype9 = numeral(totalData.clicks !== 0 ? totalData.convtype9 / totalData.clicks : 0);
                    break;
                case 'convratetype10':
                    totalData.convratetype10 = numeral(totalData.clicks !== 0 ? totalData.convtype10 / totalData.clicks : 0);
                    break;
                case 'convratetype11':
                    totalData.convratetype11 = numeral(totalData.clicks !== 0 ? totalData.convtype11 / totalData.clicks : 0);
                    break;
                case 'convratetype12':
                    totalData.convratetype12 = numeral(totalData.clicks !== 0 ? totalData.convtype12 / totalData.clicks : 0);
                    break;
                case 'convratetype13':
                    totalData.convratetype13 = numeral(totalData.clicks !== 0 ? totalData.convtype13 / totalData.clicks : 0);
                    break;
                case 'convratetype14':
                    totalData.convratetype14 = numeral(totalData.clicks !== 0 ? totalData.convtype14 / totalData.clicks : 0);
                    break;
                case 'convratetype15':
                    totalData.convratetype15 = numeral(totalData.clicks !== 0 ? totalData.convtype15 / totalData.clicks : 0);
                    break;
                case 'convratetype16':
                    totalData.convratetype16 = numeral(totalData.clicks !== 0 ? totalData.convtype16 / totalData.clicks : 0);
                    break;
                case 'convratetype17':
                    totalData.convratetype17 = numeral(totalData.clicks !== 0 ? totalData.convtype17 / totalData.clicks : 0);
                    break;
                case 'convratetype18':
                    totalData.convratetype18 = numeral(totalData.clicks !== 0 ? totalData.convtype18 / totalData.clicks : 0);
                    break;
                case 'convratetype19':
                    totalData.convratetype19 = numeral(totalData.clicks !== 0 ? totalData.convtype19 / totalData.clicks : 0);
                    break;
                case 'convratetype20':
                    totalData.convratetype20 = numeral(totalData.clicks !== 0 ? totalData.convtype20 / totalData.clicks : 0);
                    break;
                case 'approved_rate':
                    totalData.approved_rate = numeral(totalData.clicks !== 0 ? totalData.approved / totalData.clicks : 0);
                    break;
                case 'pending_rate':
                    totalData.pending_rate = numeral(totalData.clicks !== 0 ? totalData.pending / totalData.clicks : 0);
                    break;
                case 'declined_rate':
                    totalData.declined_rate = numeral(totalData.clicks !== 0 ? totalData.declined / totalData.clicks : 0);
                    break;
                case 'other_rate':
                    totalData.other_rate = numeral(totalData.clicks !== 0 ? totalData.other / totalData.clicks : 0);
                    break;
                case 'roi':
                    totalData.roi = numeral(totalData.cost !== 0 ? totalData.profit / totalData.cost : 0);
                    break;
                case 'cpa':
                    totalData.cpa = numeral(totalData.conversions !== 0 ? totalData.cost / totalData.conversions : 0);
                    break;
                case 'cpc':
                    totalData.cpc = numeral(totalData.clicks !== 0 ? totalData.cost / totalData.clicks : 0);
                    break;
                case 'cpt':
                    totalData.cpt = numeral(totalData.transactions !== 0 ? totalData.cost / totalData.transactions : 0);
                    break;
                case 'epc':
                    totalData.epc = numeral(totalData.clicks !== 0 ? totalData.revenue / totalData.clicks : 0);
                    break;
                default:
            }
        });
        newOffersData.push(totalData)
        return [...newOffersData]
    };

    render() {
        const {reportData, loading, loaded} = this.props;
        const {heads} = this.state;
        const campaignsItems = reportData || []
        const resource = this.prepareTotalDataRow(campaignsItems, heads);

        return (
            <Fragment>
                <LoadableContainer
                    loading={loading}
                    loaded={loaded}
                    style={{
                        position: 'relative',
                        width: '100%',
                        maxHeight: '350px'
                    }}
                >
                    {resource &&
                        <DataPaginationTable
                            lastRowStyle={{
                                fontWeight: 'bolder',
                                backgroundColor: '#f5f5f5',
                            }}
                            evenRowStyle={{
                                backgroundColor: '#f9f9f9',
                            }}
                            heads={heads}
                            rows={resource || []}
                        />
                    }
                </LoadableContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.users,
})

export default connect(mapStateToProps)(OfferTable)
