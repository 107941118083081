import moment from 'moment';
import { loadPayments, requestPayment, updateRequestedAmount } from '../../utils/apiAdapter'
import { errorOccuredWithNotificationAction as errorOccuredAction } from './errors'
import { PAGINATION_OPTIONS } from '../../constants';

export const LOAD_PAYMENTS = 'LOAD_PAYMENTS';
export const ADD_PAYMENTS = 'ADD_PAYMENTS';
export const ADD_PAYMENTS_ERROR = 'ADD_PAYMENTS_ERROR';
export const FILTER_PAYMENTS = 'FILTER_PAYMENTS';

const _loadPayments = () => ({
    type: LOAD_PAYMENTS,
});

export const _addPaymentsAction = (data) => ({
    type: ADD_PAYMENTS,
    data,
});

export const _addPaymentsError = () => ({
    type: ADD_PAYMENTS_ERROR,
});

export const filterAction = (filters) => ({ type: FILTER_PAYMENTS, filters });

export const loadPaymentsAction = (dispatch, getState) => {
    dispatch(_loadPayments());
    const { payments } = getState();
    const { filters } = payments;
    let pageSize = PAGINATION_OPTIONS[1].value;
    return loadPayments({
        date_from: '2019-01-01',
        date_to: moment().format('YYYY-MM-DD'),
        page: filters.page || 1,
        per: pageSize,
    })
    .then((resource) => {
      const data = resource.data || {};
      if (!data.items || !data.total) {
        return dispatch(_addPaymentsError());
      }
      return dispatch(_addPaymentsAction({
        items: data.items,
        total: data.total,
      }));
    })
    .catch((error) => dispatch(errorOccuredAction(error)));
}

export  const requestPaymentAction = (data) => (
  (dispatch) => (
    requestPayment(data)
    .then(resource => resource)
    .catch(err => {
        dispatch(errorOccuredAction(err.response.data))
    })
  )
)

export  const updateRequestedAmountAction = (data) => (
  (dispatch) => (
    updateRequestedAmount(data)
    .then(resource => resource)
    .catch(err => {
        dispatch(errorOccuredAction(err.response.data))
    })
  )
)

export const addPaymentsAction   = data => ({ type: ADD_PAYMENTS, data: data })
