import { LOAD_PAYMENTS, ADD_PAYMENTS, FILTER_PAYMENTS, ADD_PAYMENTS_ERROR } from './../actions/paymentsActions';

const initialState = {
    items: [],
    total: 0,
    filters: {},
    loading: false,
    loaded: false,
}

export const payments = (state=initialState, action) => {
    switch (action.type) {

        case LOAD_PAYMENTS:
            return {
                ...state,
                loading: true,
                loaded: false,
            }

        case ADD_PAYMENTS:
            return {
                ...state,
                loading: false,
                loaded: false,
                items: action.data.items || [],
                total: action.data.total,
            }

        case ADD_PAYMENTS_ERROR:
            return {
                ...state,
                loading: false,
                loaded: false,
                items: [],
                total: 0,
            };

        case FILTER_PAYMENTS:
            return {
                ...state,
                filters: action.filters,
            };

        default:
            return state

    }
}
