import { loadPublishersPostbacks} from '../../utils/apiAdapter';
import { prepareDateFromTo } from '../../utils';
import { errorOccuredWithNotificationAction as errorOccuredAction } from './errors';
import { PAGINATION_OPTIONS } from '../../constants';

import { DATE_FORMAT_HOUR, LAST_HOUR_FLAG_VALUE } from '../../constants/index'

export const LOAD_PUBLIHERS_POSTBACKS   = 'LOAD_PUBLIHERS_POSTBACKS'
export const ADD_PUBLIHERS_POSTBACKS    = 'ADD_PUBLIHERS_POSTBACKS'
export const ADD_PUBLIHERS_POSTBACKS_ERROR    = 'ADD_PUBLIHERS_POSTBACKS_ERROR'
export const FILTER_PUBLIHERS_POSTBACKS = 'FILTER_PUBLIHERS_POSTBACKS'

const _loadPublishersPostbacks = () => ({
  type: LOAD_PUBLIHERS_POSTBACKS,
});

export const _addPublishersPostbacksAction = (data) => ({
  type: ADD_PUBLIHERS_POSTBACKS,
  data,
});

export const _addPublishersPostbacksError = () => ({
  type: ADD_PUBLIHERS_POSTBACKS_ERROR,
});

export const filterAction = filters => ({ type: FILTER_PUBLIHERS_POSTBACKS, filters });

export const loadPublishersPostbacksAction = (dispatch, getState) => {
  dispatch(_loadPublishersPostbacks());
  let campaign_ids = [];
  const { postbacks_logs } = getState();
  const { filters } = postbacks_logs;
  const date = prepareDateFromTo(filters, LAST_HOUR_FLAG_VALUE, DATE_FORMAT_HOUR);
  campaign_ids = filters.campaign_ids;
  let pageSize = PAGINATION_OPTIONS[1].value;

  return loadPublishersPostbacks({
    date_from: date.date_from,
    date_to: date.date_to,
    campaign_ids:  [...campaign_ids],
    time_interval: filters.time_interval,
    page: filters.page || 1,
    per: pageSize,
  }, (itemsData, total) => {
    if (!itemsData.items || !total) {
      return dispatch(_addPublishersPostbacksError());
    }
    dispatch(_addPublishersPostbacksAction({
      items: itemsData.items,
      total,
    }))
    return itemsData.items;
  })
  .catch(err => dispatch(errorOccuredAction(err)))
};
