/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component, Fragment } from 'react'
import { Card, CardBody, Col, Table, UncontrolledTooltip } from 'reactstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import ClipboardFileIcon from 'mdi-react/ClipboardFileIcon'

import store from '../../../App/store'
import { getCurrencyLabel } from '../../../../utils';
import noty from '../../../../utils/noty'

import {
  NA_VALUE,
  CAMPAIGN_STATUSES,
  ALLOWED_TRAFFIC,
  REV_SHARE_COST_MODEL,
  ALL_OS_DEVICES_ALLOWED,
  ALL_COUNTRIES_ALLOWED,
  ALL_TYPES_ALLOWED,
  COPY_NOTIFICATION_COUPON,
} from '../../../../constants'

const copyCouponValueLink = (url) => noty({ text: COPY_NOTIFICATION_COUPON })

const PreviewLinkFormatter = ({ value }) => {
  return <a target={'blank'} href={value}>(Preview)</a>
}

class OfferGeneralDetails extends Component {
  constructor (props) {
    super(props)
  }

  _renderLikeTags = (data) => {
    return data.map((el, i) => <span className={`${i !== 0 ? 'pl-10 pr-10' : 'pr-10'}`}>{el}</span>)
  }

  _renderPublisherCategories = (pubDetails) => {
    let categories = null
    if (pubDetails && pubDetails.categories) {
      categories = this._renderLikeTags(pubDetails.categories)
    }

    return categories
  }

  _renderFields = (campaign) => {
    const users = store.getState().users || {};
    const usersSettings = users.settings || {};
    const currencyLabel = getCurrencyLabel(usersSettings)
    let campaignDetails = campaign
    const pubDetails = campaign.publisher_details
    let allowedTraffic = ALLOWED_TRAFFIC
    let publisherOS = pubDetails && pubDetails.os ? pubDetails.os.filter((el) => el !== '').join(', ') : ALL_OS_DEVICES_ALLOWED
    if (pubDetails && pubDetails.os && pubDetails.os.length === 1) {
      publisherOS = pubDetails.os[0] !== '' ? pubDetails.os.join(', ') : ALL_OS_DEVICES_ALLOWED
    } else if (pubDetails && pubDetails.os && pubDetails.os.length === 0) {
      publisherOS = ALL_OS_DEVICES_ALLOWED
    }
    let publisherCountries = pubDetails && pubDetails.countries ? pubDetails.countries.filter((el) => el !== '').join(', ') : ALL_COUNTRIES_ALLOWED
    if (pubDetails && pubDetails.countries && pubDetails.countries.length === 1) {
      publisherCountries = pubDetails.countries[0] !== '' ? pubDetails.countries.join(', ') : ALL_COUNTRIES_ALLOWED
    } else if (pubDetails && pubDetails.countries && pubDetails.countries.length === 0) {
      publisherCountries = ALL_COUNTRIES_ALLOWED
    }
    const campaignCap = campaignDetails.cap ? campaignDetails.cap : NA_VALUE
    let campaignStatus = NA_VALUE,
        couponValue = NA_VALUE
    if (campaignDetails.status && CAMPAIGN_STATUSES[campaignDetails.status]) {
      campaignStatus = CAMPAIGN_STATUSES[campaignDetails.status]
    }
    if (pubDetails.coupon) {
      couponValue = pubDetails.coupon
    }
    if (pubDetails && pubDetails.sources && pubDetails.sources.length !== 0) {
      allowedTraffic = this._renderLikeTags(pubDetails.sources)
    }

    const { campaign_type, campaign_rate } = pubDetails || {};
    const status = campaign_rate.status;
    let value = campaign_rate.value;
    if (campaign_type === REV_SHARE_COST_MODEL) {
      value = `${value}%`
    } else {
      value = `${currencyLabel} ${value}`
    }
    if (status === 1) {
      value = '-';
    }

    return (
      <Table className="table--bordered">
        <tbody>
        <tr className='custom'>
          <td>Payout:</td>
          <td>
            <span className='pr-10'>
              {campaign_type || NA_VALUE}
            </span>
            <span className='pl-10 pr-10'>
              {value}
            </span>
          </td>
        </tr>
        <tr className='custom'>
          <td>Allowed traffic:</td>
          <td>{allowedTraffic}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Allowed OS:</td>
          <td>{publisherOS}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Allowed Countries:</td>
          <td>{publisherCountries}</td>
        </tr>
        {campaignDetails.cap !== undefined && <tr className='custom-no-border'>
          <td>Cap</td>
          <td>{campaignCap}</td>
        </tr>}
        <tr className='custom-no-border'>
          <td>Status</td>
          <td>{campaignStatus}</td>
        </tr>
        <tr className='custom-no-border'>
          <td>Coupon</td>
          <td>
            <div>
              <span style={{ wordBreak: 'break-all' }}>{couponValue}</span>
              {pubDetails && pubDetails.coupon && <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                <CopyToClipboard id="TooltipCouponValue" text={couponValue} onCopy={copyCouponValueLink}>
                  <div className="">
                    <a href="javascript:;">
                      <ClipboardFileIcon
                        className="customize-icon"
                      />
                    </a>
                  </div>
                </CopyToClipboard>
                <UncontrolledTooltip placement="bottom" target="TooltipCouponValue">
                  {couponValue}
                </UncontrolledTooltip>
              </div>}
            </div>
          </td>
        </tr>
        </tbody>
      </Table>
    )
  }

  render () {
    const { resource } = this.props;
    const pubDetails = resource.publisher_details
    const pubPreviewImgUrl = pubDetails ? pubDetails.preview : ''
    const advertiser = pubDetails ? pubDetails.advertiser : ''
    const previewUrl = pubDetails && pubDetails.preview_url ? pubDetails.preview_url : ''

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Campaign Details</h5>
          </div>
          {pubPreviewImgUrl && <div className="offer-details__img-wrap">
            <img className="offer-details__img" src={`${pubPreviewImgUrl}`} alt="Preview image" />
          </div>}
          <div className="offer-details__resource-title">
            <h5 className="bold-text">{resource.title}</h5>
          </div>
          {advertiser && <div className='form form--horizontal'>
            <div className="form__form-group mb-10 mt-10">
              <span className="form__form-group-label">Advertiser:</span>
              <div className="form__form-group-field no-padding-left">
                {advertiser}
              </div>
            </div>
          </div>}
          {previewUrl &&
          <div className="form__form-group-field mb-10 mt-10">{PreviewLinkFormatter({ value: previewUrl })}</div>
          }
          <div className="form__form-group mb-10 mt-10">
            <div className="form__form-group-field no-margin-left no-padding-left">
              {this._renderPublisherCategories(pubDetails)}
            </div>
          </div>
          <Col md={10} lg={10} className='no-padding-left'>
            {this._renderFields(resource)}
          </Col>
        </CardBody>
      </Card>
    )
  }
}

export default OfferGeneralDetails
