import React, { Fragment, Component } from 'react'
import { Container, Row } from 'reactstrap'
import store from '../../App/store'
import { loadCampaignsAction } from "../../../redux/actions/campaignsActions"
import OfferDetailsLeftPart from './OfferDetailsLeftPart'
import OfferDetailsRightPart from './OfferDetailsRightPart'
import { connect } from "react-redux";
import { LoadableContainer } from "../../../widgets";

class OfferDetailsContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    store.dispatch(loadCampaignsAction)
  }

  render() {
    const { offers, loading, loaded } = this.props;
    const resource = offers.items.find(item => item.id === this.props.match.params.id);
    return (
      <Container
        // fluid={true}
      >
        <LoadableContainer
          loading={loading}
          loaded={loaded}
          style={{
            position: 'relative',
            width: '100%',
            maxHeight: '350px'
          }}
        >
          {resource &&
          <Row>
            <OfferDetailsLeftPart
              resource={resource}
            />
            <OfferDetailsRightPart
              resource={resource}
            />
          </Row>
          }
        </LoadableContainer>
      </Container>
    )
  }

}

const mapStateToProps = (state) => ({
  offers: state.campaigns,
  loading: state.campaigns.loading,
  loaded: state.campaigns.loaded,
});

export default connect(
  mapStateToProps,
)(OfferDetailsContainer)
