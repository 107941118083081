import React, { Fragment } from 'react'
import numeral from "numeral"
import { CardBody } from 'reactstrap';

import store from "../../App/store";
import { getCurrencySign } from '../../../utils';

class CommissionDue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

  render() {
    const users = store.getState().users || {};
    const usersSettings = users.settings || {};
    const currencySign = getCurrencySign(usersSettings);
    const commissionDue = (this.props.paymentDetails && this.props.paymentDetails.current_balance) || 0

    return (
      <CardBody
        style={{
          marginBottom: 0,
          padding: '15px 0 15px 0',
        }}
      >
        <div
          className="card__title"
          style={{
            height: '30px',
            marginBottom: 0,
            borderBottom: '1px solid transparent',
            borderBottomColor: '#dddddd',
          }}
        >
          <h5
            className="bold-text"
            style={{
              textTransform: 'none',
              textAlign: 'center',
            }}
          >
            Commission due
          </h5>
        </div>
        <div className="panel-body">
          <h1
            className="no-margin text-semibold text-center"
            style={{
              paddingTop: 10,
            }}
          >
            {`${currencySign}${numeral(commissionDue).format('0.00')}`}
          </h1>
        </div>
      </CardBody>
    )
  }
}

CommissionDue.defaultProps = {
  history: [],
  paymentDetails: {},
}

export default (CommissionDue)
