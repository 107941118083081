import {
    SIGN_IN,
    SET_CURRENT_USER,
    USER_LOAD_ALL_COUNTRIES_ACTION,
    SIGN_OUT,
    SET_SETTINGS,
    ERROR_OCCURED
} from '../actions/usersActions';

const initialState = {
    currentUser: JSON.parse(localStorage.getItem('user')),
    // settings: JSON.parse(localStorage.getItem('settings')),
    settings: {},
    countryOptions: null,
    referral: { history: [] },
    errors: {},
};

export default function (state = initialState, action) {

    switch (action.type) {
        case SIGN_IN:
            return { ...state, collapse: !state.collapse };
        case SET_CURRENT_USER:
            localStorage.setItem('user', JSON.stringify(action.data))
            return {
                ...state,
                currentUser: action.data,
            }
        case USER_LOAD_ALL_COUNTRIES_ACTION:
            return {
                ...state,
                countryOptions: action.data.sort((a,b) => a.title < b.title ? -1 : 1),
            }
        case SET_SETTINGS:
            localStorage.setItem('settings', JSON.stringify(action.data))
            return {
                ...state,
                settings: action.data,
            }
        case SIGN_OUT:
            localStorage.removeItem('user')
            return {
                ...state,
                currentUser: null,
            }

        case ERROR_OCCURED:
            return {
                ...state,
                errors: Object.assign(state.errors, action.data)
            }
        default:
            return state;
    }
}
