const SUBS_COUNT = 20;
const PUBLISHERS_POSTBACKS_LOGS_COLUMNS = [
  {
    id: 'campaign',
    enabled: true,
  },
  {
    id: 'campaign_id',
    enabled: true,
  },
  {
    id: 'ref_id',
    enabled: true,
  },
  {
    id: 'clickid',
    enabled: true,
  },
  {
    id: 'track_time',
    enabled: true,
  },
  {
    id: 'conv_time',
    enabled: true,
  },
  {
    id: 'type',
    enabled: true,
  },
  {
    id: 'pub_revenue',
    enabled: true,
  },
  {
    id: 'status',
    enabled: true,
  },
  {
    id: 'payout',
    enabled: false,
  },
  {
    id: 'duplicate_status',
    enabled: false,
  },
  ...Array.from({ length: SUBS_COUNT }, (el, i) => i + 1)
  .map((count) => (
    {
      id: `sub${count}`,
      title: `Sub${count}`,
      description: `Sub${count}`,
      enabled: false,
    }
  )),
  ...Array.from({ length: SUBS_COUNT }, (el, i) => i + 1)
  .map((count) => (
    {
      id: `convSub${count}`,
      title: `convSub${count}`,
      description: `convSub${count}`,
      enabled: false,
    }
  )),
];
export {
  PUBLISHERS_POSTBACKS_LOGS_COLUMNS,
  SUBS_COUNT,
};
