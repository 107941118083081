import React from 'react'
import {
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap'
import { capitalize } from '../utils/Helpers';

export default class FancyGroupingPanel extends React.Component {
  constructor (props) {
    super(props);
  }

  renderItem = (el, i) => {
    if (el instanceof Object) {
      if (!!el.subitems && el.subitems.length > 0) {
        return this.renderGroupingSubmenu(el)
      } else {
        return this.renderGroupingButton(el, i)
      }
    } else {
      return this.renderGroupingButton({ key: el }, i)
    }
  }

  renderGroupingButton = (el, i) => {
    return (
      <Button key={el.key}
              color="primary"
              onClick={this.props.onSelect.bind(this, el.key)}
              active={this.props.active === el.key}>
        {el.label ? el.label : capitalize(el.key)}
      </Button>
    )
  }

  renderGroupingSubmenu = (el) => {
    const { onSelect, active } = this.props;
    let submenu = el.subitems.map((item, index) => {
      if (!(item instanceof Object)) item = { key: item, label: capitalize(item) };
      return (
        <DropdownItem
          key={item.key}
          onClick={onSelect.bind(this, item.key)}
          active={active === item.key}
        >
          {item.label}
        </DropdownItem>
      )
    })
    return (
      <UncontrolledButtonDropdown
        id="group-by-sub-dropdown"
        key={el.key}
        style={{
          marginBottom: 0,
        }}
      >
        <DropdownToggle
          color="primary"
          caret
        >
          {el.label ? el.label : capitalize(el.key)}
        </DropdownToggle>
        <DropdownMenu>
          {submenu}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )
  }

  render () {
    return (
      <form className="form form--horizontal">
        <ButtonGroup
          style={{
            marginBottom: 0,
          }}
        >
          {this.props.items.map(this.renderItem)}
        </ButtonGroup>
      </form>
    )
  }
}
