import React, { Component } from 'react';
import {Col, Container, Row} from 'reactstrap';
import OfferReportTable from '../../components/reports/OfferReportTable'
import store from "../../../App/store";
import { loadReportAction } from "../../../../redux/actions/reportActions";
import { loadSettingsAction } from "../../../../redux/actions/usersActions";


class OfferReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scope: 'offers'
    }
  }

  componentDidMount() {
    const { scope } = this.state;
    store.dispatch(loadSettingsAction())
    store.dispatch(loadReportAction(scope, {
      ...store.getState().reports[`${scope}_filters`],
      campaign_ids: this.props.match.params.id,
    }))
  }



  render() {
    const { scope } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Campaign Report</h3>
          </Col>
        </Row>
        <Row>
          <OfferReportTable
            scope={scope}
            campaign_ids={this.props.match.params.id}
          />
        </Row>
      </Container>
    )
  }

}

export default OfferReport
