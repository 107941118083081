import React, { Fragment, Component } from 'react'
import OfferGeneralDetails from './OfferDetailsParts/OfferGeneralDetails'
import { Col } from 'reactstrap'

class OfferDetailsLeftPart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { resource } = this.props;

    return (
      <Col md={6} lg={6} sm={6}>
        <OfferGeneralDetails
          resource={resource}
        />
      </Col>
    )
  }

}

export default OfferDetailsLeftPart
