import moment from "moment";
import numeral from "numeral";
import React from "react";

export const REPORTS_REFERRAL_PROGRAM_OPTIONS = {
  SCOPE: 'referral_program',
  TITLE: 'Referral program report',
  SOURCE_NAME: 'referral_program',
  DEFAULT_PAGE_SIZE: 100,
  PAGE_SIZE: [50, 100, 250, 500, 1000],
  MAX_PAGE_SIZE: 5000,
};

export const getReferralsColumns = (currencySign = '', userData = {}, actions = {}) => [
  {
    id: 'invited_by_email',
    title: 'Recommender',
    accessor: 'invited_by_email',
    description: 'Recommender',
    Header: () => (
      <span>Recommender</span>
    ),
    Cell: (row) => (row.value || 'N/A'),
  },
  {
    id: 'email',
    title: 'Referral',
    accessor: 'email',
    description: 'Referral',
    Header: () => (
      <span>Referral</span>
    ),
    Cell: (row) => (row.value || 'N/A'),
  },
  {
    id: 'registration_date',
    title: 'Date of registration',
    accessor: 'registration_date',
    description: 'Date of registration',
    Header: () => (
      <span>Date of registration</span>
    ),
    Cell: (row) => (row.value ? moment(row.value).format('YYYY-DD-MM hh:mm') : ''),
  },
  {
    id: 'total_commission',
    title: 'CPA Commission',
    accessor: 'total_commission',
    description: 'CPA Commission',
    Header: () => (
      <span>CPA Commission</span>
    ),
    Cell: (row) => (row.original.financial_data.total_commission || 0),
  },
  {
    id: 'first_step_percent',
    title: 'Referral commission, %',
    accessor: 'first_step_percent',
    description: 'Referral commission, %',
    Header: () => (
      <span>Referral commission, %</span>
    ),
    Cell: (row) => `${numeral(row.original.active_program_settings.first_step_percent || 0).format('0.00')}%`,
  },
  {
    id: 'maximum_steps',
    title: 'Number of program steps',
    accessor: 'maximum_steps',
    description: 'Number of program steps',
    Header: () => (
      <span>Number of program steps</span>
    ),
    Cell: (row) => (row.original.active_program_settings.maximum_steps || 0),
  },
  {
    id: 'step_decrement',
    title: 'Step decrement',
    accessor: 'step_decrement',
    description: 'Step decrement',
    Header: () => (
      <span>Step decrement</span>
    ),
    Cell: (row) => `${numeral(row.original.active_program_settings.step_decrement || 0).format('0.00')}%`,
  },
  {
    id: 'not_approved_recommender_commission_for_referral',
    title: 'Commission for referrals under review',
    accessor: 'not_approved_recommender_commission_for_referral',
    description: 'Commission for referrals under review',
    Header: () => (
        <span>Commission for referrals under review</span>
    ),
    Cell: (row) => (row.original.financial_data.not_approved_recommender_commission_for_referral || 0),
  },
  {
    id: 'approved_recommender_commission_for_referral',
    title: 'Commission for referrals approved',
    accessor: 'approved_recommender_commission_for_referral',
    description: 'Commission for referrals approved',
    Header: () => (
        <span>Commission for referrals approved</span>
    ),
    Cell: (row) => (row.original.financial_data.approved_recommender_commission_for_referral || 0),
  },
];
