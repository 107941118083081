import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: "https://fece08571e324050a7b22d1945537bcf@sentry.io/1770427",
		sampleRate: 0.1,
		blacklistUrls: ['https://track.gogocpa.app/', '\\bgogocpa\\b']
	})
}

render(
  <App />,
  document.getElementById('root'),
);
