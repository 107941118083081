/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import shortid from "shortid";
import CopyToClipboard from 'react-copy-to-clipboard';
import ClipboardFileIcon from 'mdi-react/ClipboardFileIcon';
import HelpCircleIcon from "mdi-react/HelpCircleIcon";

import store from "../../../App/store";
import { getQueryUrlSign } from '../../../../utils';

import noty from '../../../../utils/noty';
import { COPY_NOTIFICATION } from '../../../../constants';

const copyTrackbackLink = (url) => noty({ text: COPY_NOTIFICATION })

class OfferDirectTrackingUrlPart extends Component {
  constructor (props) {
    super(props)
    const pub_details_domain = props.resource && props.resource.publisher_details && props.resource.publisher_details.domain
    this.state = {
      direct_traffic_url: pub_details_domain ? props.resource.publisher_details.domain : '',
      deeplink_traffic_url: '',
    };
  }

  componentDidMount() {
    const { resource } = this.props
    const user = store.getState().users.currentUser || {}
    const pubDetailsDomain = resource && resource.publisher_details && resource.publisher_details.domain
    const directTrafficDomain = pubDetailsDomain ? resource.publisher_details.domain : ''
    const offerId = resource && resource.id ? resource.id : ''
    let directTrafficUrl = ''
    let deeplinkTrafficUrl = ''
    let pubId = user && user.id ? user.id : ''
    if (directTrafficDomain) {
      directTrafficUrl = `${directTrafficDomain}${getQueryUrlSign(directTrafficDomain)}cmpid=${offerId}&pubid=${pubId}`
    }
    if (directTrafficDomain) {
      const queryParamsArray = directTrafficUrl.split('?');
      const queryParams = queryParamsArray[1];
      deeplinkTrafficUrl = queryParams ? `{deeplink}?${queryParams}` : deeplinkTrafficUrl;
    }
    this.setState({
      direct_traffic_url: directTrafficUrl,
      deeplink_traffic_url: deeplinkTrafficUrl,
    }, () => {
      this.props.dispatch(change('trackback_urls_form', 'direct_traffic_url', this.state.direct_traffic_url));
      this.props.dispatch(change('trackback_urls_form', 'deeplink_traffic_url', this.state.deeplink_traffic_url));
    })
  }

  render () {
    const { resource } = this.props;
    const { direct_traffic_url, deeplink_traffic_url } = this.state;
    const pubDetailsDomain = resource && resource.publisher_details && resource.publisher_details.domain;
    const directTrafficDomain = pubDetailsDomain ? resource.publisher_details.domain : '';
    const customTooltipId = `id_${shortid.generate()}`;

    return (
      <Card>
        <CardBody
          className="panel__body"
          style={{
            border: '0.5px solid rgb(232, 232, 232)',
            boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.11)',
          }}
        >
          <div className="card__title">
            <h5 className="bold-text">Direct Tracking URLS</h5>
          </div>
          <div className="form form--horizontal">
            {directTrafficDomain && (
              <>
                <div className="form__form-group mb-10 mt-10">
                  <span className="form__form-group-label">Direct Traffic URL</span>
                  <div className="form__form-group-field no-margin-left no-padding-left">
                    <div
                      id="TooltipDirectTrafficField"
                      className='offer-details__field-container'
                    >
                      <Field
                        name="direct_traffic_url"
                        component="input"
                        type="text"
                        value={direct_traffic_url}
                        placeholder={direct_traffic_url}
                        disabled
                      />
                    </div>
                    <UncontrolledTooltip placement="top" target="TooltipDirectTrafficField">
                      {direct_traffic_url}
                    </UncontrolledTooltip>
                    <div className="form__form-group-icon customize-icon-container">
                      <CopyToClipboard id="TooltipLinkDirectTraffic" text={direct_traffic_url} onCopy={copyTrackbackLink}>
                        <div className="">
                          <a href="javascript:;">
                            <ClipboardFileIcon
                              className="customize-icon"
                            />
                          </a>
                        </div>
                      </CopyToClipboard>
                      <UncontrolledTooltip placement="bottom" target="TooltipLinkDirectTraffic">
                        {direct_traffic_url}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default reduxForm({
  form: 'trackback_urls_form',
})(OfferDirectTrackingUrlPart)
