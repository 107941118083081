import {
	FIND_US_OTHER,
	TRAFFIC_OTHER,
} from '../../../constants/signup'
const validate = (values) => {
	const errors = {};
	// if (!values.firstname) {
	// 	errors.firstname = 'First name field shouldn’t be empty';
	// }
	// if (!values.lastname) {
	// 	errors.firstname = 'Last name field shouldn’t be empty';
	// }
	// if (!values.company_type) {
	// 	errors.firstname = 'I am field shouldn’t be empty';
	// }
	// if (!values.language) {
	// 	errors.language = 'I am field shouldn’t be empty';
	// }
	// if (!values.messenger_type) {
	// 	errors.messenger_type = 'Messenger type field shouldn’t be empty';
	// }
	// if (!values.messenger) {
	// 	errors.messenger = 'Messenger field shouldn’t be empty';
	// }
	if (values.find_us && values.find_us === FIND_US_OTHER && !values.find_us_details) {
		errors.find_us_details = 'Field shouldn’t be empty';
	}
	if (values.referral && !values.referral_details) {
		errors.referral_details = 'Field shouldn’t be empty';
	}
	if ((values.traffic_types || []).includes(TRAFFIC_OTHER) && !values.traffic_types_details) {
		errors.traffic_types_details = 'Field shouldn’t be empty';
	}
	return errors;
};

export default validate;
